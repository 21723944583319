<template>
    <div class="card" @click="click" role="button">
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col-10 text-truncate">
                    <a href="javascript:void(0)">
                        {{ user.first_name }} {{ user.last_name }}
                    </a>
                    <div>
                        <span class="text-muted">
                            <small>
                                <em>
                                    #{{ user.id }}
                                </em>
                            </small>
                        </span>
                    </div>
                </div>
                <div class="col-2 text-end" @click.stop>
                    <ActionDropdown :actions="actions" />
                </div>
            </div>
        </div>
    </div>
    <DangerModal :message="i18n('Remove %s?').replace('%s', user.first_name)" ref="DangerModal"
        @confirmed="deleteItem" />
</template>

<script>
import api from "@/api.js";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";
import DangerModal from "@/components/common/confirm/DangerModal.vue";

export default {
    components: {
        ActionDropdown,
        DangerModal,
    },
    emits: ['updated'],
    props: {
        role: {
            type: Object,
            default() {
                return {};
            },
        },
        user: {
            type: Object,
            default() {
                return {};
            },
        },
        expires_at: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            fullRole: null,
        };
    },
    methods: {
        click() { },
        deleteItem() {
            this.$root.setSaving(true);
            api.delete('users/' + this.user.id + '/role').then(() => {
                this.$root.setSaved(true);
                this.$root.setLoading(false);
                let index = (this.role.users || []).map(e => e.id).indexOf(
                    this.user.id);

                if (index !== -1) {
                    this.role.users.splice(index, 1);
                }

                this.$emit('updated');
            }).catch((err) => {
                this.$root.setSaved(true);
            });
        },
    },
    computed: {
        actions() {
            return [{
                label: this.i18n('Remove'),
                fn: () => {
                    if (!this.$refs['DangerModal']) return;
                    this.$refs['DangerModal'].show();
                },
            },];
        },
    }
};
</script>