<template>
    <Modal @shown="shown" @hidden="hidden" ref="modal" @submit="submit">
        <template #title>
            {{ i18n('Add Role') }}
        </template>
        <div class="alert alert-danger mb-2" v-if="errorMessage">{{ errorMessage }}</div>

        <div class="row">
            <div class="col mb-2">
                <Field :disabled="submitted" type="dictionary" rest="users" :required="true" :customAutofocus="true"
                    :label="i18n('User')" v-model="formData.user" :errorMessage="errorMessages.user" format="object"
                    :sort="(a, b) => { return a.name.localeCompare(b.name); }" />
            </div>
        </div>

        <template #footer>
            <div class="row">
                <div class="col text-end">
                    <button type="button" @click="checkUser" class="btn btn-dark btn-sm" :disabled="submitted">
                        {{ i18n('Save') }}
                    </button>
                </div>
            </div>
        </template>
    </Modal>
</template>
<script>
import api from "@/api.js";
import Modal from "@/components/common/layout/Modal.vue";
import Field from "@/components/common/form/Field.vue";
import MultipleDictionaryField from "@/components/common/form/MultipleDictionaryField.vue";
import { translatedErrorMessage, } from "@/tools/errorHandling.js";

export default {
    components: {
        Modal,
        Field,
        MultipleDictionaryField,
    },
    data() {
        return {
            submitted: false,
            formData: {},
            errorMessages: {},
            errorMessage: '',
        };
    },
    props: {
        role: {
            type: Object,
        },
    },
    emits: [
        'shown',
        'hidden',
        'submit',
        'created',
    ],
    mounted() {
        this.setFormData();
    },
    methods: {
        setFormData() {
            let formData = {};
            this.formData = formData;
        },
        show() {
            this.submitted = false;
            this.setFormData();
            this.formData.expires_at = null;
            this.errorMessage = '';

            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        shown() {
            this.$emit('shown');
        },
        hidden() {
            this.$emit('hidden');
        },
        checkUser() {
            this.errorMessage = '';
            if (!this.formData.user) return;

            if (!this.formData.user.role) {
                this.submit();
            } else if (this.formData.user.role.id !== this.role.id) {
                this.$root.requestConfirmation(this.i18n('User has already assigned a role. This role will be deleted. Are you sure?'), () => {
                    this.submit();
                });
            } else {
                this.errorMessage = this.i18n('User already has this role');
            }
        },
        submit() {
            let promise;
            this.errorMessage = '';
            this.submitted = true;

            if (!this.formData.user) return;
            promise = api.post('users/' + this.formData.user.id + '/roles/' + this.role.id, {});

            promise.then(async (role) => {
                this.$emit('submit', role);
                this.$emit('created', role);
                this.formData = {};
                this.hide();
                api.clearCache();
            }).catch((errorMessage) => {
                this.errorMessage = translatedErrorMessage(errorMessage);
                this.submitted = false;
            });
        },
    }
};
</script>