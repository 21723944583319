<template>
  <ActionDropdown :actions="actions" :cssClass="cssClass" />

  <UserModal ref="UserModal" @created="$emit('created', $event)" />
  <ImportModal ref="ImportModal" @created="$emit('created', $event)" endpoint="users/import" :name="i18n('Users')"
    :columns="[
      'user_origin',
      'origin_identifier',
      'first_name',
      'last_name',
      'email',
      'alternative_email',
      'document_type',
      'document',
      'user_type',
      'telephone',
      'telephone_extension',
      'contact_telephone',
      'company',
      'office',
      'department',
      'employee_number',
      'responsible_user',
      'language',
      'user_level',
      'team',
      'position',
      'starting_date',
      'ending_date',
      'in_name_of_user',
      'authorized_by_user',
      'authorization_date',
      'comments',
    ]" />
</template>
<script>
import api from "@/api";
import UserModal from "@/components/user/UserModal.vue";
import ImportModal from "@/components/common/import/ImportModal.vue";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";

export default {
  components: {
    UserModal,
    ImportModal,
    ActionDropdown,
  },
  props: {
    cssClass: String,
  },
  data() {
    return {
      formData: {},
    };
  },
  emits: [
    'added',
    'created'
  ],
  computed: {
    actions() {
      return [
        {
          label: this.i18n('Add New'),
          fn: () => {
            this.$refs['UserModal'].show();
          },
          starred: true,
        },
        {
          label: this.i18n('Export'),
          fn: () => {
            api.getFile('users/export', {}, 'Users.xlsx');
          },
        },
        {
          label: this.i18n('Import'),
          fn: () => {
            this.$refs['ImportModal'].show();
          },
        },
      ];
    },
  },
  methods: {
  },
};
</script>