<template>
  <tr @dblclick="$refs['actions'].callFirstStarredAction();"
    class="d-block d-lg-table-row bg-white px-2 py-2 mb-2 border p-lg-0 mb-lg-0 border-lg-0">
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Id') }}</span>
      <FieldReadonly :value="application.id" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Alias') }}</span>
      <FieldReadonly :value="application.alias" />
    </td>
    <template v-if="$root?.features?.applications?.categories?.enabled">
      <td class="d-block d-lg-table-cell">
        <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Category') }}</span>
        <FieldReadonly :value="application.category" />
      </td>
      <td class="d-block d-lg-table-cell">
        <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Subcategory') }}</span>
        <FieldReadonly :value="application.subcategory" />
      </td>
    </template>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Url') }}</span>
      <FieldReadonly :value="application.main_url" format="url" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Profiles') }}</span>
      <FieldReadonly :value="application.profile_count" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Created At') }}</span>
      <FieldReadonly :value="application.created_at" format="date" />
    </td>
    <td data-no-export class="d-block d-lg-table-cell text-end border-0 border-lg-bottom" @click.stop>
      <ActionDropdown ref="actions" :actions="actions" />
    </td>
  </tr>
</template>

<script>
import FieldReadonly from "@/components/common/form/FieldReadonly.vue";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";

export default {
  components: {
    FieldReadonly,
    ActionDropdown,
  },
  props: {
    application: Object,
  },
  data() {
    return {};
  },
  emits: [
    'deleted',
    'updating',
    'updated',
  ],
  methods: {
  },
  computed: {
    actions() {
      return [{
        label: this.i18n('Detail'),
        fn: () => {
          this.$router.push({ name: 'ApplicationDetail', params: { id: this.application.id } });
        },
        if: (this.application.acl?.update) === true || (this.application.acl?.view) === true,
        starred: true,
      },
      ];
    },
  },
};
</script>