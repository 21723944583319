<template>
  <div class="bg-white border p-2 mt-3">
    <div class="table-responsive position-relative"
      :class="{ 'with-actions-gap': profileUsers.length < 4, 'opacity-50': loading }">
      <table class="table">
        <caption class="sr-only" data-no-export>{{ i18n('Profile List') }}</caption>
        <thead>
          <tr class="d-none d-lg-table-row">
            <th scope="col">{{ i18n('Name') }}</th>
            <th scope="col">{{ i18n('Application') }}</th>
            <th scope="col">{{ i18n('Application Username') }}</th>
            <th scope="col" v-if="$root.features?.portfolios">{{ i18n('Portfolio') }}</th>
            <th scope="col">{{ i18n('Profile') }}</th>
            <th data-no-export scope="col" style="min-width: 48px">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <ProfileUserListItem v-for="profileUser in profileUsers" :profileUser="profileUser" :key="profileUser.id"
            @editUsername="showUserApplicationUsernameModal" @edit="showProfileModal"
            @deleted="$emit('deleted', $event)" @updated="$emit('updated', $event)"
            @updating="$emit('updating', $event)" />
          <EmptyListItem v-if="!profileUsers || profileUsers.length == 0" :loading="loading" :colspan="5 + ($root.features?.portfolios ? 1 : 0)" />
          <LazyLoadListItem v-if="!loading && (lazyLoad || autoLazyLoad)" :autoLazyLoad="autoLazyLoad" :colspan="5 + ($root.features?.portfolios ? 1 : 0)"
            @next="$emit('next', $event)" />
        </tbody>
      </table>
      <div class="position-absolute h-100 w-100 top-0" @click.stop v-if="loading"></div>
    </div>
  </div>
  <ProfileModal ref="ProfileModal" @updated="$emit('updated', $event)" :application="application" />
  <UserApplicationUsernameModal ref="UserApplicationUsernameModal" @updated="$emit('updated', $event)" />
</template>

<script>
import EmptyListItem from "@/components/common/list/EmptyListItem.vue";
import LazyLoadListItem from "@/components/common/list/LazyLoadListItem.vue";
import ProfileModal from "@/components/profile/ProfileModal.vue";
import ProfileUserListItem from "@/components/profileUser/ProfileUserListItem.vue";
import UserApplicationUsernameModal from "@/components/userApplicationUsername/UserApplicationUsernameModal.vue";

export default {
  components: {
    EmptyListItem,
    LazyLoadListItem,
    ProfileModal,
    ProfileUserListItem,
    UserApplicationUsernameModal,
  },
  emits: [
    'updated',
    'deleted',
    'updated',
    'updating',
    'next',
  ],
  data() {
    return {
      application: {},
    };
  },
  props: {
    loading: Boolean,
    lazyLoad: Boolean,
    autoLazyLoad: Boolean,
    profileUsers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    async showProfileModal(profile) {
      if (!this.$refs.ProfileModal) return;
      if (!profile) return;

      await this.fetchApplicationForProfile(profile);

      this.$refs.ProfileModal.show(profile.id);
    },

    async fetchApplicationForProfile(profile) {
      this.application = profile.application;
    },
    showUserApplicationUsernameModal(userProfile) {
      if (!this.$refs.UserApplicationUsernameModal) return;
      this.$refs.UserApplicationUsernameModal.show(userProfile.user.id, userProfile.profile.application.id);
    },
  },
};
</script>