<template>
    <button type="button" :class="btnClass" @click="showUserProfileModal">
        <span class="d-none d-sm-inline">
            {{ i18n('Add New') }}
        </span>
    </button>

    <UserProfileModal :user="user" :application="application" ref="UserProfileModal" @created="$emit('created', $event)"
        @submit="$emit('submit', $event)" />
</template>
<script>
import UserProfileModal from "@/components/userProfile/UserProfileModal.vue";

export default {
    components: {
        UserProfileModal,
    },
    data() {
        return {
            formData: {},
        };
    },
    props: {
        btnClass: {
            type: String,
            default: 'btn btn-sm btn-outline-dark',
        },
        user: {
            type: Object,
        },
        application: {
            type: Object,
        },
    },
    emits: [
        'added',
        'created',
        'submit',
    ],
    methods: {
        showUserProfileModal() {
            this.$refs['UserProfileModal'].show();
        },
    }
};
</script>
