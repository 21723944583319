<template>
  <div class="bg-white p-2 border">
    <UserFilter :filters="filters">
      <template #default>
        <h2 class="h5">
          {{ i18n('Users') }}
          <span class="small text-muted">({{ users.length }})</span>
        </h2>
      </template>
      <template #buttons>
        <UserRoleCreateAction @created="reload" v-if="can('ROLE_MANAGE')" btnClass="ms-1 btn btn-sm btn-outline-dark"
          :role="role" />
      </template>
    </UserFilter>

    <UserRoleList :role="role" :users="users" :loading="loading" @deleted="reload" @updating="loading = true"
      @updated="reload" />
  </div>
</template>

<script>
import api from "@/api.js";
import {
  duplicate,
  flattenUrlObject,
} from "@/tools/object.js";
import UserRoleCreateAction from "@/components/userRole/UserRoleCreateAction.vue";
import UserFilter from "@/components/user/UserFilter.vue";
import UserRoleList from "@/components/userRole/UserRoleList.vue";

export default {
  components: {
    UserRoleCreateAction,
    UserFilter,
    UserRoleList,
  },
  props: {
    role: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      users: [],
      filters: {},
      loadTimeout: null,
      loading: true,
    }
  },
  mounted() {
    api.auth().then((role) => {
      this.$root.loggedRole = role;

      if (!this.can('ROLE_MANAGE')
        && !this.can('ROLE_ACCESS')) {
        this.$router.push({
          name: 'Index',
        });
        return;
      }

      this.load();
    });
  },
  watch: {
    params: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  computed: {
    params() {
      let params = duplicate(this.filters);
      params.acl = 'role';

      flattenUrlObject(params);

      return params;
    },
  },
  methods: {
    load(force) {
      clearTimeout(this.loadTimeout);
      this.loading = true;

      if (!force) {
        this.loadTimeout = setTimeout(() => {
          this.load(true);
        }, 250);
        return;
      }

      api.get(`roles/${this.role.id}/users`, this.params).then(users => {
        this.users = users;
        this.loading = false;
      });
    },
    reload() {
      this.load();
    },
  },
};
</script>