<template>
  <ActionDropdown :actions="actions" :cssClass="cssClass" />

  <UserProfileModal ref="UserProfileModal" @created="$emit('created', $event)" />
  <ImportModal ref="ImportModal" @created="$emit('created', $event)" endpoint="users/profiles/import"
    :name="i18n('User Profiles')" :columns="[
      'user_origin',
      'origin_identifier',
      'application',
      'profile',
      'status',
    ]" />
</template>
<script>
import api from "@/api";
import UserProfileModal from "@/components/userProfile/UserProfileModal.vue";
import ImportModal from "@/components/common/import/ImportModal.vue";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";

export default {
  components: {
    UserProfileModal,
    ImportModal,
    ActionDropdown,
  },
  props: {
    cssClass: String,
  },
  data() {
    return {
      formData: {},
    };
  },
  emits: [
    'added',
    'created'
  ],
  computed: {
    actions() {
      return [
        {
          label: this.i18n('Add New'),
          fn: () => {
            this.$refs['UserProfileModal'].show();
          },
          starred: true,
        },
        {
          label: this.i18n('Export'),
          fn: () => {
            api.getFile('users/profiles/export', {}, 'User Profiles.xlsx');
          },
        },
        {
          label: this.i18n('Import'),
          fn: () => {
            this.$refs['ImportModal'].show();
          },
        },
      ];
    },
  },
  methods: {
  },
};
</script>