<template>
    <ActionDropdown :actions="actions" cssClass="ms-1" />

    <ProfileModal :application="application" ref="ProfileModal" @created="$emit('created', $event)" />
    <ProfileImportModal ref="ProfileImportModal" @updated="$emit('updated', $event)" :application="application" />
</template>

<script>
import api from "@/api.js";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";
import ProfileModal from "@/components/profile/ProfileModal.vue";
import ProfileImportModal from "@/components/profile/ProfileImportModal.vue";

export default {
    components: {
        ActionDropdown,
        ProfileModal,
        ProfileImportModal,
    },
    props: {
        application: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            permissions: [],
            filters: {},
            loadTimeout: null,
            loading: true,
        }
    },
    emits: [
        'updated',
        'created',
        'shown',
        'hidden',
        'submit',
    ],
    watch: {
    },
    methods: {
        showProfileModal() {
            if (!this.$refs['ProfileModal']) return;
            this.$refs['ProfileModal'].show();
        },
        showProfileImportModal() {
            if (!this.$refs['ProfileImportModal']) return;
            this.$refs['ProfileImportModal'].show();
        },
    },
    computed: {
        actions() {
            return [
                {
                    label: this.i18n('Add New'),
                    fn: () => {
                        this.showProfileModal();
                    },
                    if: this.application?.acl?.update === true,
                    starred: true,
                },
                {
                    label: this.i18n('Export profiles'),
                    fn: () => {
                        api.getFile(`applications/${this.application.id}/profiles/export`, {}, 'export_profiles_' + this.application.name + '.json').then(result => {
                            api.clearCache();
                        }).catch((err) => {
                            this.$root.showErrorModal(this.i18n('Error'), this.i18n('Document could not be downloaded. Try again later.'));
                        });
                    },
                    if: this.application?.acl?.update === true,
                },
                {
                    label: this.i18n('Import Profiles'),
                    fn: () => {
                        this.showProfileImportModal();
                    },
                    if: this.application?.acl?.update === true,
                },
            ];
        },
    },
};
</script>