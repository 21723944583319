<template>
    <Modal @shown="shown" @hidden="hidden" ref="modal" @submit="submit">
        <template #title>
            {{ i18n('Login As') }}
        </template>
        <Field :disabled="submitted" required type="dictionary" :rest="`users/${user.id}/applications`"
            :customAutofocus="true" :label="i18n('Application')" v-model="formData.application" format="object"
            :errorMessage="errorMessage" />

        <p class="mt-2 mb-0 small">
            <small>
                <strong>
                    {{ i18n('Note') }}:
                </strong> {{ i18n('LOGIN_AS_NOTE') }}
            </small>
        </p>

        <template #footer>
            <div class="row">
                <div class="col text-end">
                    <button type="submit" class="btn btn-dark btn-sm" :disabled="submitted">
                        {{ i18n('Simulate') }}
                    </button>
                </div>
            </div>
        </template>
    </Modal>
</template>
<script>
import api from "@/api.js";
import Modal from "@/components/common/layout/Modal.vue";
import Field from "@/components/common/form/Field.vue";

export default {
    components: {
        Modal,
        Field,
    },
    data() {
        return {
            submitted: false,
            formData: {},
            errorMessage: '',
        };
    },
    props: {
        user: {
            type: Object,
            default() {
                return {};
            },
        }
    },
    emits: [
        'shown',
        'hidden',
        'submit',
    ],
    methods: {
        show() {
            this.errorMessage = '';
            this.submitted = false;
            this.formData = {};
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        shown() {
            this.$emit('shown');
        },
        hidden() {
            this.$emit('hidden');
        },
        getMatchingUrl(urls, referenceUrl) {
            const referenceHost = new URL(referenceUrl).host;

            for (const url of urls) {
                const host = new URL(url).host;

                if (host === referenceHost) {
                    return url;
                }
            }

            return urls[0];
        },

        submit() {
            this.errorMessage = '';
            this.submitted = true;

            if (this.formData.application) {
                api.post(`users/${this.user.id}/getLoginAsCode/${this.formData.application.id}`).then((code) => {
                    api.get(`applications/${this.formData.application.id}`).then(application => {
                        let url = this.getMatchingUrl(application.redirectUris.map(ru => ru.uri),
                            application.main_url);

                        if (url) {
                            url += (url.indexOf('?') === -1 ? '?' : '&') + 'code=' +
                                encodeURIComponent(
                                    code.code);
                            url += '&state=' + encodeURIComponent('"/"');
                            window.open(url);
                            this.hide();
                        } else {
                            this.errorMessage = this.i18n('User could not be simulated.');
                            this.submitted = false;
                        }
                    }).catch(() => {
                        this.errorMessage = this.i18n('User could not be simulated.');
                        this.submitted = false;
                    });
                }).catch(() => {
                    this.errorMessage = this.i18n('User could not be simulated.');
                    this.submitted = false;
                });
            } else {
                this.errorMessage = this.i18n('You must select an application.');
                this.submitted = false;
            }
        },
    }
};
</script>