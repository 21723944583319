<template>
  <DefaultLayout>
    <template #buttons>
      <CompanyCreateAction @created="reload" v-if="can('COMPANY_MANAGE')" />
    </template>

    <CompanyFilter :filters="filters">
      <h1 class="h5 mt-2">
        {{ i18n('Companies') }}
        <span class="small text-muted" v-if="!pagination?.total">({{ companies.length }})</span>
        <span class="small text-muted" v-else>({{ pagination?.total }})</span>
      </h1>
      <template #buttons>
        <ExportToExcelAction cssClass="btn btn-sm btn-outline-dark ms-1" />
      </template>
    </CompanyFilter>

    <CompanyList :companies="companies" :loading="loading" :autoLazyLoad="!!pagination.nextPageURI" @deleted="reload"
      @updating="loading = true" @updated="reload" @next="nextPage" />
  </DefaultLayout>
</template>

<script>
import api from "@/api.js";
import {
  duplicate,
  flattenUrlObject,
} from "@/tools/object.js";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ExportToExcelAction from "@/components/common/actions/ExportToExcelAction.vue";
import CompanyCreateAction from "@/components/company/CompanyCreateAction.vue";
import CompanyFilter from "@/components/company/CompanyFilter.vue";
import CompanyList from "@/components/company/CompanyList.vue";

export default {
  components: {
    DefaultLayout,
    ExportToExcelAction,
    CompanyCreateAction,
    CompanyFilter,
    CompanyList,
  },
  data() {
    return {
      companies: [],
      filters: {},
      loadTimeout: null,
      loading: true,
      pagination: {
        requestId: 0,
        limit: 10,
        records: [],
        loadPages: 1,
        nextPageURI: null,
        currentPage: 1,
        total: null,
      },
    }
  },
  mounted() {
    this.$root.setPageTitle(this.i18n('Companies'));
    api.auth().then((user) => {
      this.$root.loggedUser = user;

      if (!this.can('COMPANY_MANAGE')
        && !this.can('COMPANY_ACCESS')) {
        this.$router.push({
          name: 'Index',
        });
        return;
      }

      this.load();
    });
  },
  watch: {
    params: {
      handler() {
        this.pagination.loadPages = 1;
        this.reload();
      },
      deep: true,
    },
  },
  computed: {
    params() {
      let params = duplicate(this.filters);
      params.acl = 'company';
      params.limit = this.pagination.limit;

      flattenUrlObject(params);

      return params;
    },
  },
  methods: {
    load(force) {
      clearTimeout(this.loadTimeout);
      this.loading = true;
      this.pagination.requestId++;

      if (!force) {
        this.loadTimeout = setTimeout(() => {
          this.load(true);
        }, 250);
        return;
      }

      this.pagination.records = [];
      this.pagination.currentPage = 1;
      this.loadPages(this.pagination.requestId, `companies`, this.params);
    },
    loadPages(requestId, uri, params) {
      api.getWithHeaders(uri, params).then((companiesResult) => {
        if (requestId != this.pagination.requestId) return;

        const companies = companiesResult.data,
          headers = companiesResult.headers;

        this.pagination.records = [
          ...this.pagination.records,
          ...companies,
        ];

        this.pagination.nextPageURI = headers.get('X-PAGINATE-NEXT');
        this.pagination.total = headers.get('X-PAGINATE-COUNT');

        if (this.pagination.currentPage < this.pagination.loadPages
          && this.pagination.nextPageURI) {
          this.pagination.currentPage++;
          this.loadPages(requestId, this.pagination.nextPageURI);
          return;
        } else {
          this.pagination.loadPages = this.pagination.currentPage;
        }

        this.companies = [...this.pagination.records];
        this.loading = false;
      });
    },
    nextPage() {
      this.loading = true;
      this.pagination.currentPage++;
      this.pagination.loadPages = this.pagination.currentPage;
      this.loadPages(++this.pagination.requestId, this.pagination.nextPageURI);
    },
    reload() {
      this.load();
    },
  },
};
</script>