<template>
  <template v-if="showView">
    <nav class="navbar position-fixed fixed-top navbar-dark bg-primary mb-3 bg-cover no-print" :style="{
      backgroundColor: devEnvironmentName ? '#5ccbff !important' : '#496c70 !important',
    }">
      <div class="container-fluid">
        <div class="applications-wrapper">
          <ApplicationMenu v-if="!$root?.features?.loginPortal?.enabled" />
          <a class="navbar-brand" href="/">
            <img :src="defaultLogoLightUrl" :alt="defaultCompanyAlias" height="38" data-logo="light">
            <span class="brand-separator"></span> <span class="text-uppercase brand-application">
              {{ appName }}
              {{ devEnvironmentName }}
            </span>
          </a>
        </div>
        <div class="btn-group">
          <slot name="buttons"></slot>
          <button class="btn btn-outline-light d-lg-none" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#aside-menu" aria-controls="aside-menu">
            <i class="custom-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list"
                viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
              </svg>
            </i>
          </button>
        </div>
      </div>
    </nav>

    <template v-if="showMenu">
      <SideMenu>
        <ul class="nav nav-pills flex-column mb-auto">
          <li v-if="can('DASHBOARD_ACCESS')" class="nav-item">
            <router-link :to="{ name: 'DashboardIndex' }" class="nav-link link-dark" v-if="can('DASHBOARD_ACCESS')">
              <DashboardIcon />
              <div class="slim-hide">{{ i18n('Dashboard') }}</div>
            </router-link>
          </li>
          <li class="nav-item" v-if="can('USER_MANAGE') || can('USER_ACCESS')">
            <router-link :to="{ name: 'UserIndex' }" class="nav-link link-dark"
              :class="{ 'active': $route.name === 'UserDetail' }">
              <UserIcon />
              <div class="slim-hide">{{ i18n('Users') }}</div>
            </router-link>
          </li>
          <li class="nav-item"
            v-if="(!$root.features?.login || $root?.features?.login?.local) && (can('USER_MANAGE') || can('USER_ACCESS'))">
            <router-link :to="{ name: 'LocalUserIndex' }" class="nav-link link-dark"
              :class="{ 'active': $route.name === 'LocalUserDetail' }">
              <LockFillIcon />
              <div class="slim-hide">{{ i18n('Local Users') }}</div>
            </router-link>
          </li>
          <li class="nav-item"
            v-if="(can('USER_MANAGE') || can('USER_ACCESS')) && this.$root.features?.profiles?.user_index">
            <router-link :to="{ name: 'ProfileUserIndex' }" class="nav-link link-dark">
              <KeyIcon />
              <div class="slim-hide">{{ i18n('Profiles') }}</div>
            </router-link>
          </li>
          <li class="nav-item" v-if="can('COMPANY_MANAGE') || can('COMPANY_ACCESS')">
            <router-link :to="{ name: 'CompanyIndex' }" class="nav-link link-dark"
              :class="{ 'active': $route.name === 'CompanyDetail' }">
              <BuildingIcon />
              <div class="slim-hide">{{ i18n('Companies') }}</div>
            </router-link>
          </li>
          <li v-if="isIntrum && (can('OFFICE_MANAGE') || can('OFFICE_ACCESS'))" class="nav-item">
            <router-link :to="{ name: 'OfficeIndex' }" class="nav-link link-dark"
              :class="{ 'active': $route.name === 'OfficeIndex' }">
              <MarkerIcon />
              <div class="slim-hide">{{ i18n('Offices') }}</div>
            </router-link>
          </li>
          <li v-if="isIntrum && (can('ROLE_MANAGE') || can('ROLE_ACCESS'))" class="nav-item">
            <router-link :to="{ name: 'RoleIndex' }" class="nav-link link-dark"
              :class="{ 'active': $route.name === 'RoleIndex' }">
              <ComputerIcon />
              <div class="slim-hide">{{ i18n('Roles') }}</div>
            </router-link>
          </li>
          <li class="nav-item"
            v-if="(can('PROVIDER_MANAGE') || can('PROVIDER_ACCESS')) && (!$root.features?.login || $root?.features?.login?.local)">
            <router-link :to="{ name: 'ProviderIndex' }" class="nav-link link-dark"
              :class="{ 'active': $route.name === 'ProviderDetail' }">
              <FolderIcon />
              <div class="slim-hide">{{ i18n('Providers') }}</div>
            </router-link>
          </li>
          <li class="nav-item" v-if="can('TRIGGER_MANAGE') || can('TRIGGER_ACCESS')">
            <router-link :to="{ name: 'TriggerIndex' }" class="nav-link link-dark"
              :class="{ 'active': $route.name === 'TriggerIndex' }">
              <LockFillIcon />
              <div class="slim-hide">{{ i18n('Triggers') }}</div>
            </router-link>
          </li>
          <li class="nav-item" v-if="can('APPLICATION_MANAGE') || can('APPLICATION_ACCESS')">
            <router-link :to="{ name: 'ApplicationIndex' }" class="nav-link link-dark"
              :class="{ 'active': $route.name === 'ApplicationDetail' && ($route.params.id != 1 || !isIntrum) }">
              <ApplicationIcon />
              <div class="slim-hide">{{ i18n('Applications') }}</div>
            </router-link>
          </li>
          <li v-if="isIntrum && (can('USER_MANAGE') || can('USER_ACCESS'))" class="nav-item">
            <router-link :to="{ name: 'ApplicationDetail', params: { id: 1 } }" class="nav-link link-dark">
              <PantheonIcon />
              <div class="slim-hide">{{ appName }}</div>
            </router-link>
          </li>
          <li v-if="isIntrum && can('AUDIT_ACCESS')" class="nav-item">
            <router-link :to="{ name: 'AuditIndex' }" class="nav-link link-dark"
              :class="{ 'active': $route.name === 'AuditIndex' }">
              <LockFillIcon />
              <div class="slim-hide">{{ i18n('Audit') }}</div>
            </router-link>
          </li>
        </ul>
        <template #userMenu>
          <ul class="dropdown-menu text-small shadow">
            <template v-for="lang in ['es', 'en']" :key="lang">
              <li v-if="$root.getCurrentLang() !== lang">
                <a class="dropdown-item" href="javascript:void(0)" @click="$root.switchLang(lang)">
                  {{ i18n('View in {lang}').replace('{lang}', lang.toUpperCase()) }}</a>
              </li>
            </template>
            <li class="dropdown-divider"></li>
            <li>
              <a class="dropdown-item" href="javascript:void(0)" @click="logout">{{ i18n('Logout') }}</a>
            </li>
          </ul>
        </template>
      </SideMenu>
    </template>

    <main class="container-fluid mt-5 pt-4" :class="{ 'no-aside-menu': !showMenu }">
      <slot></slot>
    </main>
    <BackToTop />
  </template>
</template>

<script>
import $env from "@/tools/environment.js";
const appName = $env('VITE_APP_NAME');
const defaultLogoLightUrl = $env('VITE_DEFAULT_LOGO_LIGHT_URL');
const defaultCompanyAlias = $env('VITE_DEFAULT_COMPANY_ALIAS');
const defaultIcoUrl = $env('VITE_DEFAULT_ICO_URL');
const ignoreUserCompany = $env('VITE_IGNORE_USER_COMPANY');
import api from "@/api.js";
import ApplicationMenu from "@/components/common/layout/ApplicationMenu.vue";
import SideMenu from "@/components/common/layout/SideMenu.vue";
import DashboardIcon from "@/assets/svg/dashboard.vue";
import ApplicationIcon from "@/assets/svg/application.vue";
import UserIcon from "@/assets/svg/user.vue";
import ListIcon from "@/assets/svg/list.vue";
import LockFillIcon from "@/assets/svg/lock-fill.vue";
import FolderIcon from "@/assets/svg/folder.vue";
import BuildingIcon from "@/assets/svg/building.vue";
import BackToTop from '@/components/common/layout/BackToTop.vue';
import PantheonIcon from '@/assets/svg/pantheon.vue';
import MarkerIcon from '@/assets/svg/marker.vue';
import KeyIcon from '@/assets/svg/key.vue';
import ComputerIcon from '@/assets/svg/computer.vue';

export default {
  components: {
    ApplicationMenu,
    SideMenu,
    KeyIcon,
    DashboardIcon,
    ApplicationIcon,
    UserIcon,
    ListIcon,
    LockFillIcon,
    FolderIcon,
    BuildingIcon,
    BackToTop,
    PantheonIcon,
    ComputerIcon,
    MarkerIcon,
  },
  props: {
    allowAnonymous: {
      type: Boolean,
      default: false,
    },
    showMenu: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      devEnvironmentName: false,
      appName,
      defaultLogoLightUrl,
      defaultCompanyAlias,
    };
  },
  computed: {
    showView() {
      return !!this.allowAnonymous || !!(this.$root.loggedUser && this.$root.loggedUser.id);
    },
    isIntrum() {
      return $env('VITE_CUSTOM_STYLE_SHEET') === '/assets/intrum/styles.css';
    }
  },
  methods: {
    logout() {
      this.$router.push({
        name: 'Logout'
      });
    },
  },
  mounted() {
    api.auth().then((user) => {
      let company = null;

      if (user?.company
        && !ignoreUserCompany) {
        company = user.company;
      }

      if (company) {
        this.$root.setCompanyName(user.company.alias);

        [...document.querySelectorAll('[data-logo="light"]')].forEach(e => {
          e.alt = user.company.alias;
          e.src = user.company.logo.light_png_url;
        });
        [...document.querySelectorAll('[data-logo="default"]')].forEach(e => {
          e.alt = user.company.alias;
          e.src = user.company.logo.png_url;
        });
      }

      let icoUrl = company?.logo?.icon_url || defaultIcoUrl;

      if (icoUrl) {
        let link = document.querySelector('link[rel~="icon"]');

        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.head.appendChild(link);
        }

        link.href = icoUrl;
      }
    });
  },
  created() {
    this.devEnvironmentName = api.devEnvironmentName();

    if (!this.allowAnonymous &&
      !api.hasAuthToken()) {
      api.login(
        this.$route.query, {
        name: this.$route.name,
        params: this.$route.params
      }).then((route) => {
        this.$root.loadFeatures();
        this.$router.push(route);
      });
    } else if (api.hasAuthToken()) {
      api.auth().then((user) => {
        this.$root.loggedUser = user;
      });
    }
  },
  UserLoginResetPasswordModal() {
    if (!this.$refs.UserLoginResetPasswordModal) return;
    this.$refs.UserLoginResetPasswordModal.show(this.user.id);
  },

};
</script>

<style lang="scss" scoped>
.navbar-brand {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width:511px) {
  .navbar-brand img {
    height: 24px;
  }

  .applications-wrapper {
    .applications-trigger {
      margin-left: 0;
      opacity: 1;
      color: var(--bs-white);
      cursor: pointer;

      .custom-icon__big {
        color: currentColor;
      }
    }
  }
}

@media screen and (min-width:512px) {
  .navbar-brand {
    flex-direction: row;
    align-items: center;
  }

  .brand-separator {
    display: inline-block;
    margin-right: 11px;
    margin-left: 9px;
    background-color: var(--bs-gray-500);
    width: 1px;
    height: 30px;
  }

  .brand-application {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.035rem;
    margin-top: 3px;
  }
}

.brand-application {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.035rem;
  margin-top: 3px;
}

.applications-wrapper {
  display: flex;
  align-items: center;
}
</style>