<template>
    <div class="custom-list-view">
        <EmptyListItem v-if="!profileRoles || profileRoles.length == 0" />
        <ProfileRoleListItem @updated="$emit('updated', $event)" v-for="profileRole in profileRoles"
            :role="profileRole.role" :key="`${profileRole.role.id}|${profileRole.profile.id}`"
            :profile="profileRole.profile" />
    </div>
</template>

<script>
import ProfileRoleListItem from "@/components/profileRole/ProfileRoleListItem.vue";
import EmptyListItem from "@/components/common/list/EmptyListItem.vue";

export default {
    components: {
        ProfileRoleListItem,
        EmptyListItem,
    },
    emits: ['updated'],
    props: {
        role: {
            type: Object,
            default() {
                return {};
            },
        },
        profileRoles: {
            type: Array,
            default() {
                return [];
            },
        },
    },
};
</script>