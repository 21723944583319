<template>
    <div class="custom-list-view">
        <EmptyListItem v-if="!users || users.length == 0" />
        <UserRoleListItem @updated="$emit('updated', $event)" v-for="user in users" :role="role" :key="`${user.id}`"
            :user="user" />
    </div>
</template>

<script>
import UserRoleListItem from "@/components/userRole/UserRoleListItem.vue";
import EmptyListItem from "@/components/common/list/EmptyListItem.vue";

export default {
    components: {
        UserRoleListItem,
        EmptyListItem,
    },
    emits: ['updated'],
    props: {
        role: {
            type: Object,
            default() {
                return {};
            },
        },
        users: {
            type: Array,
            default() {
                return [];
            },
        },
    },
};
</script>