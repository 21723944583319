import api from '@/api.js';

let userOriginCount = null;

export const hasSingleUserOrigin = () => {
    if (userOriginCount === null) {
        api.cached('types/userOrigins').then((userOrigins) => {
            userOriginCount = userOrigins.length;
        });

        return false;
    }

    return userOriginCount === 1;
};