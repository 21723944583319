<template>
    <Modal @shown="shown" @hidden="hidden" ref="modal" modalClass="modal-lg" @submit="submit">
        <template #title>
            <template v-if="user && !readonly">
                {{ i18n('Edit {name}').replace('{name}', i18n('User')) }}
            </template>
            <template v-else-if="user">
                {{ i18n('{name} Detail').replace('{name}', i18n('User')) }}
            </template>
            <template v-else>
                {{ i18n('Add {name}').replace('{name}', i18n('User')) }}
            </template>
        </template>

        <div class="alert alert-danger mb-2" v-if="errorMessage">{{ errorMessage }}</div>

        <div class="row g-2">
            <div class="col-12" :class="{ 'col-sm-4': !hasSingleUserOrigin(), 'col-sm-6': hasSingleUserOrigin() }">
                <Field v-model="formData.userType" :errorMessage="errorMessages.userType" :label="i18n('User Type')"
                    :disabled="submitted || (!!user && !!user.userType)" :required="true" :list="userTypes"
                    format="object" :type="readonly ? 'readonly' : 'dictionary'" />
            </div>
            <div class="col col-sm-4" v-if="!hasSingleUserOrigin()">
                <Field :disabled="submitted || !!user" type="dictionary" rest="types/userOrigins" :required="true"
                    :filter="(o) => user || o._originalObject.code != 'local'" :customAutofocus="true"
                    :label="i18n('Origin')" v-model="formData.userOrigin" :errorMessage="errorMessages.user_origin_id"
                    format="object" />
            </div>
            <div class="col-12" :class="{ 'col-sm-4': !hasSingleUserOrigin(), 'col-sm-6': hasSingleUserOrigin() }"
                v-if="formData.userOrigin && formData.userType">
                <Field v-model="formData.origin_identifier" :errorMessage="errorMessages.origin_identifier"
                    :label="i18n('Origin Identifier')" :disabled="submitted || !!user" :required="true"
                    :type="readonly ? 'readonly' : 'text'" v-if="!hasAutomaticOriginIdentifier || user" />

                <Field modelValue="[...]" :label="i18n('Origin Identifier')" :disabled="true"
                    :type="readonly ? 'readonly' : 'text'" v-else />
            </div>
        </div>

        <template v-if="formData.userType">
            <div class="row g-2 mt-0">
                <div class="col-12 col-sm-6">
                    <Field v-model="formData.first_name" :errorMessage="errorMessages.first_name"
                        :label="i18n('First Name')" :disabled="submitted || (user?.userOrigin?.code === 'local')"
                        :type="readonly ? 'readonly' : 'text'" />
                </div>
                <div class="col-12 col-sm-6">
                    <Field v-model="formData.last_name" :errorMessage="errorMessages.last_name"
                        :label="i18n('Last Name')" :disabled="submitted || (user?.userOrigin?.code === 'local')"
                        :type="readonly ? 'readonly' : 'text'" />
                </div>
                <div class="col-12" v-if="!hasAutomaticEmail || user">
                    <Field v-model="formData.email" :errorMessage="errorMessages.email" :label="i18n('Email')"
                        :disabled="submitted || (user?.userOrigin?.code === 'local') || !!user"
                        :type="readonly ? 'readonly' : 'email'" />
                </div>
                <div class="col-12" v-else>
                    <Field :modelValue="`[...]@${userTypeEmailDomain}`" :label="i18n('Email')" :disabled="true"
                        :type="readonly ? 'readonly' : 'text'" />
                </div>
                <div class="col-12">
                    <Field v-model="formData.alternative_email" :errorMessage="errorMessages.alternative_email"
                        :label="i18n('Alternative Email')" :disabled="submitted"
                        :type="readonly ? 'readonly' : 'email'" />
                </div>
                <div class="col-12 col-sm-6" v-if="formData?.userType?.code !== 'app'">
                    <Field v-model="formData.documentType" :errorMessage="errorMessages.documentType"
                        :label="i18n('Document Type')" :disabled="submitted" rest="types/documents" format="object"
                        :type="readonly ? 'readonly' : 'dictionary'" />
                </div>
                <div class="col-12 col-sm-6" v-if="formData?.userType?.code !== 'app'">
                    <Field v-model="formData.document" :errorMessage="errorMessages.document" :label="i18n('Document')"
                        :required="userOriginOriginIdentifierFunction.includes('Document')"
                        :disabled="submitted || (!!user && !!user.document) || (user?.userOrigin?.code === 'local')"
                        :type="readonly ? 'readonly' : 'text'" />
                </div>
                <template v-if="formData?.userType?.code !== 'app' && $root.features?.telephone?.enabled">
                    <div class="col-12 col-sm-6" v-if="formData.userType?.code == 'employee'">
                        <Field v-model="formData.telephone" :errorMessage="errorMessages.telephone"
                            :label="i18n('Telephone')" :disabled="submitted" :type="readonly ? 'readonly' : 'text'" />
                    </div>
                    <div class="col-12 col-sm-6"
                        v-if="formData.userType?.code == 'employee' && $root.features?.telephone?.extension?.enabled">
                        <Field v-model="formData.telephone_extension" :errorMessage="errorMessages.telephone_extension"
                            :label="i18n('Extension')" :disabled="submitted" :type="readonly ? 'readonly' : 'text'" />
                    </div>
                    <div class="col-12 col-sm-6"
                        v-if="formData.userType?.code != 'employee' && $root.features?.telephone?.mobile?.enabled">
                        <Field v-model="formData.contact_telephone" :errorMessage="errorMessages.contact_telephone"
                            :label="i18n('Contact Telephone')" :disabled="submitted"
                            :type="readonly ? 'readonly' : 'text'" />
                    </div>
                </template>
                <div class="col-12 col-sm-6" v-if="formData?.userType?.code !== 'app'">
                    <Field v-model="formData.company" :errorMessage="errorMessages.company" :label="i18n('Company')"
                        :disabled="submitted" rest="companies" format="object"
                        :type="readonly ? 'readonly' : 'dictionary'" />
                </div>
                <div class="col-12 col-sm-6" v-if="formData.userType?.code == 'employee' && formData.company">
                    <Field v-model="formData.office" :errorMessage="errorMessages.office" :label="i18n('Office')"
                        :disabled="submitted" :rest="`companies/${formData.company.id}/offices`" format="object"
                        :type="readonly ? 'readonly' : 'dictionary'" />
                </div>
                <div class="col-12 col-sm-6" v-if="formData.userType?.code == 'employee' && formData.company">
                    <Field v-model="formData.department" :errorMessage="errorMessages.department"
                        :label="i18n('Department')" :disabled="submitted"
                        :rest="`companies/${formData.company.id}/departments`" format="object"
                        :type="readonly ? 'readonly' : 'dictionary'" />
                </div>
                <div class="col-12 col-sm-6" v-if="formData.userType?.code == 'employee'">
                    <Field v-model="formData.employee_number" :errorMessage="errorMessages.employee_number"
                        :label="i18n('Employee Number')" :disabled="submitted" :type="readonly ? 'readonly' : 'text'" />
                </div>
                <div class="col-12" v-if="formData.userType?.code == 'employee' && formData.company">
                    <Field v-model="formData.responsibleUser" :errorMessage="errorMessages.responsibleUser"
                        :label="i18n('Responsible User')" :disabled="submitted"
                        :rest="`companies/${formData.company.id}/users`" format="object"
                        :type="readonly ? 'readonly' : 'dictionary'" />
                </div>
                <div class="col-12 col-sm-6" v-if="formData?.userType?.code !== 'app'">
                    <Field v-model="formData.language" :errorMessage="errorMessages.language" :label="i18n('Language')"
                        :disabled="submitted" :rest="`language`" format="object"
                        :type="readonly ? 'readonly' : 'dictionary'" />
                </div>
                <div class="col-12 col-sm-6" v-if="formData.userType?.code == 'employee'">
                    <Field v-model="formData.userLevel" :errorMessage="errorMessages.userLevel" :label="i18n('Level')"
                        :disabled="submitted" :rest="`types/userLevels`" format="object"
                        :type="readonly ? 'readonly' : 'dictionary'" />
                </div>
                <div class="col-12" v-if="formData.userType?.code == 'employee' && $root.features?.team?.enabled">
                    <Field v-model="formData.team" :errorMessage="errorMessages.team" :label="i18n('Team')"
                        :disabled="submitted" :rest="`teams`" format="object"
                        :type="readonly ? 'readonly' : 'dictionary'" />
                </div>
                <div class="col-12" v-if="formData.userType?.code == 'employee' && $root.features?.position?.enabled">
                    <Field v-model="formData.position" :errorMessage="errorMessages.position" :label="i18n('Position')"
                        :disabled="submitted" :type="readonly ? 'readonly' : 'text'" />
                </div>
                <div class="col-12 col-sm-9"></div>
                <div class="col-12  col-sm-6"
                    v-if="$root.features?.users?.validity && formData?.userType?.code !== 'app'">
                    <Field v-model="formData.starting_date" :errorMessage="errorMessages.starting_date"
                        :label="i18n('Start Date')" :disabled="submitted" format="date"
                        :type="readonly ? 'readonly' : 'date'" />
                </div>
                <div class="col-12  col-sm-6"
                    v-if="$root.features?.users?.validity && formData?.userType?.code !== 'app'">
                    <Field v-model="formData.ending_date" :errorMessage="errorMessages.ending_date"
                        :label="i18n('End Date')" :disabled="submitted" format="date"
                        :type="readonly ? 'readonly' : 'date'" />
                </div>
                <div class="col-12 col-sm-6"
                    v-if="formData.userType?.code == 'employee' && formData.company && $root.features?.inNameOf?.enabled">
                    <Field v-model="formData.inNameOfUser" :errorMessage="errorMessages.inNameUser"
                        :label="i18n('In Name Of')" :disabled="submitted"
                        :rest="`companies/${formData.company.id}/users`" format="object"
                        :type="readonly ? 'readonly' : 'dictionary'" />
                </div>
                <div class="col-12 col-sm-6"
                    v-if="$root.features?.users?.authorizedBy?.enabled && formData?.company?.id">
                    <Field v-model="formData.authorizedByExternalUser"
                        :errorMessage="errorMessages.authorizedByExternalUser" :label="i18n('Authorized By')"
                        :disabled="submitted" :rest="`companies/${formData.company.id}/users`" format="object"
                        :type="readonly ? 'readonly' : 'dictionary'" />
                </div>
                <div class="col-12  col-sm-6"
                    v-if="$root.features?.users?.authorizedBy?.enabled && formData?.userType?.code !== 'app'">
                    <Field v-model="formData.authorization_date" :errorMessage="errorMessages.authorization_date"
                        :label="i18n('Authorization Date')" :disabled="submitted" format="date"
                        :type="readonly ? 'readonly' : 'date'" />
                </div>
                <div class="col-12">
                    <Field v-model="formData.comments" :errorMessage="errorMessages.comments" :label="i18n('Comments')"
                        :disabled="submitted" format="text" :type="readonly ? 'readonly' : 'text'" />
                </div>
            </div>

            <div class="small mb-0 mt-2 pt-2 border-top text-muted"
                v-if="formData.userOrigin?.code == 'local' && localNote">
                <em>{{ localNote }}</em>
            </div>
            <div class="small mb-0 mt-2 pt-2 border-top text-muted"
                v-else-if="formData.userOrigin?.code == 'office365' && office365Note">
                <em>{{ office365Note }}</em>
            </div>
        </template>

        <template #footer>
            <div class="row">
                <template v-if="!readonly">
                    <div class="col text-start">
                        <button type="button" class="btn btn-danger" @click="hide">
                            {{ i18n('Cancel') }}
                        </button>
                    </div>
                    <div class="col text-end">
                        <button type="submit" class="btn btn-primary text-white" :disabled="submitted">
                            {{ i18n('Confirm') }}
                        </button>
                    </div>
                </template>
                <div class="col text-end" v-else>
                    <button type="button" @click="hide" class="btn btn-outline-dark btn-sm me-2">
                        {{ i18n('Close') }}
                    </button>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import { validateDniOrNie, getDniOrNieLetter, } from "@/tools/documentType.js";
import api from "@/api.js";
import { duplicate, } from "@/tools/object.js";
import Modal from "@/components/common/layout/Modal.vue";
import Field from "@/components/common/form/Field.vue";
import { translatedErrorMessage, } from "@/tools/errorHandling.js";
import { hasSingleUserOrigin } from "@/components/user/userOrigin.js";

export default {
    components: {
        Modal,
        Field,
    },
    data() {
        return {
            userId: null,
            user: null,
            submitted: false,
            formData: {},
            errorMessages: {},
            errorMessage: '',
            localNote: null,
            office365Note: null,
            userTypes: null,
            userOrigins: null,
            languages: null,
        };
    },
    emits: [
        'shown',
        'hidden',
        'created',
        'updated',
    ],
    computed: {
        readonly() {
            if (!this.user) return false;
            return this.user.acl?.update !== true;
        },
        userOriginOriginIdentifierFunction() {
            let functionName = null;

            if (this.formData?.userType?.code !== 'app'
                && this.formData?.userOrigin?.code) {
                functionName = this.$root.features?.login?.[this.formData?.userOrigin?.code]?.username_function || 'fromEmail';
            }

            return functionName || 'dummy';
        },
        hasAutomaticOriginIdentifier() {
            return this.userOriginOriginIdentifierFunction !== 'dummy';
        },
        userTypeEmailDomain() {
            let domain = null;

            if (this.formData?.userType?.code) {
                domain = this.$root.features?.users?.types?.[this.formData?.userType?.code]?.emailDomain || null;
            }

            return domain;
        },
        hasAutomaticEmail() {
            return !!this.userTypeEmailDomain;
        },
    },
    created() {
        this.localNote = this.i18n('Changes to the fields name, surname, email of local users must be edited in the corresponding section.');
        this.office365Note = this.i18n('Changes to the fields name, surname, email of Office 365 users will be overwritten by the active directory data at each login.');
    },
    methods: {
        show(userId) {
            this.userId = userId;
            this.user = null;
            this.load().then(() => {
                this.submitted = false;
                this.$refs['modal'].show();
                this.errorMessages = {};
                this.errorMessage = '';

                if (this.user) {
                    this.formData = duplicate(this.user);
                } else {
                    this.formData = {};

                    this.formData.userOrigin = this.userOrigins[0];
                    this.formData.language = this.languages.sort((a, b) => {
                        if (a.code === 'es') {
                            return -1;
                        } else if (b.code === 'es') {
                            return 1;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    })[0];
                    this.userTypes = this.userTypes.filter((userType) => {
                        const requiredPermissions = this.$root.features?.users?.types?.[userType.code]?.requiredPermissions || [];

                        return requiredPermissions.every((permission) => {
                            return this.can(permission);
                        });
                    });
                }
            });
        },
        load() {
            const promises = [];

            if (this.userId) {
                promises.push(api.get(`users/${this.userId}`, { acl: 'user' }).then(user => {
                    this.user = user;
                }));
            }

            promises.push(api.cached('types/users').then(userTypes => {
                this.userTypes = userTypes;
            }));

            promises.push(api.cached('types/userOrigins').then(userOrigins => {
                this.userOrigins = userOrigins;
            }));

            promises.push(api.cached('types/languages').then(languages => {
                this.languages = languages;
            }));

            return Promise.all(promises);
        },
        hide() {
            this.$refs['modal'].hide();
        },
        shown() {
            this.$emit('shown');
        },
        hidden() {
            this.$emit('hidden');
        },
        submit() {
            this.submitted = true;

            if (this.hasAutomaticEmail) {
                delete this.formData.email;
            }

            if (this.hasAutomaticOriginIdentifier) {
                delete this.formData.origin_identifier;
            }

            if (this.formData.email) {
                this.formData.email = this.formData.email.trim().toLowerCase();
            }

            if (this.formData.document &&
                !validateDniOrNie(this.formData.document)) {
                this.errorMessage = this.i18n('Invalid Document.');
                this.submitted = false;
                return;
            }

            this.errorMessages = {};
            this.errorMessage = '';

            const postFormData = duplicate(this.formData);
            let savePromise;

            if (!this.user) {
                savePromise = api.post(`users`, postFormData);
            } else {
                savePromise = api.put(`users/${this.user.id}`, postFormData);
            }

            savePromise.then(user => {
                if (!this.user) {
                    this.$emit('created', user);
                } else {
                    this.$emit('updated', user);
                }

                this.hide();
            }).catch((errorMessage) => {
                this.errorMessage = translatedErrorMessage(errorMessage);
                this.submitted = false;
            });
        },
        hasSingleUserOrigin,
    },
}
</script>
