<template>
  <DefaultLayout>
    <template #buttons>
      <RoleCreateAction @created="reload" v-if="can('ROLE_MANAGE')" />
    </template>

    <RoleFilter :filters="filters">
      <h1 class="h5 mt-2">
        {{ i18n('Roles') }}
        <span class="small text-muted" v-if="!pagination?.total">({{ roles.length }})</span>
        <span class="small text-muted" v-else>({{ pagination?.total }})</span>
      </h1>
    </RoleFilter>

    <RoleList :roles="roles" :loading="loading" :autoLazyLoad="!!pagination.nextPageURI" @deleted="reload"
      @updating="loading = true" @updated="reload" @next="nextPage" />
  </DefaultLayout>
</template>

<script>
import api from "@/api.js";
import {
  duplicate,
  flattenUrlObject,
} from "@/tools/object.js";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import RoleCreateAction from "@/components/role/RoleCreateAction.vue";
import RoleFilter from "@/components/role/RoleFilter.vue";
import RoleList from "@/components/role/RoleList.vue";

export default {
  components: {
    DefaultLayout,
    RoleCreateAction,
    RoleFilter,
    RoleList,
  },
  data() {
    return {
      roles: [],
      filters: {},
      loadTimeout: null,
      loading: true,
      pagination: {
        requestId: 0,
        limit: 10,
        records: [],
        loadPages: 1,
        nextPageURI: null,
        currentPage: 1,
        total: null,
      },
    }
  },
  mounted() {
    this.$root.setPageTitle(this.i18n('Roles'));
    api.auth().then((user) => {
      this.$root.loggedUser = user;

      if ((!this.can('ROLE_MANAGE')
        && !this.can('ROLE_ACCESS'))
        || !this.$root?.features?.roles?.enabled) {
        this.$router.push({
          name: 'Index',
        });
        return;
      }

      this.load();
    });
  },
  watch: {
    params: {
      handler() {
        this.pagination.loadPages = 1;
        this.reload();
      },
      deep: true,
    },
  },
  computed: {
    params() {
      let params = duplicate(this.filters);
      params.acl = 'role';
      params.limit = this.pagination.limit;

      flattenUrlObject(params);

      return params;
    },
    actions() {
      return [
        {
          label: this.i18n('Import'),
          fn: () => {
            this.showProfileRoleImportModal();
          },
          if: this.can('ROLE_MANAGE'),
        },
        {
          label: this.i18n('Export'),
          fn: () => {
            this.showProfileRoleExportModal();
          },
          if: this.can('ROLE_ACCESS') || this.can('ROLE_MANAGE'),
        },
      ];
    },
  },
  methods: {
    load(force) {
      clearTimeout(this.loadTimeout);
      this.loading = true;
      this.pagination.requestId++;

      if (!force) {
        this.loadTimeout = setTimeout(() => {
          this.load(true);
        }, 250);
        return;
      }

      this.pagination.records = [];
      this.pagination.currentPage = 1;
      this.loadPages(this.pagination.requestId, `roles`, this.params);
    },
    loadPages(requestId, uri, params) {
      api.getWithHeaders(uri, params).then((rolesResult) => {
        if (requestId != this.pagination.requestId) return;

        const roles = rolesResult.data,
          headers = rolesResult.headers;

        this.pagination.records = [
          ...this.pagination.records,
          ...roles,
        ];

        this.pagination.nextPageURI = headers.get('X-PAGINATE-NEXT');
        this.pagination.total = headers.get('X-PAGINATE-COUNT');

        if (this.pagination.currentPage < this.pagination.loadPages
          && this.pagination.nextPageURI) {
          this.pagination.currentPage++;
          this.loadPages(requestId, this.pagination.nextPageURI);
          return;
        } else {
          this.pagination.loadPages = this.pagination.currentPage;
        }

        this.roles = [...this.pagination.records];
        this.loading = false;
      });
    },
    nextPage() {
      this.loading = true;
      this.pagination.currentPage++;
      this.pagination.loadPages = this.pagination.currentPage;
      this.loadPages(++this.pagination.requestId, this.pagination.nextPageURI);
    },
    reload() {
      this.load();
    },
  },
};
</script>
