<template>
  <DefaultLayout>
    <template #buttons>
    </template>

    <UserFilter :filters="filters">
      <h1 class="h5 mt-2">
        {{ i18n('Users') }}
        <span class="small text-muted" v-if="!pagination?.total">({{ users.length }})</span>
        <span class="small text-muted" v-else>({{ pagination?.total }})</span>
      </h1>
      <template #buttons>
        <UserCreateAction cssClass="ms-1" @created="reload" v-if="can('USER_MANAGE')" />
      </template>
    </UserFilter>

    <UserList :users="users" :loading="loading" :autoLazyLoad="!!pagination.nextPageURI" @deleted="reload"
      @updating="loading = true" @updated="reload" @next="nextPage" />
  </DefaultLayout>
</template>

<script>
import api from "@/api.js";
import {
  duplicate,
  flattenUrlObject,
} from "@/tools/object.js";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import UserCreateAction from "@/components/user/UserCreateAction.vue";
import UserFilter from "@/components/user/UserFilter.vue";
import UserList from "@/components/user/UserList.vue";

export default {
  components: {
    DefaultLayout,
    UserCreateAction,
    UserFilter,
    UserList,
  },
  data() {
    return {
      users: [],
      filters: {
        is_deleted: 0,
      },
      loadTimeout: null,
      loading: true,
      pagination: {
        requestId: 0,
        limit: 10,
        records: [],
        loadPages: 1,
        nextPageURI: null,
        currentPage: 1,
        total: null,
      },
    }
  },
  mounted() {
    this.$root.setPageTitle(this.i18n('Users'));
    api.auth().then((user) => {
      this.$root.loggedUser = user;

      if (!this.can('USER_MANAGE')
        && !this.can('USER_ACCESS')) {
        this.$router.push({
          name: 'Index',
        });
        return;
      }

      this.load();
    });
  },
  watch: {
    params: {
      handler() {
        this.pagination.loadPages = 1;
        this.reload();
      },
      deep: true,
    },
  },
  computed: {
    params() {
      let params = duplicate(this.filters);
      params.acl = 'user';
      params.limit = this.pagination.limit;

      flattenUrlObject(params);

      if (!params.company) {
        delete params.office;
        delete params.department;
      }

      return params;
    },
  },
  methods: {
    load(force) {
      clearTimeout(this.loadTimeout);
      this.loading = true;
      this.pagination.requestId++;

      if (!force) {
        this.loadTimeout = setTimeout(() => {
          this.load(true);
        }, 250);
        return;
      }

      this.pagination.records = [];
      this.pagination.currentPage = 1;
      this.loadPages(this.pagination.requestId, `users`, this.params);
    },
    loadPages(requestId, uri, params) {
      api.getWithHeaders(uri, params).then((usersResult) => {
        if (requestId != this.pagination.requestId) return;

        const users = usersResult.data,
          headers = usersResult.headers;

        this.pagination.records = [
          ...this.pagination.records,
          ...users,
        ];

        this.pagination.nextPageURI = headers.get('X-PAGINATE-NEXT');
        this.pagination.total = headers.get('X-PAGINATE-COUNT');

        if (this.pagination.currentPage < this.pagination.loadPages
          && this.pagination.nextPageURI) {
          this.pagination.currentPage++;
          this.loadPages(requestId, this.pagination.nextPageURI);
          return;
        } else {
          this.pagination.loadPages = this.pagination.currentPage;
        }

        this.users = [...this.pagination.records];
        this.loading = false;
      });
    },
    nextPage() {
      this.loading = true;
      this.pagination.currentPage++;
      this.pagination.loadPages = this.pagination.currentPage;
      this.loadPages(++this.pagination.requestId, this.pagination.nextPageURI);
    },
    reload() {
      this.load();
    },
  },
};
</script>