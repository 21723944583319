<template>
    <Modal @shown="shown" @hidden="hidden" ref="modal" modalClass="modal-md" @submit="submit">
        <template #title>
            <template v-if="trigger && !readonly">
                {{ $root.i18n('Edit {name}').replace('{name}', $root.i18n('Trigger')) }}
            </template>
            <template v-else-if="trigger">
                {{ $root.i18n('{name} detail').replace('{name}', $root.i18n('Trigger')) }}
            </template>
            <template v-else>
                {{ $root.i18n('Add {name}').replace('{name}', $root.i18n('Trigger')) }}
            </template>
        </template>

        <div class="alert alert-danger mb-2" v-if="errorMessage">{{ errorMessage }}</div>

        <div class="row g-2">
            <div class="col-12 col-sm-6">
                <Field v-model="formData.application" :errorMessage="errorMessages.application" :label="$root.i18n('Application')" :disabled="submitted" rest="applications" format="object" :type="readonly ? 'readonly' : 'dictionary'" />
            </div>
            <div class="col-12 col-sm-6">
                <Field v-model="formData.profile" :errorMessage="errorMessages.profile" :label="$root.i18n('Profile')" :disabled="submitted" rest="applications/{application}/profiles" format="object" :type="readonly ? 'readonly' : 'dictionary'" />
            </div>
            <div class="col-12 col-sm-6">
                <Field v-model="formData.triggerType" :errorMessage="errorMessages.triggerType" :label="$root.i18n('Trigger Type')" :disabled="submitted" rest="types/triggers" format="object" :type="readonly ? 'readonly' : 'dictionary'" />
            </div>
            <div class="col-12 col-sm-6">
                <Field v-model="formData.params" :errorMessage="errorMessages.params" :label="$root.i18n('Params')" :disabled="submitted" :required="true" :type="readonly ? 'readonly' : 'text'" />
            </div>
        </div>

        <template #footer>
            <div class="row">
                <template v-if="!readonly">
                    <div class="col text-start">
                        <button type="button" class="btn btn-danger" @click="hide">
                            {{ $root.i18n('Cancel') }}
                        </button>
                    </div>
                    <div class="col text-end">
                        <button type="submit" class="btn btn-primary text-white" :disabled="submitted">
                            {{ $root.i18n('Confirm') }}
                        </button>
                    </div>
                </template>
                <div class="col text-end" v-else>
                    <button type="button" @click="hide" class="btn btn-outline-dark btn-sm me-2">
                        {{ $root.i18n('Close') }}
                    </button>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import api from "@/api.js";
import {
    duplicate,
} from "@/tools/object.js";
import Modal from "@/components/common/layout/Modal.vue";
import Field from "@/components/common/form/Field.vue";

export default {
    components: {
        Modal,
        Field,
    },
    props: {
    },
    data() {
        return {
            triggerId: null,
            trigger: null,
            submitted: false,
            formData: {},
            errorMessages: {},
            errorMessage: '',
        };
    },
    emits: [
        'shown',
        'hidden',
        'created',
        'updated',
    ],
    computed: {
        readonly() {
            if (!this.trigger) {
              return false;
            }

            return !(this.trigger.acl?.update === true);
        },
    },
    methods: {
        show(triggerId) {
            this.triggerId = triggerId;
            this.trigger = null;
            this.load().then(() => {
                this.submitted = false;
                this.$refs['modal'].show();
                this.errorMessages = {};
                this.errorMessage = '';

                if (this.trigger) {
                    this.formData = duplicate(this.trigger);
                } else {
                    this.formData = {};
                }
            });
        },
        load() {
            if (!this.triggerId) {
              return Promise.resolve();
            }

            let promise = api.get(`triggers/${this.triggerId}`, {acl: 'trigger'});

            promise.then(trigger => {
                this.trigger = trigger;
            });

            return promise;
        },
        hide() {
            this.$refs['modal'].hide();
        },
        shown() {
            this.$emit('shown');
        },
        hidden() {
            this.$emit('hidden');
        },
        submit() {
            this.submitted = true;
            this.errorMessages = {};
            this.errorMessage = '';

            const postFormData = duplicate(this.formData);
            let savePromise;

            if (!this.trigger) {
                savePromise = api.post(`triggers`, postFormData);
            } else {
                savePromise = api.put(`triggers/${this.trigger.id}`, postFormData);
            }

            savePromise.then(trigger => {
                if (!this.trigger) {
                    this.$emit('created', trigger);
                } else {
                    this.$emit('updated', trigger);
                }

                this.hide();
            }).catch((errorMessage) => {
                this.errorMessage = translatedErrorMessage(errorMessage);
                this.submitted = false;
            });
        }
    }
}
</script>