<template>
  <div class="p-5 text-center">
    <span class="spinner-border spinner-border-sm" role="status">
      <span class="visually-hidden">
        {{ i18n('Loading...') }}
      </span>
    </span>
  </div>
</template>

<script>
import api from "@/api.js";

export default {
  components: {},
  data() {
    return {};
  },
  created() {
    this.$root.setPageTitle(this.i18n('Do Login'));

    if (!this.$route.query.code &&
      !this.$route.query.error) {
      this.$route.query.error = 'Error';
    }

    api.login(
      this.$route.query, {
      name: this.$route.name,
      params: this.$route.params
    }).then((route) => {
      this.$root.loadFeatures();
      api.auth().then((user) => {
        let lang = user?.language?.code;

        if (lang && lang !== this.getCurrentLang()) {
          this.switchLang(lang, false);
        }

        this.$router.push(route);
      });
    });
  },
};
</script>