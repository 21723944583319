<template>
    <button type="button" :class="btnClass" @click="showTriggerModal">
        {{ $root.i18n('Add New') }}
    </button>

    <TriggerModal ref="TriggerModal" @created="$emit('created', $event)" />
</template>
<script>
import TriggerModal from "@/components/trigger/TriggerModal.vue";

export default {
  components: {
    TriggerModal,
  },
  props: {
    btnClass: {
      type: String,
      default: 'btn btn-sm btn-outline-dark',
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  emits: [
    'created'
  ],
  methods: {
    showTriggerModal() {
      this.$refs['TriggerModal'].show();
    },
  }
};
</script>