<template>
    <ApiViewWrapper :notFound="notFound" :allowAnonymous="false">
        <DefaultLayout>
            <div class="collapsable-header p-2 border">
                <UserDetailHeader :user="user" @goBack="goBack" />
            </div>
            <SectionManager sectionType="none" stickyY="auto">
                <SectionItem>
                    <template #title>
                        {{ i18n('Main Information') }}
                    </template>
                    <div class="bg-white p-2 border">
                        <div class="row g-2 align-items-center">
                            <div class="col">
                                <h2 class="h5">
                                    {{ i18n('Main Information') }}
                                </h2>
                            </div>
                            <div class="col text-end">
                                <ActionDropdown ref="actions" :actions="actions" />
                            </div>
                        </div>
                        <div class="row g-2 mt-0">
                            <div class="col-12 col-sm-6">
                                <FieldReadonly :value="user.userOrigin" :label="i18n('Origin')" />
                            </div>
                            <div class="col-12 col-sm-6">
                                <template v-if="user?.metadata?.AD_STATUS === 'PENDING'">
                                    <div class="read-only">
                                        <div class="small text-muted">
                                            {{ i18n('Origin Identifier') }}
                                        </div>
                                        <Tooltip :title="i18n('Pending update on AD')">
                                            <span class="text-danger me-1">⚠</span>
                                        </Tooltip>
                                        <FormattedValue :value="user.origin_identifier" />
                                    </div>
                                </template>
                                <template v-else>
                                    <FieldReadonly :value="user.origin_identifier" :label="i18n('Origin Identifier')" />
                                </template>
                            </div>
                        </div>
                        <div class="row g-2 mt-0">
                            <div class="col-12 col-sm-6">
                                <FieldReadonly :value="user.first_name" :label="i18n('First Name')" />
                            </div>
                            <div class="col-12 col-sm-6">
                                <FieldReadonly :value="user.last_name" :label="i18n('Last Name')" />
                            </div>
                        </div>
                        <div class="row g-2 mt-0">
                            <div class="col-12">
                                <FieldReadonly
                                    :value="user.email + (user.alternative_email != null ? ' / ' + user.alternative_email : '')"
                                    :label="i18n('Email')" />
                            </div>
                            <div class="col-12 col-sm-6" v-if="$root.features?.document">
                                <FieldReadonly :value="user.documentType?.name + ' / ' + user.document"
                                    :label="i18n('Document')" />
                            </div>
                            <div class="col-12 col-sm-6">
                                <FieldReadonly :value="user.userType" :label="i18n('User Type')" format="object" />
                            </div>
                            <div class="col-12 col-sm-6" v-if="user.userType?.code === 'employee' && user.company">
                                <FieldReadonly :value="user.company" :label="i18n('Company')" format="object" />
                            </div>
                            <div class="col-12 col-sm-6"
                                v-if="user.userType?.code === 'employee' && user.company && $root.features?.office?.enabled">
                                <FieldReadonly :value="user.office?.name" :label="i18n('Office')" format="object" />
                            </div>
                            <div class="col-12 col-sm-6"
                                v-if="user.userType?.code === 'employee' && user.company && $root.features?.department?.enabled">
                                <FieldReadonly :value="user.department?.name" :label="i18n('Department')"
                                    format="object" />
                            </div>
                            <div class="col-12 col-sm-6" v-if="$root.features?.responsible?.enabled">
                                <FieldReadonly
                                    :value="user.responsibleUser !== null ? user.responsibleUser?.first_name + ' ' + user.responsibleUser?.last_name : '--'"
                                    :label="i18n('Responsible User')" format="object" />
                            </div>
                            <div class="col-12 col-sm-6" v-if="user.userType?.code === 'employee' && user.company">
                                <FieldReadonly :value="user.position" :label="i18n('Position')" format="object" />
                            </div>
                            <div class="col-12 col-sm-6" v-if="user.userType?.code === 'employee' && user.company">
                                <FieldReadonly :value="user.userLevel?.name" :label="i18n('Level')" format="object" />
                            </div>
                            <div class="col-12 col-sm-6"
                                v-if="user.userType?.code === 'employee' && user.company && ($root.features?.position || true)">
                                <FieldReadonly :value="user.employee_number" :label="i18n('Employee Number')" />
                            </div>
                            <div class="col-12 col-sm-6" v-if="$root.features?.telephone?.enabled">
                                <FieldReadonly
                                    :value="(user.telephone != null ? user.telephone : '') + (user.telephone_extension != null ? ' / ' + user.telephone_extension : '') + (user.contact_telephone != null ? ' / ' + user.contact_telephone : '')"
                                    :label="i18n('Mobile / Extension / Contact Tel.')" />
                            </div>
                            <div class="col-12 col-sm-6">
                                <FieldReadonly :value="user.language" :label="i18n('Language')" />
                            </div>
                            <div class="col-12 col-sm-6" v-if="user.userType?.code !== 'employee'">
                                <FieldReadonly :value="user.company?.name" :label="i18n('Company')" />
                            </div>
                            <div class="col-12 col-sm-6"
                                v-if="user.userType?.code == 'employee' && $root.features?.team?.enabled">
                                <FieldReadonly :value="user.team?.name" :label="i18n('Team')" />
                            </div>
                            <div class="col-12 col-sm-6" v-if="user?.metadata?.AD_STATUS">
                                <FieldReadonly :value="i18n(user.metadata.AD_STATUS)" :label="i18n('AD Status')" />
                            </div>
                            <div class="col-12 col-sm-6" v-if="user?.metadata?.AD_PASSWORD_EXPIRATION">
                                <FieldReadonly :value="user?.metadata?.AD_PASSWORD_EXPIRATION"
                                    :label="i18n('Password Expiration Date')" format="date" />
                            </div>
                        </div>
                        <div class="row g-2 mt-0">
                            <div class="col-12 col-sm-6" v-if="user.ending_date">
                                <FieldReadonly :value="user.ending_date" :label="i18n('End Date')" format="date" />
                            </div>
                            <div class="col-12 col-sm-6" v-if="user.starting_date">
                                <FieldReadonly :value="user.starting_date" :label="i18n('Start Date')" format="date" />
                            </div>
                        </div>
                        <div class="row g-2 mt-0">
                            <div class="col-12 col-sm-6" v-if="$root.features?.users?.authorizedBy?.enabled">
                                <FieldReadonly :value="user.authorizedByExternalUser" :label="i18n('Authorized By')"
                                    format="object" />
                            </div>
                            <div class="col-12 col-sm-6" v-if="$root.features?.users?.authorizedBy?.enabled">
                                <FieldReadonly :value="user.authorization_date" :label="i18n('Authorization Date')"
                                    format="date" />
                            </div>
                        </div>
                        <div class="row g-2 mt-0">
                            <div class="col-12">
                                <FieldReadonly :value="user.comments" :label="i18n('Comments')" />
                            </div>
                        </div>
                    </div>
                </SectionItem>
                <SectionItem>
                    <template #title>
                        {{ i18n('Profiles') }}
                    </template>
                    <UserUserProfileSection :user="user" @updated="reload" />
                </SectionItem>
            </SectionManager>
            <UserModal ref="UserModal" @updated="reload" />
        </DefaultLayout>
    </ApiViewWrapper>
</template>

<script>
import api from "@/api.js";
import { hasSingleUserOrigin } from "@/components/user/userOrigin.js";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";
import ApiViewWrapper from "@/components/common/view/ApiViewWrapper.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import FieldReadonly from "@/components/common/form/FieldReadonly.vue";
import FormattedValue from "@/components/common/form/FormattedValue.vue";
import SectionItem from "@/components/common/layout/section/SectionItem.vue";
import SectionManager from "@/components/common/layout/section/SectionManager.vue";
import Tooltip from "@/components/common/layout/Tooltip.vue";
import UserDetailHeader from "@/components/user/UserDetailHeader.vue";
import UserModal from "@/components/user/UserModal.vue";
import UserUserProfileSection from "@/components/user/UserUserProfileSection.vue";

export default {
    components: {
        ActionDropdown,
        ApiViewWrapper,
        DefaultLayout,
        FieldReadonly,
        FormattedValue,
        SectionItem,
        SectionManager,
        Tooltip,
        UserDetailHeader,
        UserModal,
        UserUserProfileSection,
    },
    data() {
        return {
            user: null,
            notFound: null,
        };
    },
    mounted() {
        api.auth().then((user) => {
            this.$root.loggedUser = user;

            this.load();
        });

    },
    watch: {
        "$route.params.id"() {
            this.load();
        },
    },
    computed: {
        actions() {
            return [{
                label: this.i18n('Edit'),
                fn: () => {
                    this.showUserModal();
                },
                if: (this.user.acl?.update) === true,
                starred: true,
            },
            {
                label: this.i18n('Do Discharge'),
                fn: () => {
                    this.$root.requestConfirmation(this.i18n('Do Discharge?'), () => {
                        this.$emit('updating', this.user);
                        api.delete(`users/${this.user.id}`).then(() => {
                            this.reload();
                        }).then(() => {
                            this.reload();
                        });
                    });
                },
                if: (this.user.acl?.delete) === true,
                starred: false,
            }, {
                label: this.i18n('Do Restore'),
                fn: () => {
                    this.$root.requestConfirmation(this.i18n('Do Restore?'), () => {
                        this.$emit('updating', this.user);
                        api.post(`users/${this.user.id}/restore`).then(() => {
                            this.reload();
                        }).then(() => {
                            this.reload();
                        });
                    });
                },
                if: (this.user.acl?.restore) === true,
                starred: true,
            },];
        },
    },
    hasSingleUserOrigin,
    methods: {
        goBack() {
            this.$router.push({
                name: 'UserIndex',
            });
        },
        load(force) {
            clearTimeout(this.loadTimeout);
            this.notFound = null;

            if (!force) {
                this.loadTimeout = setTimeout(() => {
                    this.load(true);
                }, 250);
                return;
            }

            if (!this.$route.params.id) {
                this.notFound = true;
                return;
            }

            api.get(`users/${this.$route.params.id}`, { acl: 'user' }).then((user) => {
                if (user.acl?.view !== true) {
                    this.$router.push({ name: 'UserIndex', });
                    return;
                }

                this.user = user;
                this.notFound = false;
            }).catch(() => {
                this.notFound = true;
            });
        },
        reload() {
            this.load();
        },
        showUserModal() {
            if (!this.$refs.UserModal) return;
            this.$refs.UserModal.show(this.user.id);
        },
    },
};
</script>