<template>
    <div class="card" @click="click" role="button">
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col-12 col-md-4 text-truncate" v-if="withApplication">
                    <a href="javascript:void(0)">
                        {{ application.name }}
                    </a>
                    <div>
                        <span class="text-muted">
                            <small>
                                <em>
                                    #{{ application.id }}
                                </em>
                            </small>
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-4 text-truncate" v-if="withUser">
                    <a href="javascript:void(0)">
                        {{ user.first_name }} {{ user.last_name }}
                    </a>
                    <div>
                        <span class="text-muted">
                            <small>
                                <em>
                                    #{{ user.id }}
                                </em>
                            </small>
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-3 text-truncate" v-if="$root.features?.profiles?.application_username">
                    <FormattedValue :value="profile.username" />
                </div>
                <div class="col-10 text-truncate"
                    :class="{ 'col-md-3': !!$root.features?.profiles?.application_username, 'col-md-6': !$root.features?.profiles?.application_username }">
                    <a href="javascript:void(0)">
                        {{ profile.name }}
                    </a>
                    <div>
                        <span class="text-muted">
                            <small>
                                <em>
                                    #{{ profile.id }}
                                </em>
                            </small>
                        </span>
                    </div>
                </div>

                <div class="col-2 text-end" @click.stop v-if="!hideActions">
                    <ActionDropdown :actions="actions" />
                </div>
            </div>
        </div>
    </div>
    <UserProfileModal :user="user" ref="UserProfileModal" />
</template>

<script>
import api from "@/api.js";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";
import DangerModal from "@/components/common/confirm/DangerModal.vue";
import FormattedValue from "@/components/common/form/FormattedValue.vue";
import UserProfileModal from "@/components/userProfile/UserProfileModal.vue";

export default {
    components: {
        ActionDropdown,
        DangerModal,
        FormattedValue,
        UserProfileModal,
    },
    emits: [
        'updated',
        'editUsername',
    ],
    props: {
        withUser: {
            type: Boolean,
            default: false,
        },
        withApplication: {
            type: Boolean,
            default: true,
        },
        application: {
            type: Object,
            default() {
                return {};
            },
        },
        user: {
            type: Object,
            default() {
                return {};
            },
        },
        profile: {
            type: Object,
            default() {
                return {};
            },
        },
        hideActions: {
            type: Boolean,
            default: false,
        },
        expiresAt: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
        };
    },
    methods: {
        click() { },
        remove() {
            this.$root.setSaving(true);
            api.delete(`users/${this.user.id}/profiles/${this.profile.id}`).then(() => {
                this.$root.setSaved(true);
                this.$root.setLoading(false);
                let index = (this.user.profiles || []).map(e => e.id).indexOf(
                    this.profile.id);

                if (index !== -1) {
                    this.user.profiles.splice(index, 1);
                }

                this.$emit('updated');
            }).catch((err) => {
                console.error('Error deleting profile:', err);
            }).finally(() => {
                this.$root.setSaved(true);
                this.$root.setLoading(false);
            });
        },
    },
    computed: {
        actions() {
            return [{
                label: this.i18n('Update User Application Username'),
                fn: () => {
                    this.$emit('editUsername', { user: { id: this.user.id }, profile: { application: { id: this.application.id } } });
                },
                if: (this.user?.acl?.update) === true && (!this.application?.is_self && this.application?.is_identity) && this.$root.features?.profiles?.application_username,
                starred: false,
            }, {
                label: this.i18n('Remove'),
                fn: () => {
                    this.$root.requestConfirmation(this.i18n('Remove?'), () => {
                        this.remove();
                    });
                },
                if: this.user?.acl?.update === true,
            }];
        },
    },
};
</script>
