<template>
  <button type="button" :class="btnClass" @click="showDepartmentModal">
    <FileEarmarkPlusIcon />
    {{ i18n('Add New') }}
  </button>

  <DepartmentModal ref="DepartmentModal" @created="$emit('created', $event)" :company="company" />
</template>
<script>
import DepartmentModal from "@/components/department/DepartmentModal.vue";
import FileEarmarkPlusIcon from "@/assets/svg/file-earmark-plus.vue";

export default {
  components: {
    DepartmentModal,
    FileEarmarkPlusIcon,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    btnClass: {
      type: String,
      default: 'btn btn-outline-light',
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  emits: [
    'added',
    'created'
  ],
  methods: {
    showDepartmentModal() {
      this.$refs['DepartmentModal'].show();
    },
  }
};
</script>