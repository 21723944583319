<template>
  <ApiViewWrapper :notFound="notFound" :allowAnonymous="false">
    <DefaultLayout>
      <h1 class="h5 d-flex align-items-center" :class="{ 'mt-2': !role.svg }">
        <img alt="" :src="svgDataUrl" class="me-2" v-if="role.svg" style="width: 2rem" />
        <div>
          <span class="small text-muted">{{ i18n('Role') }}</span>
          {{ role.name }}
        </div>
      </h1>

      <SectionManager sectionType="none" stickyY="auto">
        <SectionItem>
          <template #title>
            {{ i18n('Main Information') }}
          </template>
          <div class="bg-white p-2 border">
            <div class="row g-2 align-items-center">
              <div class="col">
                <h2 class="h5">
                  {{ i18n('Main Information') }}
                </h2>
              </div>
              <div class="col text-end">
                <ActionDropdown ref="actions" :actions="actions" />
              </div>
            </div>
            <div class="row g-2">
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="role.name" :label="i18n('Name')" />
              </div>
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="role.userType" :label="i18n('User Type')" />
              </div>
              <template v-if="$root?.features?.roles?.team?.enabled">
                <div class="col-6">
                  <FieldReadonly :value="role.team" :label="i18n('Team')" />
                </div>
                <div class="col-6" v-if="role.team">
                  <FieldReadonly :value="role.is_default_for_team ? i18n('Default') : i18n('Optional')"
                    :label="i18n('Assignment')" />
                </div>
              </template>
            </div>
          </div>
        </SectionItem>
        <SectionItem v-if="can('ROLE_ACCESS') || can('ROLE_MANAGE')">
          <template #title>
            {{ i18n('Profiles') }}
          </template>
          <ProfileRoleSection :role="role" />
        </SectionItem>
        <SectionItem v-if="can('USER_ACCESS') || can('USER_MANAGE')">
          <template #title>
            {{ i18n('Users') }}
          </template>
          <UserRoleSection :role="role" />
        </SectionItem>
      </SectionManager>
      <RoleModal ref="RoleModal" @updated="reload" />
    </DefaultLayout>
  </ApiViewWrapper>
</template>

<script>
import api from "@/api.js";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ApiViewWrapper from "@/components/common/view/ApiViewWrapper.vue";
import SectionManager from "@/components/common/layout/section/SectionManager.vue";
import SectionItem from "@/components/common/layout/section/SectionItem.vue";
import FieldReadonly from "@/components/common/form/FieldReadonly.vue";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";
import UserRoleSection from "@/components/userRole/UserRoleSection.vue";
import ProfileRoleSection from "@/components/profileRole/ProfileRoleSection.vue";
import RoleModal from "@/components/role/RoleModal.vue";

export default {
  components: {
    ApiViewWrapper,
    DefaultLayout,
    SectionManager,
    SectionItem,
    FieldReadonly,
    ActionDropdown,
    UserRoleSection,
    RoleModal,
    ProfileRoleSection,
  },
  data() {
    return {
      role: null,
      notFound: null,
    };
  },
  mounted() {
    api.auth().then((user) => {
      this.$root.loggedUser = user;

      if ((!this.can('ROLE_MANAGE')
        && !this.can('ROLE_ACCESS'))
        || !this.$root?.features?.roles?.enabled) {
        this.$router.push({
          name: 'Index',
        });
        return;
      }

      this.load();
    });
  },
  watch: {
    "$route.params.id"() {
      this.load();
    },
  },
  computed: {
    svgDataUrl() {
      return `data:image/svg+xml;base64,${btoa(this.role.svg)}`;
    },
    actions() {
      return [{
        label: this.i18n('Edit'),
        fn: () => {
          this.showRoleModal();
        },
        if: (this.role.acl?.update) === true,
        starred: true,
      },
      {
        label: this.i18n('Delete'),
        fn: () => {
          this.$root.requestConfirmation(this.i18n('Delete? This action cannot be undone.'), () => {
            this.$emit('updating', this.role);
            api.delete(`roles/${this.role.id}`).then(() => {
              api.clearCache();
              this.$router.push({ name: 'RoleIndex', });
            }).then(() => {
              this.reload();
            });
          });
        },
        if: (this.role.acl?.delete) === true,
        starred: false,
      },
      ];
    },
  },
  methods: {
    load(force) {
      clearTimeout(this.loadTimeout);
      this.notFound = null;

      if (!force) {
        this.loadTimeout = setTimeout(() => {
          this.load(true);
        }, 250);
        return;
      }

      if (!this.$route.params.id) {
        this.notFound = true;
        return;
      }

      api.get(`roles/${this.$route.params.id}`, { acl: 'role' }).then((role) => {
        if (role.acl?.view !== true) {
          this.$router.push({ name: 'RoleIndex', });
          return;
        }

        this.role = role;
        this.notFound = false;
      }).catch(() => {
        this.notFound = true;
      });
    },
    reload() {
      this.load();
    },
    showRoleModal() {
      if (!this.$refs.RoleModal) return;
      this.$refs.RoleModal.show(this.role.id);
    },
  },
};
</script>