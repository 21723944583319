<template>
  <div class="bg-white p-2">
    <div class="row pt-1 mb-2">
      <div class="col-6">
        <h2 class="h6 mt-1">{{ i18n('Profiles') }}</h2>
      </div>
      <div class="col-6 text-end" v-if="!hideActions">
        <UserProfileCreateAction :user="user" />
      </div>
    </div>
    <UserProfileList :profiles="user.profiles" :user="user" :hideActions="hideActions" @updated="$emit('updated')" />
  </div>
</template>

<script>
import UserProfileCreateAction from "@/components/userProfile/UserProfileCreateAction.vue";
import UserProfileList from "@/components/userProfile/UserProfileList.vue";
export default {
  components: {
    UserProfileCreateAction,
    UserProfileList,
  },
  emits: ['updated'],
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>