<template>
    <Modal @shown="shown" @hidden="hidden" ref="modal" @submit="submit">
        <template #title>
            {{ i18n('Add Profile') }}
        </template>
        <div class="alert alert-danger mb-2" v-if="errorMessage">{{ errorMessage }}</div>

        <div class="row">
            <div class="col mb-2">
                <Field :disabled="submitted" type="dictionary" rest="applications" :required="true"
                    :customAutofocus="true" :label="i18n('Application')" v-model="formData.application_id"
                    :errorMessage="errorMessages.application_id"
                    :sort="(a, b) => { return a.name.localeCompare(b.name); }" />

            </div>
        </div>

        <div class="row" v-if="formData.application_id">
            <div class="col mb-2">
                <Field :disabled="submitted" type="dictionary"
                    :rest="`applications/${formData.application_id}/profiles`" :required="true" :customAutofocus="true"
                    :label="i18n('Profile')" v-model="formData.profile_id" :errorMessage="errorMessages.profile_id"
                    :sort="(a, b) => { return a.name.localeCompare(b.name); }" />
            </div>
        </div>

        <template #footer>
            <div class="row">
                <div class="col text-end">
                    <button type="submit" class="btn btn-dark btn-sm" :disabled="submitted">
                        {{ i18n('Save') }}
                    </button>
                </div>
            </div>
        </template>
    </Modal>
</template>
<script>
import api from "@/api.js";
import Modal from "@/components/common/layout/Modal.vue";
import Field from "@/components/common/form/Field.vue";
import MultipleDictionaryField from "@/components/common/form/MultipleDictionaryField.vue";
import { translatedErrorMessage, } from "@/tools/errorHandling.js";

export default {
    components: {
        Modal,
        Field,
        MultipleDictionaryField,
    },
    data() {
        return {
            submitted: false,
            formData: {},
            errorMessages: {},
            errorMessage: '',
        };
    },
    props: {
        role: {
            type: Object,
        },
    },
    emits: [
        'shown',
        'hidden',
        'submit',
        'created',
    ],
    mounted() {
        this.setFormData();
    },
    methods: {
        setFormData() {
            let formData = {};
            this.formData = formData;
        },
        show() {
            this.submitted = false;
            this.setFormData();
            this.formData.expires_at = null;
            this.errorMessage = '';

            if (this.role) {
                this.formData.role_id = this.role.id;
            } else {
                this.formData.role_id = null;
            }

            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        shown() {
            this.$emit('shown');
        },
        hidden() {
            this.$emit('hidden');
        },
        submit() {
            let promise;
            this.errorMessage = '';
            this.submitted = true;

            if (!this.formData.profile_id) return;
            promise = api.post('profiles/' + this.formData.profile_id + '/roles/' + this.formData.role_id, {});

            promise.then(async (profile) => {
                let role = await api.get('roles/' + this.formData.role_id);

                if (this.role) {
                    this.role.profiles = role.profiles;
                }

                this.$emit('submit', profile);
                this.$emit('created', profile);
                this.formData = {};
                this.hide();
                api.clearCache();
            }).catch((errorMessage) => {
                this.errorMessage = translatedErrorMessage(errorMessage);
                this.submitted = false;

                this.submitted = false;
            });
        },
    }
};
</script>