<template>
  <tr @dblclick="$refs['actions'].callFirstStarredAction();"
    class="d-block d-lg-table-row bg-white px-2 py-2 mb-2 border p-lg-0 mb-lg-0 border-lg-0">
    <td class="d-block d-lg-table-cell">
      <img :src="localUser.avatar_url" alt="" style="min-width: 50px; max-width: 100px;" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Id') }}</span>
      <FieldReadonly :value="localUser.id" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Provider') }}</span>
      <FieldReadonly :value="localUser.provider" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('First Name') }}</span>
      <FieldReadonly :value="localUser.first_name" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Last Name') }}</span>
      <FieldReadonly :value="localUser.last_name" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Email') }}</span>
      <FieldReadonly :value="localUser.email" />
    </td>
    <td data-no-export class="d-block d-lg-table-cell text-end border-0 border-lg-bottom" @click.stop>
      <ActionDropdown ref="actions" :actions="actions" />
    </td>
  </tr>
</template>

<script>
import api from "@/api.js";
import FieldReadonly from "@/components/common/form/FieldReadonly.vue";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";

export default {
  components: {
    FieldReadonly,
    ActionDropdown,
  },
  props: {
    localUser: Object,
  },
  data() {
    return {};
  },
  emits: [
    'edit',
    'password',
    'deleted',
    'updating',
    'updated',
  ],
  methods: {
  },
  computed: {
    actions() {
      return [
        {
          label: this.i18n('Detail'),
          fn: () => {
            this.$router.push({
              name: 'UserDetail',
              params: {
                id: this.localUser.user_id,
              },
            });
          },
          if: !!this.localUser.user_id,
          starred: true,
        }, {
          label: this.i18n('Edit Contact Information'),
          fn: () => {
            this.$emit('edit', this.localUser);
          },
          if: (this.localUser.acl?.update) === true,
          starred: false,
        },
        {
          label: this.i18n('Change Password'),
          fn: () => {
            this.$emit('password', this.localUser);
          },
          if: (this.localUser.acl?.setPassword) === true,
          starred: false,
        },
        {
          label: this.i18n('Send New Password'),
          fn: () => {
            this.$emit('updating', this.localUser);
            this.$root.requestConfirmation(this.i18n('Are you sure you want to generate a new password for this user?'), () => {
              api.post(`localUsers/${this.localUser.id}/sendNewPassword`).then(() => {
                this.$root.showSuccessModal(this.i18n('New password sent.'));
                this.$emit('updated', this.localUser);
              }).then(() => {
                // Force reload
                this.$emit('updated', this.localUser);
              });
            });
          },
          if: (this.localUser.acl?.sendNewPassword) === true,
          starred: false,
        },
        {
          label: this.i18n('Delete'),
          fn: () => {
            this.$emit('updating', this.localUser);
            this.$root.requestConfirmation(this.i18n('Delete? This action cannot be undone.'), () => {
              api.delete(`localUsers/${this.localUser.id}`).then(() => {
                this.$emit('deleted', this.localUser);
              }).then(() => {
                // Force reload
                this.$emit('updated', this.localUser);
              });
            });
          },
          if: (this.localUser.acl?.delete) === true,
          starred: false,
        },
      ];
    },
  },
};
</script>