<template>
    <DashboardCounters :title="i18n('Dashboard')" :filters="filters" :linkable="true" />
    <div class="row g-2">
        <div v-if="user && user.id" class="row g-2">
            <div class="col-12 col-lg-12">
                <Statistics :user="user.id" :statistic="statistic" />
            </div>
            <div class="col-12 col-lg-2">
                <div class="col-12 col-sm-6">
                    <FieldReadonly :value="user.first_name" :label="i18n('First Name')" />
                </div>
                <div class="col-12 col-sm-6">
                    <FieldReadonly :value="user.last_name" :label="i18n('Last Name')" />
                </div>
                <div class="col-12 col-sm-6">
                    <FieldReadonly :value="user.userOrigin" :label="i18n('Origin')" />
                </div>
                <div class="col-12 col-sm-6">
                    <FieldReadonly :value="user.origin_identifier" :label="i18n('Origin Identifier')" />
                </div>
                <div class="col-12">
                    <FieldReadonly :value="user.email" :label="i18n('Email')" />
                </div>
                <div class="col-12 col-sm-6">
                    <FieldReadonly :value="user.userType" :label="i18n('User Type')" format="object" />
                </div>
                <div class="col-12 col-sm-6" v-if="user.userOrigin?.code === 'employee' && user.company">
                    <FieldReadonly :value="user.company" :label="i18n('Company')" format="object" />
                </div>
                <div class="col-12 col-sm-6" v-if="user.userOrigin?.code === 'employee' && user.company">
                    <FieldReadonly :value="user.office" :label="i18n('Office')" format="object" />
                </div>
                <div class="col-12 col-sm-6" v-if="user.userOrigin?.code === 'employee' && user.company">
                    <FieldReadonly :value="user.department" :label="i18n('Department')" format="object" />
                </div>
                <div class="col-12 col-sm-6" v-if="user.userOrigin?.code === 'employee' && user.company">
                    <FieldReadonly :value="user.responsibleUser" :label="i18n('Responsible User')" format="object" />
                </div>
                <div class="col-12 col-sm-6" v-if="user.userOrigin?.code === 'employee' && user.company">
                    <FieldReadonly :value="user.employee_number" :label="i18n('Employee Number')" />
                </div>
            </div>
            <div class="col-12 col-sm-10">
                <UserUserProfileSection :user="user" :hideActions="true" />
            </div>
        </div>
    </div>
</template>

<script>
import DashboardCounters from '@/components/dashboard/commons/DashboardCounters.vue';
import UserUserProfileSection from "@/components/user/UserUserProfileSection.vue";
import FieldReadonly from "@/components/common/form/FieldReadonly.vue";
import Statistics from "@/components/dashboard/Statistics.vue";

export default {
    components: {
        DashboardCounters,
        UserUserProfileSection,
        FieldReadonly,
        Statistics,
    },
    data() {
        return {
            user: {},
        };
    },
    mounted() {
        api.auth().then((user) => {
            this.$root.loggedUser = user;
            this.load();
        });
    },
    methods: {
        load() {
            this.loading = true;
            this.loadUser();
        },
        loadUser() {
            api.get(`users/${this.$root.loggedUser.id}`, { acl: 'user' }).then(user => {
                this.user = user;
                this.notFound = false;
            }).catch(() => {
                this.notFound = true;
            });

            api.get(`dashboard/${this.$root.loggedUser.id}`).then(statistic => {
                this.statistic = statistic;
                this.notFound = false;
            }).catch(() => {
                this.notFound = true;
            });
        },
    },
};
</script>