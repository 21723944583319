<template>
  <tr @dblclick="$refs['actions'].callFirstStarredAction();"
    class="d-block d-lg-table-row bg-white px-2 py-2 mb-2 border p-lg-0 mb-lg-0 border-lg-0">
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Name') }}</span>
      <FieldReadonly :value="profileUser.user" />
      <FieldReadonly :value="profileUser.user.origin_identifier" formatClass="text-muted" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Application') }}</span>
      <FieldReadonly :value="profileUser.profile.application" />
    </td>
    <td class="d-block d-lg-table-cell" v-if="$root.features?.profiles?.application_username">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Application Username') }}</span>
      <FieldReadonly :value="profileUser.username" />
    </td>
    <td class="d-block d-lg-table-cell" v-if="$root.features?.portfolios">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Portfolio') }}</span>
      <FieldReadonly :value="profileUser.profile.portfolio" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Profile') }}</span>
      <FieldReadonly :value="profileUser.profile" />
    </td>
    <td data-no-export class="d-block d-lg-table-cell text-end border-0 border-lg-bottom" @click.stop>
      <ActionDropdown ref="actions" :actions="actions" />
    </td>
  </tr>
</template>

<script>
import api from "@/api.js";
import FieldReadonly from "@/components/common/form/FieldReadonly.vue";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";

export default {
  components: {
    FieldReadonly,
    ActionDropdown,
  },
  props: {
    profileUser: Object,
  },
  data() {
    return {};
  },
  emits: [
    'edit',
    'editUsername',
    'deleted',
    'updating',
    'updated',
  ],
  methods: {
  },
  computed: {
    actions() {
      return [
        {
          label: this.i18n('See User Details'),
          fn: () => {
            this.$router.push({ name: 'UserDetail', params: { id: this.profileUser.user.id } });
          },
          if: (this.profileUser?.acl?.update) === true || (this.profileUser?.acl?.view) === true,
          starred: false,
        },
        {
          label: this.i18n('Update User Application Username'),
          fn: () => {
            this.$emit('editUsername', this.profileUser);
          },
          if: (this.profileUser?.acl?.update) === true && (!this.profileUser.profile.application?.is_self && this.profileUser.profile.application?.is_identity) && this.$root.features?.profiles?.application_username,
          starred: false,
        },
        {
          label: this.i18n('Remove'),
          fn: () => {
            this.$emit('updating', this.office);
            this.$root.requestConfirmation(this.i18n('Remove?'), () => {
              api.delete('users/' + this.profileUser.user.id + '/profiles/' + this.profileUser.profile.id).then(() => {
                this.$emit('deleted', this.office);
              }).then(() => {
                // Force reload
                this.$emit('updated', this.office);
              });
            });
          },
          if: (this.profileUser.acl?.delete) === true,
          starred: false,
        },
      ];
    },
  },
};
</script>