<template>
    <a href="javascript:void(0)" class="btn btn-sm btn-outline-dark" :class="cssClass"
        @click="$refs['UserLoginAsModal'].show()">
        <KeyIcon />
        <span class="d-none d-sm-inline-block ms-2">
            {{ i18n('Login As') }}
        </span>
    </a>
    <UserLoginAsModal :user="user" ref="UserLoginAsModal" />
</template>

<script>
import KeyIcon from "@/assets/svg/key.vue";
import UserLoginAsModal from "@/components/user/UserLoginAsModal.vue";

export default {
    components: {
        KeyIcon,
        UserLoginAsModal,
    },
    props: {
        cssClass: String,
        user: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>