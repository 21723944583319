<template>
    <Modal @shown="shown" @hidden="hidden" ref="modal" @submit="submit">
        <template #title>
            {{ i18n('Update User Application Username') }}
        </template>
        <div class="alert alert-danger mb-2" v-if="errorMessage">{{ errorMessage }}</div>

        <div class="row">
            <div class="col mb-2">
                <Field :disabled="submitted" type="text" :label="i18n('Application Username')"
                    v-model="formData.username" />
            </div>
        </div>

        <template #footer>
            <div class="row">
                <div class="col text-end">
                    <button type="submit" class="btn btn-dark btn-sm" :disabled="submitted">
                        {{ i18n('Save') }}
                    </button>
                </div>
            </div>
        </template>
    </Modal>
</template>
<script>
import api from "@/api.js";
import Modal from "@/components/common/layout/Modal.vue";
import Field from "@/components/common/form/Field.vue";
import { translatedErrorMessage, } from "@/tools/errorHandling.js";

export default {
    components: {
        Modal,
        Field,
    },
    data() {
        return {
            submitted: false,
            formData: {},
            errorMessages: {},
            errorMessage: '',
            userId: null,
            applicationId: null,
        };
    },
    emits: [
        'shown',
        'hidden',
        'updated',
    ],
    methods: {
        show(userId, applicationId) {
            if (!this.$root.features?.profiles?.application_username) {
                return;
            }

            this.userId = userId;
            this.applicationId = applicationId;
            this.submitted = false;
            this.formData = {};
            this.errorMessage = '';

            api.get(`users/${this.userId}/applications/${this.applicationId}/username`).then((applicationUsername) => {
                if (applicationUsername.active) {
                    this.formData.username = applicationUsername.username;
                    this.$refs['modal'].show();
                }
            });
        },
        hide() {
            this.$refs['modal'].hide();
        },
        shown() {
            this.$emit('shown');
        },
        hidden() {
            this.$emit('hidden');
        },
        submit() {
            let promise;
            this.errorMessage = '';
            this.submitted = true;

            promise = api.put(`users/${this.userId}/applications/${this.applicationId}/username`, {
                username: this.formData.username,
            });

            promise.then(async () => {
                this.$emit('updated');
                this.hide();
            }).catch((errorMessage) => {
                this.errorMessage = translatedErrorMessage(errorMessage);
                this.submitted = false;
            });
        },
    }
};
</script>