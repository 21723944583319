<template>
  <MinimalLayout :allowAnonymous="true" v-if="preloaded">
    <div class="container-fluid">
      <section class="vh-100">
        <div class="row vh-100">
          <div class="col-md-6 d-flex align-items-center justify-content-center vh-25 bg-cover bg-center"
            :style="`background-image: url(${loginImagePath});`"></div>
          <div class="col-md-6 d-flex align-items-center justify-content-center vh-25 bg-cover bg-center">
            <div class="d-flex align-items-center text-center">
              <form action="javascript:void(0)" @submit="submit" :class="{ submitting: submitted }">
                <img class="img-fluid mb-4 w-75" :src="defaultLogoUrl" :alt="defaultCompanyAlias" srcset="">
                <div class="alert alert-danger" v-if="errorMessage">{{ errorMessage }}</div>
                <div>
                  <p class="h3 mb-3">{{ i18n('Change Password') }}</p>
                </div>
                <template v-if="!completed">
                  <div class="row g-2 mb-2">
                    <div class="col-12">
                      <Field :label="i18n('Password')" type="password" placeholder="" v-model="formData.password"
                        :required="true" :disabled="submitted" :errorMessage="errorMessages.password" />
                    </div>
                    <div class="col-12">
                      <Field :label="i18n('Repeat Password')" type="password" placeholder="" :disabled="submitted"
                        :required="true" v-model="formData.repeat_password"
                        :errorMessage="errorMessages.repeat_password" />
                    </div>
                  </div>
                  <div class="d-grid mb-3 mt-4">
                    <button type="submit" class="btn btn-dark btn-dark-petrol" :disabled="submitted">
                      <span v-if="submitted" class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden"> {{ i18n('Sending ...') }}</span>
                      </span>
                      {{ i18n('Confirm') }}
                    </button>
                  </div>
                </template>
                <template v-else>
                  <p>
                    {{ i18n('Your password has been changed successfully.') }}
                  </p>
                  <p class="small">
                    {{ i18n('You can close this window now.') }}
                  </p>
                </template>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </MinimalLayout>
  <div class="p-5 text-center" v-else>
    <span class="spinner-border spinner-border-sm" role="status">
      <span class="visually-hidden">
        {{ i18n('Loading...') }}
      </span>
    </span>
  </div>
</template>

<script>
import $env from '@/tools/environment.js';
import api from "@/api.js";
import { duplicate, } from '@/tools/object.js';
import { translatedErrorMessage, } from "@/tools/errorHandling.js";
import MinimalLayout from "@/layouts/MinimalLayout.vue";
import Field from "@/components/common/form/Field.vue";

const defaultLogoUrl = $env('VITE_DEFAULT_LOGO_URL');
const defaultCompanyAlias = $env('VITE_DEFAULT_COMPANY_ALIAS');
const loginImagePath = $env('VITE_DEFAULT_LOGIN_IMAGE');

export default {
  components: {
    MinimalLayout,
    Field,
  },
  data() {
    return {
      preloaded: false,
      formData: {},
      errorMessage: '',
      errorMessages: {},
      submitted: false,
      loginImagePath,
      defaultLogoUrl,
      defaultCompanyAlias,
      urlParams: {},
      completed: false,
    };
  },
  created() {
    this.urlParams = Object.fromEntries(new URLSearchParams(window.location.search));

    if (!this.urlParams.token) {
      this.$router.push({ name: 'Index' });
      return;
    }

    api.auth().then((user) => {
      if (user.loggedIn) {
        this.$router.push({ name: 'Index' });
      } else {
        this.preloaded = true;
      }
    }).catch(() => {
      this.preloaded = true;
    });
  },
  methods: {
    submit() {
      if (this.formData.password !== this.formData.repeat_password) {
        this.errorMessages.repeat_password = this.i18n('Passwords do not match');
        return;
      }

      this.errorMessage = '';
      this.errorMessages = {};
      this.submitted = true;
      this.completed = false;

      const postFormData = Object.assign(duplicate(this.urlParams), duplicate(this.formData));

      api.post('users/changePassword', postFormData).then(() => {
        this.completed = true;
      }).catch((errorMessage) => {
        this.errorMessage = translatedErrorMessage(errorMessage, {
          'Not Found': 'The reset password link is invalid or has expired.',
        });
        this.submitted = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  max-width: 320px;
}
</style>