<template>
    <Modal @shown="shown" @hidden="hidden" ref="modal" @submit="submit">
        <template #title>
            {{ i18n('Send Access Instructions') }}
        </template>
        <Field :disabled="submitted" type="dictionary" :rest="`users/${user.id}/applications`" :customAutofocus="true"
            :required="true" :label="i18n('Application')" v-model="formData.application_id"
            :errorMessage="errorMessage" />

        <template #footer>
            <div class="row">
                <div class="col text-end">
                    <button type="submit" class="btn btn-dark btn-sm" :disabled="submitted">
                        {{ i18n('Send') }}
                    </button>
                </div>
            </div>
        </template>
    </Modal>
</template>
<script>
import api from "@/api.js";
import Modal from "@/components/common/layout/Modal.vue";
import Field from "@/components/common/form/Field.vue";

export default {
    components: {
        Modal,
        Field,
    },
    data() {
        return {
            submitted: false,
            formData: {},
            errorMessage: '',
        };
    },
    props: {
        user: {
            type: Object,
            default() {
                return {};
            },
        }
    },
    emits: [
        'shown',
        'hidden',
        'submit',
    ],
    methods: {
        show() {
            this.errorMessage = '';
            this.submitted = false;
            this.formData = {};
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        shown() {
            this.$emit('shown');
        },
        hidden() {
            this.$emit('hidden');
        },
        submit() {
            if (this.formData.application_id) {
                return;
            }

            this.errorMessage = '';
            this.submitted = true;

            api.post(`users/${this.user.id}/sendWelcomeMail/${this.formData.application_id}`).then(() => {
                this.submitted = false;
                this.hide();
            }).catch((error) => {
                this.errorMessage = error.response.data.message;
                this.submitted = false;
            });
        },
    }
};
</script>