<template>
  <button type="button" :class="btnClass" @click="showLocalUserModal">
    <FileEarmarkPlusIcon />
    {{ i18n('Add New') }}
  </button>

  <LocalUserModal ref="LocalUserModal" @created="$emit('created', $event)" />
</template>
<script>
import LocalUserModal from "@/components/localUser/LocalUserModal.vue";
import FileEarmarkPlusIcon from "@/assets/svg/file-earmark-plus.vue";

export default {
  components: {
    LocalUserModal,
    FileEarmarkPlusIcon,
  },
  props: {
    btnClass: {
      type: String,
      default: 'btn btn-outline-light',
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  emits: [
    'added',
    'created'
  ],
  methods: {
    showLocalUserModal() {
      this.$refs['LocalUserModal'].show();
    },
  }
};
</script>