<template>
    <Modal @shown="shown" @hidden="hidden" ref="modal" modalClass="modal-md" @submit="submit">
        <template #title>
            <template v-if="department && !readonly">
                {{ i18n('Edit {name}').replace('{name}', i18n('Department')) }}
            </template>
            <template v-else-if="department">
                {{ i18n('{name} Detail').replace('{name}', i18n('Department')) }}
            </template>
            <template v-else>
                {{ i18n('Add {name}').replace('{name}', i18n('Department')) }}
            </template>
        </template>

        <div class="alert alert-danger mb-2" v-if="errorMessage">{{ errorMessage }}</div>

        <div class="row g-2">
            <div class="col-12">
                <Field v-model="formData.name" :errorMessage="errorMessages.name" :label="i18n('Name')"
                    :disabled="submitted" :required="true" :type="readonly ? 'readonly' : 'text'" />
            </div>
        </div>

        <template #footer>
            <div class="row">
                <template v-if="!readonly">
                    <div class="col text-start">
                        <button type="button" class="btn btn-danger" @click="hide">
                            {{ i18n('Cancel') }}
                        </button>
                    </div>
                    <div class="col text-end">
                        <button type="submit" class="btn btn-primary text-white" :disabled="submitted">
                            {{ i18n('Confirm') }}
                        </button>
                    </div>
                </template>
                <div class="col text-end" v-else>
                    <button type="button" @click="hide" class="btn btn-outline-dark btn-sm me-2">
                        {{ i18n('Close') }}
                    </button>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import api from "@/api.js";
import { duplicate, } from "@/tools/object.js";
import Modal from "@/components/common/layout/Modal.vue";
import Field from "@/components/common/form/Field.vue";
import { translatedErrorMessage, } from "@/tools/errorHandling.js";

export default {
    components: {
        Modal,
        Field,
    },
    props: {
        company: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            departmentId: null,
            department: null,
            submitted: false,
            formData: {},
            errorMessages: {},
            errorMessage: '',
        };
    },
    emits: [
        'shown',
        'hidden',
        'created',
        'updated',
    ],
    computed: {
        readonly() {
            return this.department
                && this.department.acl?.update !== true;
        },
    },
    methods: {
        show(departmentId) {
            this.departmentId = departmentId;
            this.department = null;
            this.load().then(() => {
                this.submitted = false;
                this.$refs['modal'].show();
                this.errorMessages = {};
                this.errorMessage = '';

                if (this.department) {
                    this.formData = duplicate(this.department);
                } else {
                    this.formData = {};
                }
            });
        },
        load() {
            if (!this.departmentId) return Promise.resolve();
            let promise = api.get(`companies/${this.company.id}/departments/${this.departmentId}`, { acl: 'department' });

            promise.then(department => {
                this.department = department;
            });

            return promise;
        },
        hide() {
            this.$refs['modal'].hide();
        },
        shown() {
            this.$emit('shown');
        },
        hidden() {
            this.$emit('hidden');
        },
        submit() {
            this.submitted = true;
            this.errorMessages = {};
            this.errorMessage = '';

            const postFormData = duplicate(this.formData);
            let savePromise;

            if (!this.department) {
                savePromise = api.post(`companies/${this.company.id}/departments`, postFormData);
            } else {
                savePromise = api.put(`companies/${this.company.id}/departments/${this.department.id}`, postFormData);
            }

            savePromise.then(department => {
                if (!this.department) {
                    this.$emit('created', department);
                } else {
                    this.$emit('updated', department);
                }

                this.hide();
                api.clearCache();
            }).catch((errorMessage) => {
                this.errorMessage = translatedErrorMessage(errorMessage);
                this.submitted = false;
            });
        }
    }
}
</script>