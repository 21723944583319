<template>
    <div class="row align-items-center">
        <div class="col-6 col-md-12 align-self-center">
            <div class="row align-items-center">
                <div class="col-3 col-md-2 align-self-center">
                    <StatisticItem typeItem="userDirect" :data="statistic.users_in_charge" />
                </div>
                <div class="col-3 col-md-2 align-self-center">
                    <StatisticItem typeItem="userActiveAccess" :data="statistic.active_user_can_see" />
                </div>
                <div class="col-3 col-md-2 align-self-center">
                    <StatisticItem typeItem="pendingRecertifications" :data="statistic.pending_recertifications" />
                </div>
                <div class="col-3 col-md-2 align-self-center">
                    <StatisticItem typeItem="assignedApp" :data="statistic.apps_assigned" />
                </div>
                <div class="col-3 col-md-2 align-self-center">
                    <StatisticItem typeItem="assignedProfiles" :data="statistic.profiles_assigned" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import StatisticItem from "@/components/dashboard/StatisticItem.vue";

export default {
    components: {
        StatisticItem,
    },
    data() {
        return {

        };
    },
    props: {
        user: {
            type: Number,
        },
        statistic: {
            type: Object,
            required: true,
            default: () => ({
                users_in_charge: '0',
                active_user_can_see: '0',
                pending_recertifications: '0',
                apps_assigned: '0',
                profiles_assigned: '0',
            }),
        },
    },
    methods: {

    }
};
</script>