<template>
    <template v-if="linkable">
        <a href="javascript:void(0)" :class="cssClass" @click="click">
            {{ countValue.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}
        </a>
    </template>
    <template v-else-if="cssClass">
        <span :class="cssClass">
            {{ countValue.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}
        </span>
    </template>
    <template v-else>
        {{ countValue.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}
    </template>
</template>

<script>
export default {
    components: {},
    props: ['value', 'cssClass'],
    data() {
        return {};
    },
    created() { },
    watch: {},
    computed: {
        countValue() {
            if (this.value === null) return 0;
            if (this.value === undefined) return 0;

            if (Array.isArray(this.value)) return this.value.length;
            if (this.value.count !== undefined) return this.value.count;

            return this.value;
        },
        ids() {
            if (this.value === null) return [];
            if (this.value === undefined) return [];

            if (Array.isArray(this.value)) return this.value;
            if (this.value.ids) return this.value.ids;

            return [];
        },
        linkable() {
            return this.ids.length > 0;
        },
    },
    methods: {
        click() {
            document.cookie = `ids=${this.ids};`;

            this.$router.push({
                name: 'UserIndex',
                hash: `?ids=${this.ids}`,
            });
        },
    },
}
</script>
<style scoped>
a {
    text-decoration: none;
}
</style>