<template>
  <button type="button" :class="btnClass" @click="showApplicationModal">
    <FileEarmarkPlusIcon />
    {{ i18n('Add New') }}
  </button>

  <ApplicationModal ref="ApplicationModal" @created="$emit('created', $event)" />
</template>
<script>
import ApplicationModal from "@/components/application/ApplicationModal.vue";
import FileEarmarkPlusIcon from "@/assets/svg/file-earmark-plus.vue";

export default {
  components: {
    ApplicationModal,
    FileEarmarkPlusIcon,
  },
  props: {
    btnClass: {
      type: String,
      default: 'btn btn-outline-light',
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  emits: [
    'added',
    'created'
  ],
  methods: {
    showApplicationModal() {
      this.$refs['ApplicationModal'].show();
    },
  }
};
</script>