<template>
  <div class="bg-white p-2 border">
    <DepartmentFilter :filters="filters">
      <template #default>
        <h2 class="h5">
          {{ i18n('Departments') }}
          <span class="small text-muted">({{ departments.length }})</span>
        </h2>
      </template>
      <template #buttons>
        <DepartmentCreateAction @created="reload" v-if="can('COMPANY_MANAGE')"
          btnClass="ms-1 btn btn-sm btn-outline-dark" :company="company" />
      </template>
    </DepartmentFilter>

    <DepartmentList :departments="departments" :loading="loading" @deleted="reload" @updating="loading = true"
      @updated="reload" :company="company" />
  </div>
</template>

<script>
import api from "@/api.js";
import {
  duplicate,
  flattenUrlObject,
} from "@/tools/object.js";
import DepartmentCreateAction from "@/components/department/DepartmentCreateAction.vue";
import DepartmentFilter from "@/components/department/DepartmentFilter.vue";
import DepartmentList from "@/components/department/DepartmentList.vue";

export default {
  components: {
    DepartmentCreateAction,
    DepartmentFilter,
    DepartmentList,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      departments: [],
      filters: {},
      loadTimeout: null,
      loading: true,
    }
  },
  mounted() {
    api.auth().then((user) => {
      this.$root.loggedUser = user;

      if (!this.can('COMPANY_MANAGE')
        && !this.can('COMPANY_ACCESS')) {
        this.$router.push({
          name: 'Index',
        });
        return;
      }

      this.load();
    });
  },
  watch: {
    params: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  computed: {
    params() {
      let params = duplicate(this.filters);
      params.acl = 'department';

      flattenUrlObject(params);

      return params;
    },
  },
  methods: {
    load(force) {
      clearTimeout(this.loadTimeout);
      this.loading = true;

      if (!force) {
        this.loadTimeout = setTimeout(() => {
          this.load(true);
        }, 250);
        return;
      }

      api.get(`companies/${this.company.id}/departments`, this.params).then(departments => {
        this.departments = departments;
        this.loading = false;
      });
    },
    reload() {
      this.load();
    },
  },
};
</script>