<template>
    <Modal @shown="shown" @hidden="hidden" ref="modal" @submit="submit">
        <template #title>
            {{ i18n('Add Profile') }}
        </template>
        <div class="alert alert-danger mb-2" v-if="errorMessage">{{ errorMessage }}</div>

        <div class="row" v-if="!application">
            <div class="col mb-2">
                <Field :disabled="submitted" type="dictionary" rest="applications" :required="true"
                    :customAutofocus="true" :label="i18n('Application')" v-model="formData.application_id"
                    :errorMessage="errorMessages.application_id"
                    :sort="(a, b) => { return a.name.localeCompare(b.name); }" />
            </div>
        </div>

        <div class="row" v-if="!user">
            <div class="col mb-2">
                <Field :disabled="submitted" type="dictionary" rest="users" :required="true" :customAutofocus="true"
                    :label="i18n('User')" v-model="formData.user_id" :errorMessage="errorMessages.user_id"
                    :sort="(a, b) => { return a.name.localeCompare(b.name); }" />
            </div>
        </div>

        <div class="row"
            v-if="formData.application_id && formData.user_id && hasApplicationUsername && applicationUsernameLoaded">
            <div class="col mb-2">
                <Field :disabled="submitted" type="text" :label="i18n('Application Username')"
                    v-model="formData.username" />
            </div>
        </div>

        <div class="row" v-if="formData.application_id">
            <div class="col mb-2">
                <Field :disabled="submitted" type="dictionary"
                    :rest="`applications/${formData.application_id}/profiles`" :required="true" :customAutofocus="true"
                    :label="i18n('Profile')" v-model="formData.profile_id" :errorMessage="errorMessages.profile_id"
                    :sort="(a, b) => { return a.name.localeCompare(b.name); }" />
            </div>
        </div>

        <div class="row" v-if="formData.profile_id && $root.features?.profiles?.expiration">
            <div class="col mb-2">
                <Field :disabled="submitted" type="checkbox" :label="i18n('Has Expiration Date')"
                    v-model="is_expiring" />
            </div>
        </div>

        <div class="row" v-if="is_expiring">
            <div class="col mb-2">
                <Field :disabled="submitted" type="date" :required="true" :customAutofocus="true"
                    :label="i18n('Expiration Date')" v-model="formData.expires_at"
                    :errorMessage="errorMessages.expires_at" />
            </div>
        </div>

        <template #footer>
            <div class="row">
                <div class="col text-end">
                    <button type="submit" class="btn btn-dark btn-sm" :disabled="submitted">
                        {{ i18n('Save') }}
                    </button>
                </div>
            </div>
        </template>
    </Modal>
</template>
<script>
import api from "@/api.js";
import Modal from "@/components/common/layout/Modal.vue";
import Field from "@/components/common/form/Field.vue";
import { translatedErrorMessage, } from "@/tools/errorHandling.js";

export default {
    components: {
        Modal,
        Field,
    },
    data() {
        return {
            submitted: false,
            formData: {},
            errorMessages: {},
            errorMessage: '',
            is_expiring: false,
            applicationUsernameLoaded: false,
            hasApplicationUsername: false,
        };
    },
    props: {
        user: {
            type: Object,
        },
        application: {
            type: Object,
        },
    },
    emits: [
        'shown',
        'hidden',
        'submit',
        'created',
    ],
    mounted() {
        this.setFormData();
    },
    watch: {
        'formData.application_id': function () {
            this.loadApplicationUsername();
        },
        'formData.user_id': function () {
            this.loadApplicationUsername();
        },
    },
    methods: {
        loadApplicationUsername() {
            this.applicationUsernameLoaded = false;

            if (!this.formData.application_id || !this.formData.user_id
                || !this.$root.features?.profiles?.application_username
            ) {
                return;
            }

            api.get(`users/${this.formData.user_id}/applications/${this.formData.application_id}/username`).then((applicationUsername) => {
                this.formData.username = applicationUsername.username;
                this.hasApplicationUsername = applicationUsername.active;
                this.applicationUsernameLoaded = true;
            });
        },
        setFormData() {
            let formData = {};
            this.formData = formData;
        },
        show() {
            this.submitted = false;
            this.setFormData();
            this.formData.expires_at = null;
            this.is_expiring = false;
            this.errorMessage = '';
            this.applicationUsernameLoaded = false;
            this.hasApplicationUsername = false;

            if (this.application) {
                this.formData.application_id = this.application.id;
            } else {
                this.formData.application_id = null;
            }

            if (this.user) {
                this.formData.user_id = this.user.id;
            } else {
                this.formData.user_id = null;
            }

            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        shown() {
            this.$emit('shown');
        },
        hidden() {
            this.$emit('hidden');
        },
        submit() {
            let promise;
            this.errorMessage = '';
            this.submitted = true;

            if (!this.formData.profile_id) return;
            if (!this.applicationUsernameLoaded) return;

            promise = api.post('users/' + this.formData.user_id + '/profiles/' + this.formData.profile_id, {
                expires_at: this.is_expiring ? this.formData.expires_at : null,
                username: this.formData.username,
            });

            promise.then(async (profile) => {
                api.get('users/' + this.formData.user_id).then((user) => {
                    if (this.user) {
                        this.user.profiles = user.profiles;
                    }

                    this.$emit('submit', profile);
                    this.$emit('created', profile);
                    this.formData = {};
                    this.hide();
                    api.clearCache();
                });
            }).catch((errorMessage) => {
                this.errorMessage = translatedErrorMessage(errorMessage);
                this.submitted = false;
            });
        },
    }
};
</script>