<template>
  <tr @dblclick="$refs['actions'].callFirstStarredAction();"
    class="d-block d-lg-table-row bg-white px-2 py-2 mb-2 border p-lg-0 mb-lg-0 border-lg-0">
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Id') }}</span>
      <FieldReadonly :value="role.id" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Name') }}</span>
      <FieldReadonly :value="role.name" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('User Type') }}</span>
      <FieldReadonly :value="role.userType" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Users') }}</span>
      <FieldReadonly :value="role.user_count" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Profiles') }}</span>
      <FieldReadonly :value="role.profile_count" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Created At') }}</span>
      <FieldReadonly :value="role.created_at" format="date" />
    </td>
    <td data-no-export class="d-block d-lg-table-cell text-end border-0 border-lg-bottom" @click.stop>
      <ActionDropdown ref="actions" :actions="actions" />
    </td>
  </tr>
</template>

<script>
import FieldReadonly from "@/components/common/form/FieldReadonly.vue";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";

export default {
  components: {
    FieldReadonly,
    ActionDropdown,
  },
  props: {
    role: Object,
  },
  data() {
    return {};
  },
  emits: [
    'deleted',
    'updating',
    'updated',
  ],
  methods: {
  },
  computed: {
    actions() {
      return [{
        label: this.i18n('Detail'),
        fn: () => {
          this.$router.push({ name: 'RoleDetail', params: { id: this.role.id } });
        },
        if: (this.role.acl?.update) === true || (this.role.acl?.view) === true,
        starred: true,
      },
      ];
    },
  },
};
</script>