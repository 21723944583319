<template>
    <Modal @shown="shown" @hidden="hidden" ref="modal" modalClass="modal-md" @submit="submit">
        <template #title>
            <template v-if="redirectUri && !readonly">
                {{ i18n('Edit {name}').replace('{name}', i18n('Redirect URI')) }}
            </template>
            <template v-else-if="redirectUri">
                {{ i18n('{name} Detail').replace('{name}', i18n('Redirect URI')) }}
            </template>
            <template v-else>
                {{ i18n('Add {name}').replace('{name}', i18n('Redirect URI')) }}
            </template>
        </template>

        <div class="alert alert-danger mb-2" v-if="errorMessage">{{ errorMessage }}</div>

        <div class="row g-2">
            <div class="col-12">
                <Field v-model="formData.uri" :errorMessage="errorMessages.uri" :label="i18n('Uri')"
                    :disabled="submitted" :required="true" :type="readonly ? 'readonly' : 'text'" />
            </div>
        </div>

        <template #footer>
            <div class="row">
                <template v-if="!readonly">
                    <div class="col text-start">
                        <button type="button" class="btn btn-danger" @click="hide">
                            {{ i18n('Cancel') }}
                        </button>
                    </div>
                    <div class="col text-end">
                        <button type="submit" class="btn btn-primary text-white" :disabled="submitted">
                            {{ i18n('Confirm') }}
                        </button>
                    </div>
                </template>
                <div class="col text-end" v-else>
                    <button type="button" @click="hide" class="btn btn-outline-dark btn-sm me-2">
                        {{ i18n('Close') }}
                    </button>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import api from "@/api.js";
import { duplicate, } from "@/tools/object.js";
import Modal from "@/components/common/layout/Modal.vue";
import Field from "@/components/common/form/Field.vue";
import { translatedErrorMessage, } from "@/tools/errorHandling.js";

export default {
    components: {
        Modal,
        Field,
    },
    props: {
        application: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            redirectUriId: null,
            redirectUri: null,
            submitted: false,
            formData: {},
            errorMessages: {},
            errorMessage: '',
        };
    },
    emits: [
        'shown',
        'hidden',
        'created',
        'updated',
    ],
    computed: {
        readonly() {
            if (!this.redirectUri) return false;
            return this.redirectUri.acl?.update !== true;
        },
    },
    methods: {
        show(redirectUriId) {
            this.redirectUriId = redirectUriId;
            this.redirectUri = null;
            this.load().then(() => {
                this.submitted = false;
                this.$refs['modal'].show();
                this.errorMessages = {};
                this.errorMessage = '';

                if (this.redirectUri) {
                    this.formData = duplicate(this.redirectUri);
                } else {
                    this.formData = {};
                }
            });
        },
        load() {
            if (!this.redirectUriId) return Promise.resolve();
            let promise = api.get(`applications/${this.application.id}/redirectUris/${this.redirectUriId}`, { acl: 'redirectUri' });

            promise.then(redirectUri => {
                this.redirectUri = redirectUri;
            });

            return promise;
        },
        hide() {
            this.$refs['modal'].hide();
        },
        shown() {
            this.$emit('shown');
        },
        hidden() {
            this.$emit('hidden');
        },
        submit() {
            this.submitted = true;
            this.errorMessages = {};
            this.errorMessage = '';

            const postFormData = duplicate(this.formData);
            let savePromise;

            if (!this.redirectUri) {
                savePromise = api.post(`applications/${this.application.id}/redirectUris`, postFormData);
            } else {
                savePromise = api.put(`applications/${this.application.id}/redirectUris/${this.redirectUri.id}`, postFormData);
            }

            savePromise.then(redirectUri => {
                if (!this.redirectUri) {
                    this.$emit('created', redirectUri);
                } else {
                    this.$emit('updated', redirectUri);
                }

                this.hide();
            }).catch((errorMessage) => {
                this.errorMessage = translatedErrorMessage(errorMessage);
                this.submitted = false;
            });
        }
    }
}
</script>