<template>
  <ApiViewWrapper :notFound="notFound" :allowAnonymous="false">
    <DefaultLayout>
      <h1 class="h5 mt-2">
        <span class="small text-muted">{{ i18n('Company') }}</span>
        {{ company.alias }}
      </h1>

      <SectionManager :sectionType="company.is_from_group ? 'aside' : 'none'" stickyY="auto">
        <SectionItem>
          <template #title>
            {{ i18n('Main Information') }}
          </template>
          <div class="bg-white p-2 border">
            <div class="row g-2 align-items-center">
              <div class="col">
                <h2 class="h5">
                  {{ i18n('Main Information') }}
                </h2>
              </div>
              <div class="col text-end">
                <ActionDropdown ref="actions" :actions="actions" />
              </div>
            </div>
            <div class="row g-2">
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="company.code" :label="i18n('Code')" />
              </div>
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="company.alias" :label="i18n('Alias')" />
              </div>
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="company.name" :label="i18n('Name')" />
              </div>
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="company.document" :label="i18n('Nif')" />
              </div>
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="company.is_from_group" :label="i18n('Of Group')" />
              </div>
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="company.is_client" :label="i18n('Client')" />
              </div>
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="company.user_count" :label="i18n('Users')" />
              </div>
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="company.created_at" format="date" :label="i18n('Created At')" />
              </div>
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="company.logo.png_url" :label="i18n('Logo Png Url')" format="url" />
              </div>
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="company.logo.light_png_url" :label="i18n('Logo Light Png Url')" format="url" />
              </div>
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="company.logo.svg_url" :label="i18n('Logo Svg Url')" format="url" />
              </div>
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="company.logo.icon_url" :label="i18n('Logo Icon Url')" format="url" />
              </div>
            </div>
          </div>
        </SectionItem>
        <SectionItem v-if="(can('COMPANY_ACCESS') || can('COMPANY_MANAGE')) && company.is_from_group">
          <template #title>
            {{ i18n('Departments') }}
          </template>
          <DepartmentSection :company="company" />
        </SectionItem>
        <SectionItem v-if="(can('COMPANY_ACCESS') || can('COMPANY_MANAGE')) && company.is_from_group">
          <template #title>
            {{ i18n('Offices') }}
          </template>
          <OfficeSection :company="company" />
        </SectionItem>
      </SectionManager>
      <CompanyModal ref="CompanyModal" @updated="reload" />
    </DefaultLayout>
  </ApiViewWrapper>
</template>

<script>
import api from "@/api.js";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ApiViewWrapper from "@/components/common/view/ApiViewWrapper.vue";
import SectionManager from "@/components/common/layout/section/SectionManager.vue";
import SectionItem from "@/components/common/layout/section/SectionItem.vue";
import FieldReadonly from "@/components/common/form/FieldReadonly.vue";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";
import DepartmentSection from "@/components/department/DepartmentSection.vue";
import OfficeSection from "@/components/office/OfficeSection.vue";
import CompanyModal from "@/components/company/CompanyModal.vue";

export default {
  components: {
    ApiViewWrapper,
    DefaultLayout,
    SectionManager,
    SectionItem,
    FieldReadonly,
    ActionDropdown,
    DepartmentSection,
    OfficeSection,
    CompanyModal,
  },
  data() {
    return {
      company: null,
      notFound: null,
    };
  },
  mounted() {
    api.auth().then((user) => {
      this.$root.loggedUser = user;

      this.load();
    });
  },
  watch: {
    "$route.params.id"() {
      this.load();
    },
  },
  computed: {
    actions() {
      return [{
        label: this.i18n('Edit'),
        fn: () => {
          this.showCompanyModal();
        },
        if: (this.company.acl?.update) === true,
        starred: true,
      },
      {
        label: this.i18n('Delete'),
        fn: () => {
          this.$root.requestConfirmation(this.i18n('Delete? This action cannot be undone.'), () => {
            this.$emit('updating', this.company);
            api.delete(`companies/${this.company.id}`).then(() => {
              this.$router.push({ name: 'CompanyIndex', });
              api.clearCache();
            }).then(() => {
              this.reload();
            });
          });
        },
        if: (this.company.acl?.delete) === true,
        starred: false,
      },
      ];
    },
  },
  methods: {
    load(force) {
      clearTimeout(this.loadTimeout);
      this.notFound = null;

      if (!force) {
        this.loadTimeout = setTimeout(() => {
          this.load(true);
        }, 250);
        return;
      }

      if (!this.$route.params.id) {
        this.notFound = true;
        return;
      }

      api.get(`companies/${this.$route.params.id}`, { acl: 'company' }).then(company => {
        if (company.acl?.view !== true) {
          this.$router.push({ name: 'CompanyIndex', });
          return;
        }

        this.company = company;
        this.notFound = false;
      }).catch(() => {
        this.notFound = true;
      });
    },
    reload() {
      this.load();
    },
    showCompanyModal() {
      if (!this.$refs.CompanyModal) return;
      this.$refs.CompanyModal.show(this.company.id);
    },
  },
};
</script>