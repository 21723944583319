import ApplicationDetailView from "@/views/ApplicationDetailView.vue";
import ApplicationIndexView from "@/views/ApplicationIndexView.vue";
import AuditIndexView from "@/views/AuditIndexView.vue";
import CompanyDetailView from "@/views/CompanyDetailView.vue";
import CompanyIndexView from "@/views/CompanyIndexView.vue";
import DashboardIndexView from "./views/DashboardIndexView.vue";
import IndexView from "@/views/IndexView.vue";
import LocalUserIndexView from "@/views/LocalUserIndexView.vue";
import LoginErrorView from "@/views/LoginErrorView.vue";
import LoginForgotPasswordView from "@/views/LoginForgotPasswordView.vue";
import LoginView from "@/views/LoginView.vue";
import LoginResetPasswordView from "@/views/LoginResetPasswordView.vue";
import LoginWithAdUsernameAndPasswordView from "@/views/LoginWithAdUsernameAndPasswordView.vue";
import LogoutView from "@/views/LogoutView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import OfficeIndexView from "@/views/OfficeIndexView.vue";
import ProfileUserIndexView from "@/views/ProfileUserIndexView.vue";
import ProviderIndexView from "@/views/ProviderIndexView.vue";
import RoleDetailView from "@/views/RoleDetailView.vue";
import RoleIndexView from "@/views/RoleIndexView.vue";
import TeamIndexView from "@/views/TeamIndexView.vue";
import TriggerIndexView from "@/views/TriggerIndexView.vue";
import UserDetailView from "@/views/UserDetailView.vue";
import UserIndexView from "@/views/UserIndexView.vue";

import $env from '@/tools/environment.js';

const isIntrum = $env('VITE_CUSTOM_STYLE_SHEET') === '/assets/intrum/styles.css';

const featureviews = {
    AuditIndex: isIntrum,
    DashboardIndex: isIntrum,
    LoginWithAdUsernameAndPassword: isIntrum,
    OfficeIndex: isIntrum,
    RoleDetail: isIntrum,
    RoleIndex: isIntrum,
    TeamIndex: isIntrum,
    TriggerIndex: isIntrum,
};

const allRoutes = [
    { path: "/:catchAll(.*)", component: NotFoundView, name: 'NotFound', },
    { path: "/index", component: IndexView, name: 'Index', },
    { path: "/login-error", component: LoginErrorView, name: 'LoginError', },
    { path: "/logout", component: LogoutView, name: 'Logout', },
    { path: '/', component: IndexView, name: 'Index', },
    { path: '/application/:id', component: ApplicationDetailView, name: 'ApplicationDetail', },
    { path: '/applications', component: ApplicationIndexView, name: 'ApplicationIndex', },
    { path: '/audit', component: AuditIndexView, name: 'AuditIndex' },
    { path: '/companies', component: CompanyIndexView, name: 'CompanyIndex', },
    { path: '/company/:id', component: CompanyDetailView, name: 'CompanyDetail', },
    { path: '/dashboard', component: DashboardIndexView, name: 'DashboardIndex', },
    { path: '/fromlogin', component: LoginView, name: 'FromLogin', },
    { path: '/localUsers', component: LocalUserIndexView, name: 'LocalUserIndex', },
    { path: '/login', component: LoginView, name: 'Login', },
    { path: '/login-ad', component: LoginWithAdUsernameAndPasswordView, name: 'LoginWithAdUsernameAndPassword', },
    { path: '/login/forgot', component: LoginForgotPasswordView, name: 'LoginForgotPassword', },
    { path: '/login/reset', component: LoginResetPasswordView, name: 'LoginResetPassword', },
    { path: '/offices', component: OfficeIndexView, name: 'OfficeIndex' },
    { path: '/profileUsers', component: ProfileUserIndexView, name: 'ProfileUserIndex', },
    { path: '/provider/:id', name: 'ProviderDetail', },
    { path: '/providers', component: ProviderIndexView, name: 'ProviderIndex', },
    { path: '/role/:id', component: RoleDetailView, name: 'RoleDetail', },
    { path: '/roles', component: RoleIndexView, name: 'RoleIndex' },
    { path: '/teams', component: TeamIndexView, name: 'TeamIndex' },
    { path: '/triggers', component: TriggerIndexView, name: 'TriggerIndex' },
    { path: '/user/:id', component: UserDetailView, name: 'UserDetail', },
    { path: '/users', component: UserIndexView, name: 'UserIndex', },
];

const routes = allRoutes.filter(route => {
    return featureviews[route.name] !== false;
});

export default routes;
