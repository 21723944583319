<template>
  <div class="bg-white border p-2 mt-3">
    <div class="table-responsive position-relative"
      :class="{ 'with-actions-gap': offices.length < 4, 'opacity-50': loading }">
      <table class="table">
        <caption class="sr-only" data-no-export>{{ i18n('Office list') }}</caption>
        <thead>
          <tr class="d-none d-lg-table-row">
            <th scope="col">{{ i18n('Id') }}</th>
            <th v-if="!company" scope="col">{{ i18n('Company') }}</th>
            <th scope="col">{{ i18n('Name') }}</th>
            <th scope="col">{{ i18n('Address') }}</th>
            <th data-no-export scope="col" style="min-width: 48px">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <OfficeListItem v-for="office in offices" :office="office" :key="office.id" @edit="showOfficeModal"
            @deleted="$emit('deleted', $event)" @updated="$emit('updated', $event)"
            @updating="$emit('updating', $event)" :company="company" />
          <EmptyListItem v-if="!offices || offices.length == 0" :loading="loading" :colspan="4 + (!company ? 1 : 0)" />
          <LazyLoadListItem v-if="!loading && (lazyLoad || autoLazyLoad)" :autoLazyLoad="autoLazyLoad"
            :colspan="4 + (!company ? 1 : 0)" @next="$emit('next', $event)" />
        </tbody>
      </table>
      <div class="position-absolute h-100 w-100 top-0" @click.stop v-if="loading"></div>
    </div>
  </div>
  <OfficeModal ref="OfficeModal" @updated="$emit('updated', $event)" :company="company" />
</template>

<script>
import OfficeListItem from "@/components/office/OfficeListItem.vue";
import OfficeModal from "@/components/office/OfficeModal.vue";
import EmptyListItem from "@/components/common/list/EmptyListItem.vue";
import LazyLoadListItem from "@/components/common/list/LazyLoadListItem.vue";

export default {
  components: {
    OfficeListItem,
    OfficeModal,
    EmptyListItem,
    LazyLoadListItem,
  },
  emits: [
    'updated',
    'deleted',
    'updated',
    'updating',
    'next',
  ],
  props: {
    company: {
      type: Object,
    },
    loading: Boolean,
    lazyLoad: Boolean,
    autoLazyLoad: Boolean,
    offices: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    showOfficeModal(office) {
      if (!this.$refs.OfficeModal) return;
      this.$refs.OfficeModal.show(office.id);
    },
  },
};
</script>