<template>
    <div class="mt-4 mb-3">

        <div class="mb-3">
            <h2 class="h5">{{ title }}</h2>
        </div>

        <div class="" v-if="!stats">
            <div class="text-center">
                <span class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">
                        {{ i18n('Loading...') }}
                    </span>
                </span>
            </div>
        </div>
        <div class="row g-2 text-center" v-else :style="{ opacity: (reloading ? 0.5 : 1) }">
            <div :class="columns[index % columns.length]" v-for="(title, index) in titles" :key="index">
                <div class="card">
                    <div class="card-body">
                        <div class="display-5">
                            <template v-if="linkable">
                                <DashboardCountLink :value="stats[keys[index]]" />
                            </template>

                            <template v-else>
                                <span>{{ stats[keys[index]].count }}</span>
                            </template>
                        </div>
                    </div>
                    <div class="card-footer">
                        <p class="mb-0 small dark">{{ title }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import DashboardCountLink from "@/components/dashboard/commons/DashboardCountLink.vue";

import api from "@/api.js";
export default {
    components: {
        DashboardCountLink,
    },
    props: {
        title: String,
        filters: Object,
        linkable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            reloading: false,
            stats: null,
            users: {},
        };
    },
    created() {
        this.load();
    },
    watch: {
        filters: {
            handler() {
                this.load();
            },
            deep: true,
        },
    },
    computed: {
        keys() {
            return Object.keys(this.stats);
        },
        columns() {
            return ['col-sm-4 col-lg'];
        },
        titles() {
            return [
                this.i18n('Direct users in your care'),
                this.i18n('Active users you have access to'),
                this.i18n('Assigned applications'),
                this.i18n('Assigned profiles'),
            ]
        },
    },
    methods: {
        load() {
            this.reloading = true;

            api.get(`dashboard/counters`).then(stats => {
                this.stats = stats;
                this.reloading = false;
            });
        },
    },
}
</script>