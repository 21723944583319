<template>
  <div class="bg-white border p-2 mt-3">
    <div class="table-responsive position-relative"
      :class="{ 'with-actions-gap': providers.length < 4, 'opacity-50': loading }">
      <table class="table">
        <caption class="sr-only" data-no-export>{{ i18n('Provider list') }}</caption>
        <thead>
          <tr class="d-none d-lg-table-row">
            <th scope="col">{{ i18n('Id') }}</th>
            <th scope="col">{{ i18n('Identifier') }}</th>
            <th scope="col">{{ i18n('Name') }}</th>
            <th data-no-export scope="col" style="min-width: 48px">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <ProviderListItem v-for="provider in providers" :provider="provider" :key="provider.id"
            @edit="showProviderModal" @deleted="$emit('deleted', $event)" @updated="$emit('updated', $event)"
            @updating="$emit('updating', $event)" />
          <EmptyListItem v-if="!providers || providers.length == 0" :loading="loading" :colspan="4" />
          <LazyLoadListItem v-if="!loading && (lazyLoad || autoLazyLoad)" :autoLazyLoad="autoLazyLoad" :colspan="4"
            @next="$emit('next', $event)" />
        </tbody>
      </table>
      <div class="position-absolute h-100 w-100 top-0" @click.stop v-if="loading"></div>
    </div>
  </div>
  <ProviderModal ref="ProviderModal" @updated="$emit('updated', $event)" />
</template>

<script>
import ProviderListItem from "@/components/provider/ProviderListItem.vue";
import ProviderModal from "@/components/provider/ProviderModal.vue";
import EmptyListItem from "@/components/common/list/EmptyListItem.vue";
import LazyLoadListItem from "@/components/common/list/LazyLoadListItem.vue";
import Tooltip from "@/components/common/layout/Tooltip.vue";

export default {
  components: {
    ProviderListItem,
    ProviderModal,
    EmptyListItem,
    LazyLoadListItem,
    Tooltip,
  },
  emits: [
    'updated',
    'deleted',
    'updated',
    'updating',
    'next',
  ],
  props: {
    loading: Boolean,
    lazyLoad: Boolean,
    autoLazyLoad: Boolean,
    providers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    showProviderModal(provider) {
      if (!this.$refs.ProviderModal) return;
      this.$refs.ProviderModal.show(provider.id);
    },
  },
};
</script>