<template>
    <Modal @shown="shown" @hidden="hidden" ref="modal" @submit="submit" modalClass="modal-xl">
        <template #title>
            {{ i18n('Array Update') }}
        </template>
        <div class="alert alert-danger mb-2" v-if="errorMessage">{{ errorMessage }}</div>

        <table class="table" v-if="profiles.length > 0 && permissions.length > 0">
            <caption class="sr-only" data-no-export>{{ i18n('Profile List') }}</caption>
            <tbody>
                <tr>
                    <th>{{ i18n('Permission') }} / {{ i18n('Profile') }}</th>
                    <th v-for="profile in profiles" :key="profile.id">
                        {{ profile.name }}
                    </th>
                </tr>
                <tr v-for="permission in permissions" :key="permission.id">
                    <td>{{ permission.name }}</td>
                    <td v-for="profile in profiles" :key="profile.id">
                        <input type="checkbox" :value="profile.id"
                            v-model="permissionsByProfile[profile.id][permission.id]" />
                    </td>
                </tr>
            </tbody>
        </table>

        <template #footer>
            <div class="row">
                <div class="col text-end">
                    <button type="submit" class="btn btn-dark btn-sm" :disabled="submitted">
                        {{ i18n('Save') }}
                    </button>
                </div>
            </div>
        </template>
    </Modal>
</template>
<script>
import api from "@/api.js";
import Modal from "@/components/common/layout/Modal.vue";
import Field from "@/components/common/form/Field.vue";

export default {
    components: {
        Modal,
        Field,
    },
    data() {
        return {
            submitted: false,
            permissionsByProfile: {},
            errorMessages: {},
            errorMessage: '',
            profiles: [],
            permissions: [],
        };
    },
    props: {
        application: {
            type: Object,
        },
    },
    emits: [
        'shown',
        'hidden',
        'updated',
        'created',
    ],
    methods: {
        show() {
            this.submitted = false;
            this.permissionsByProfile = {};
            this.errorMessages = {};
            this.errorMessage = '';
            this.profiles = [];
            this.permissions = [];

            this.load().then(() => {
                this.submitted = false;
                this.$refs['modal'].show();
                this.errorMessages = {};
                this.errorMessage = '';
            });
        },
        load() {
            return Promise.all([
                api.get('applications/' + this.application.id + '/profiles', { detailed: 1, acl: 1 }),
                api.get('applications/' + this.application.id + '/permissions'),
            ]).then(([profiles, permissions]) => {
                this.profiles = profiles;
                this.permissions = permissions;

                this.profiles.forEach(profile => {
                    this.permissionsByProfile[profile.id] = {};

                    (profile.permissions || []).forEach(p => {
                        this.permissionsByProfile[profile.id][p.id] = true;
                    });
                });
            });
        },
        hide() {
            this.$refs['modal'].hide();
        },
        shown() {
            this.$emit('shown');
        },
        hidden() {
            this.$emit('hidden');
        },
        submit() {
            this.submitted = true;
            this.errorMessage = '';

            let promises = [];
            this.profiles.forEach(profile => {
                this.permissions.forEach(permission => {
                    if (this.permissionsByProfile[profile.id][permission.id]) {
                        if ((profile.permissions || []).map(p => p.id).indexOf(permission.id) === -1) {
                            promises.push(
                                api.post('applications/' + this.application.id + '/profiles/' + profile.id + '/permissions/' + permission.id)
                            );
                        }
                    } else if ((profile.permissions || []).map(p => p.id).indexOf(permission.id) !== -1) {
                        promises.push(
                            api.delete('applications/' + this.application.id + '/profiles/' + profile.id + '/permissions/' + permission.id)
                        );
                    }
                });
            });

            Promise.all(promises).then(() => {
                this.$emit('updated');
                api.clearCache();
                this.hide();
            }).catch((err) => {
                this.submitted = false;
                this.errorMessage = this.i18n('Unable to save.');
            });
        },
    }
};
</script>