<template>
  <div class="bg-white border p-2 mt-3">
    <div class="table-responsive position-relative"
      :class="{ 'with-actions-gap': redirectUris.length < 4, 'opacity-50': loading }">
      <table class="table">
        <caption class="sr-only" data-no-export>{{ i18n('Redirect list') }}</caption>
        <thead>
          <tr class="d-none d-lg-table-row">
            <th scope="col">{{ i18n('Id') }}</th>
            <th scope="col">{{ i18n('Uri') }}</th>
            <th data-no-export scope="col" style="min-width: 48px">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <RedirectUriListItem v-for="redirectUri in redirectUris" :redirectUri="redirectUri" :key="redirectUri.id"
            @edit="showRedirectUriModal" @deleted="$emit('deleted', $event)" @updated="$emit('updated', $event)"
            @updating="$emit('updating', $event)" :application="application" />
          <EmptyListItem v-if="!redirectUris || redirectUris.length == 0" :loading="loading" :colspan="3" />
          <LazyLoadListItem v-if="!loading && (lazyLoad || autoLazyLoad)" :autoLazyLoad="autoLazyLoad" :colspan="3"
            @next="$emit('next', $event)" />
        </tbody>
      </table>
      <div class="position-absolute h-100 w-100 top-0" @click.stop v-if="loading"></div>
    </div>
  </div>
  <RedirectUriModal ref="RedirectUriModal" @updated="$emit('updated', $event)" :application="application" />
</template>

<script>
import RedirectUriListItem from "@/components/redirectUri/RedirectUriListItem.vue";
import RedirectUriModal from "@/components/redirectUri/RedirectUriModal.vue";
import EmptyListItem from "@/components/common/list/EmptyListItem.vue";
import LazyLoadListItem from "@/components/common/list/LazyLoadListItem.vue";
import Tooltip from "@/components/common/layout/Tooltip.vue";

export default {
  components: {
    RedirectUriListItem,
    RedirectUriModal,
    EmptyListItem,
    LazyLoadListItem,
    Tooltip,
  },
  emits: [
    'updated',
    'deleted',
    'updated',
    'updating',
    'next',
  ],
  props: {
    application: {
      type: Object,
      required: true,
    },
    loading: Boolean,
    lazyLoad: Boolean,
    autoLazyLoad: Boolean,
    redirectUris: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    showRedirectUriModal(redirectUri) {
      if (!this.$refs.RedirectUriModal) return;
      this.$refs.RedirectUriModal.show(redirectUri.id);
    },
  },
};
</script>