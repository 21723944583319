<template>
    <Modal @shown="shown" @hidden="hidden" ref="modal" modalClass="modal-lg" @submit="submit">
        <template #title>
            <template v-if="application && !readonly">
                {{ i18n('Edit {name}').replace('{name}', i18n('Application')) }}
            </template>
            <template v-else-if="application">
                {{ i18n('{name} Detail').replace('{name}', i18n('Application')) }}
            </template>
            <template v-else>
                {{ i18n('Add {name}').replace('{name}', i18n('Application')) }}
            </template>
        </template>

        <div class="alert alert-danger mb-2" v-if="errorMessage">{{ errorMessage }}</div>

        <h2 class="h5">{{ i18n('Main Info') }}</h2>

        <div class="row g-2">
            <div class="col-12" :class="{ 'col-sm-6': $root?.features?.applications?.alias?.enabled }">
                <Field v-model="formData.name" :errorMessage="errorMessages.name" :label="i18n('Name')"
                    :disabled="submitted" :required="true" :type="readonly ? 'readonly' : 'text'" />
            </div>
            <div class="col-12 col-sm-6" v-if="$root?.features?.applications?.alias?.enabled">
                <Field v-model="formData.alias" :errorMessage="errorMessages.alias" :label="i18n('Alias')"
                    :disabled="submitted" :required="true" :type="readonly ? 'readonly' : 'text'" />
            </div>
            <div class="col-12">
                <Field v-model="formData.main_url" :errorMessage="errorMessages.main_url" :label="i18n('Main Url')"
                    :disabled="submitted" :required="true" format="url" :type="readonly ? 'readonly' : 'text'" />
            </div>
            <div class="col-12">
                <Field v-model="formData.description" :errorMessage="errorMessages.description"
                    :label="i18n('Description')" :disabled="submitted" :required="true"
                    :type="readonly ? 'readonly' : 'textarea'" />
            </div>
            <template v-if="$root?.features?.applications?.categories?.enabled">
                <div class="col-12 col-sm-6">
                    <Field v-model="formData.category" :errorMessage="errorMessages.category" :label="i18n('Category')"
                        :disabled="submitted" :type="readonly ? 'readonly' : 'text'" />
                </div>
                <div class="col-12 col-sm-6">
                    <Field v-model="formData.subcategory" :errorMessage="errorMessages.subcategory"
                        :label="i18n('Subcategory')" :disabled="submitted" :type="readonly ? 'readonly' : 'text'" />
                </div>
            </template>
            <div class="col-12" v-if="!$root?.features?.loginPortal?.enabled">
                <Field v-model="formData.svg" :errorMessage="errorMessages.svg" :label="i18n('Svg')"
                    :disabled="submitted" :type="readonly ? 'readonly' : 'textarea'" />
            </div>
        </div>
        <template v-if="$root?.features?.loginPortal?.enabled">
            <h2 class="h5 mt-3">{{ i18n('Portal') }}</h2>
            <div class="row g-2">
                <div class="col-12 col-sm-6">
                    <Field v-model="formData.menu_group" :errorMessage="errorMessages.menu_group"
                        :label="i18n('Menu Group')" :disabled="submitted" :required="true"
                        :type="readonly ? 'readonly' : 'text'" />
                </div>
                <div class="col-12 col-sm-6">
                    <Field v-model="formData.menu_subgroup" :errorMessage="errorMessages.menu_subgroup"
                        :label="i18n('Menu Subgroup')" :disabled="submitted" :required="true"
                        :type="readonly ? 'readonly' : 'text'" />
                </div>
                <div class="col-12">
                    <Field v-model="formData.image_url" :errorMessage="errorMessages.image_url" :label="i18n('Image')"
                        :disabled="submitted" :type="readonly ? 'readonly' : 'imageUrl'" />
                </div>
                <div class="col-12">
                    <Field v-model="formData.is_starred" :errorMessage="errorMessages.is_starred"
                        :label="i18n('Display In Home Page')" :disabled="submitted"
                        :type="readonly ? 'readonly' : 'checkbox'" />
                </div>
            </div>
        </template>
        <template v-if="!application?.is_self">
            <h2 class="h5 mt-3">{{ i18n('Profiling') }}</h2>
            <div class="row g-2">
                <div class="col-12 col-sm-6">
                    <Field v-model="formData.authorizationType" :errorMessage="errorMessages.authorizationType"
                        :label="i18n('Authorization Type')" :disabled="submitted" :required="true"
                        :type="readonly ? 'readonly' : 'dictionary'" format="object" rest="types/authorizations" />
                </div>
                <div v-if="formData.authorizationType && formData.authorizationType?.code == 'profiles'"
                    class="col-12 col-sm-6">
                    <Field v-model="formData.is_single_profile" :errorMessage="errorMessages.is_single_profile"
                        :label="i18n('Profiling')" :disabled="submitted" :type="readonly ? 'readonly' : 'dictionary'"
                        format="boolean" :hash="{ false: i18n('Multiple profiles'), true: i18n('Unique profile') }" />
                </div>
                <div class="col-6">
                    <Field v-model="formData.is_integrated" :errorMessage="errorMessages.is_identity"
                        :label="i18n('Integrated With %s', appName)" format="boolean" :disabled="submitted"
                        :type="readonly ? 'readonly' : 'dictionary'" :hash="{ false: i18n('No'), true: i18n('Yes') }" />
                </div>
                <template v-if="formData.is_integrated === false">
                    <div class="col-6">
                        <Field v-model="formData.is_identity" :errorMessage="errorMessages.is_identity"
                            :label="i18n('Identity')" format="boolean" :disabled="submitted"
                            :type="readonly ? 'readonly' : 'dictionary'"
                            :hash="{ false: i18n('Delegated identity application'), true: i18n('Self-identity application') }" />
                    </div>
                    <div v-if="formData.is_identity === false" class="col-6">
                        <Field v-model="formData.identityApplication" :errorMessage="errorMessages.identityApplication"
                            :label="i18n('Application that manages identity')" :disabled="submitted"
                            :type="readonly ? 'readonly' : 'dictionary'" format="object" :required="true"
                            rest="applications?is_identity=1" />
                    </div>
                </template>
                <template v-if="!formData.is_self">
                    <div class="col-12">
                        <Field v-model="formData.is_keep_for_internal_disabled_users"
                            :label="i18n('Keep on internal users disabled')" :disabled="submitted"
                            :type="readonly ? 'readonly' : 'checkbox'" />
                    </div>
                    <div class="col-12">
                        <Field v-model="formData.is_exclude_from_duplication"
                            :label="i18n('Exclude when duplicating profiles')" :disabled="submitted"
                            :type="readonly ? 'readonly' : 'checkbox'" />
                    </div>
                    <div class="col-12">
                        <Field v-model="formData.has_otp_seed_access" :label="i18n('Can update/verify OTP seed')"
                            :disabled="submitted" :type="readonly ? 'readonly' : 'checkbox'" />
                    </div>
                </template>
            </div>
        </template>
        <template #footer>
            <div class="row">
                <template v-if="!readonly">
                    <div class="col text-start">
                        <button type="button" class="btn btn-danger" @click="hide">
                            {{ i18n('Cancel') }}
                        </button>
                    </div>
                    <div class="col text-end">
                        <button type="submit" class="btn btn-primary text-white" :disabled="submitted">
                            {{ i18n('Confirm') }}
                        </button>
                    </div>
                </template>
                <div class="col text-end" v-else>
                    <button type="button" @click="hide" class="btn btn-outline-dark btn-sm me-2">
                        {{ i18n('Close') }}
                    </button>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import $env from "@/tools/environment.js";
const appName = $env('VITE_APP_NAME');
import api from "@/api.js";
import { duplicate, } from "@/tools/object.js";
import Modal from "@/components/common/layout/Modal.vue";
import Field from "@/components/common/form/Field.vue";
import FieldReadonly from "@/components/common/form/FieldReadonly.vue";
import { translatedErrorMessage, } from "@/tools/errorHandling.js";

export default {
    components: {
        Modal,
        Field,
        FieldReadonly,
    },
    data() {
        return {
            appName,
            applicationId: null,
            application: null,
            submitted: false,
            formData: {},
            errorMessages: {},
            errorMessage: '',
        };
    },
    emits: [
        'shown',
        'hidden',
        'created',
        'updated',
    ],
    computed: {
        readonly() {
            return this.application
                && this.application.acl?.update !== true;
        },
    },
    methods: {
        show(applicationId) {
            this.applicationId = applicationId;
            this.application = null;
            this.load().then(() => {
                this.submitted = false;
                this.$refs['modal'].show();
                this.errorMessages = {};
                this.errorMessage = '';

                if (this.application) {
                    this.formData = duplicate(this.application);
                } else {
                    this.formData = {
                        is_integrated: true,
                    };
                }
            });
        },
        load() {
            if (!this.applicationId) return Promise.resolve();
            let promise = api.get(`applications/${this.applicationId}`, { acl: 'application' });

            promise.then(application => {
                this.application = application;
            });

            return promise;
        },
        hide() {
            this.$refs['modal'].hide();
        },
        shown() {
            this.$emit('shown');
        },
        hidden() {
            this.$emit('hidden');
        },
        submit() {
            this.submitted = true;
            this.errorMessages = {};
            this.errorMessage = '';

            const postFormData = duplicate(this.formData);
            let savePromise;

            if (!this.application) {
                savePromise = api.post(`applications`, postFormData);
            } else {
                savePromise = api.put(`applications/${this.application.id}`, postFormData);
            }

            savePromise.then(application => {
                if (!this.application) {
                    this.$emit('created', application);
                } else {
                    this.$emit('updated', application);
                }

                this.hide();
                api.clearCache();
            }).catch((errorMessage) => {
                this.errorMessage = translatedErrorMessage(errorMessage);
                this.submitted = false;
            });
        }
    }
}
</script>