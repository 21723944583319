<template>
  <tr @dblclick="$refs['actions'].callFirstStarredAction();"
    class="d-block d-lg-table-row bg-white px-2 py-2 mb-2 border p-lg-0 mb-lg-0 border-lg-0">
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Id') }}</span>
      <FieldReadonly :value="user.id" />
    </td>
    <td class="d-block d-lg-table-cell" style="max-width: 10rem;">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Username') }}</span>
      <template v-if="user.userOrigin.code !== 'local'">
        <Tooltip :title="user.origin_identifier">
          <FieldReadonly :value="user.origin_identifier"
            :formatClass="(user.deleted_at ? 'text-danger' : '') + ' d-block text-truncate'" />
        </Tooltip>
      </template>
      <FieldReadonly value="" :formatClass="user.deleted_at ? 'text-danger' : ''" v-else />
      <FieldReadonly v-if="!hasSingleUserOrigin()" :value="user.userOrigin" formatClass="text-muted small" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Type') }}</span>
      <FieldReadonly :value="user.userType" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Name') }}</span>
      <FieldReadonly :value="user" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Email') }}</span>
      <FieldReadonly :value="user.email" />
      <FieldReadonly :value="user.alternative_email" v-if="user.alternative_email" formatClass="text-muted" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Company') }}</span>
      <FieldReadonly :value="user.company" format="object" />
      <FieldReadonly :value="user.department" format="object" v-if="user.department" formatClass="text-muted" />
      <FieldReadonly :value="user.office" format="object" v-if="user.office" formatClass="text-muted" />
    </td>
    <td data-no-export class="d-block d-lg-table-cell text-end border-0 border-lg-bottom" @click.stop>
      <ActionDropdown ref="actions" :actions="actions" />
    </td>
  </tr>
</template>

<script>
import { hasSingleUserOrigin } from "@/components/user/userOrigin.js";
import FieldReadonly from "@/components/common/form/FieldReadonly.vue";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";
import Tooltip from "@/components/common/layout/Tooltip.vue";

export default {
  components: {
    FieldReadonly,
    ActionDropdown,
    Tooltip,
  },
  props: {
    user: Object,
  },
  data() {
    return {};
  },
  emits: [
    'edit',
    'deleted',
    'updating',
    'updated',
  ],
  methods: {
    hasSingleUserOrigin,
  },
  computed: {
    actions() {
      return [
        {
          label: this.i18n('Detail'),
          fn: () => {
            this.$router.push({
              name: 'UserDetail',
              params: {
                id: this.user.id,
              },
            });
          },
          if: true,
          starred: true,
        }
      ];
    },
  },
};
</script>