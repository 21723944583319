<template>
  <ApiViewWrapper :allowAnonymous="false" :notFound="true"></ApiViewWrapper>
</template>

<script>
import ApiViewWrapper from "@/components/common/view/ApiViewWrapper.vue";

export default {
  components: {
    ApiViewWrapper,
  },
  data() {
    return {}
  },
  created() {
    this.$root.setPageTitle(this.i18n('Not Found'));
  },
};
</script>
