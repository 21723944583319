<template>
  <button type="button" :class="btnClass" @click="showCompanyModal">
    <FileEarmarkPlusIcon />
    {{ i18n('Add New') }}
  </button>

  <CompanyModal ref="CompanyModal" @created="$emit('created', $event)" />
</template>
<script>
import CompanyModal from "@/components/company/CompanyModal.vue";
import FileEarmarkPlusIcon from "@/assets/svg/file-earmark-plus.vue";

export default {
  components: {
    CompanyModal,
    FileEarmarkPlusIcon,
  },
  props: {
    btnClass: {
      type: String,
      default: 'btn btn-outline-light',
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  emits: [
    'added',
    'created'
  ],
  methods: {
    showCompanyModal() {
      this.$refs['CompanyModal'].show();
    },
  }
};
</script>