<template>
  <div class="bg-white border p-2 mt-3">
    <div class="table-responsive position-relative"
      :class="{ 'with-actions-gap': departments.length < 4, 'opacity-50': loading }">
      <table class="table">
        <caption class="sr-only" data-no-export>{{ i18n('Department list') }}</caption>
        <thead>
          <tr class="d-none d-lg-table-row">
            <th scope="col">{{ i18n('Id') }}</th>
            <th scope="col">{{ i18n('Name') }}</th>
            <th data-no-export scope="col" style="min-width: 48px">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <DepartmentListItem v-for="department in departments" :department="department" :key="department.id"
            @edit="showDepartmentModal" @deleted="$emit('deleted', $event)" @updated="$emit('updated', $event)"
            @updating="$emit('updating', $event)" :company="company" />
          <EmptyListItem v-if="!departments || departments.length == 0" :loading="loading" :colspan="3" />
          <LazyLoadListItem v-if="!loading && (lazyLoad || autoLazyLoad)" :autoLazyLoad="autoLazyLoad" :colspan="3"
            @next="$emit('next', $event)" />
        </tbody>
      </table>
      <div class="position-absolute h-100 w-100 top-0" @click.stop v-if="loading"></div>
    </div>
  </div>
  <DepartmentModal ref="DepartmentModal" @updated="$emit('updated', $event)" :company="company" />
</template>

<script>
import DepartmentListItem from "@/components/department/DepartmentListItem.vue";
import DepartmentModal from "@/components/department/DepartmentModal.vue";
import EmptyListItem from "@/components/common/list/EmptyListItem.vue";
import LazyLoadListItem from "@/components/common/list/LazyLoadListItem.vue";
import Tooltip from "@/components/common/layout/Tooltip.vue";

export default {
  components: {
    DepartmentListItem,
    DepartmentModal,
    EmptyListItem,
    LazyLoadListItem,
    Tooltip,
  },
  emits: [
    'updated',
    'deleted',
    'updated',
    'updating',
    'next',
  ],
  props: {
    company: {
      type: Object,
      required: true,
    },
    loading: Boolean,
    lazyLoad: Boolean,
    autoLazyLoad: Boolean,
    departments: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    showDepartmentModal(department) {
      if (!this.$refs.DepartmentModal) return;
      this.$refs.DepartmentModal.show(department.id);
    },
  },
};
</script>