<template>
  <MinimalLayout :allowAnonymous="true" v-if="preloaded">
    <div class="container-fluid">
      <section class="vh-100">
        <div class="row vh-100">
          <div class="col-md-6 d-flex align-items-center justify-content-center vh-25 bg-cover bg-center"
            :style="`background-image: url(${loginImagePath});`"></div>
          <div class="col-md-6 d-flex align-items-center justify-content-center vh-25 bg-cover bg-center">
            <div class="d-flex align-items-center text-center">
              <form action="javascript:void(0)" @submit="submit" :class="{ submitting: submitted }">
                <img class="img-fluid mb-4 w-75" :src="defaultLogoUrl" :alt="defaultCompanyAlias" srcset="">
                <div class="alert alert-danger" v-if="errorMessage">{{ errorMessage }}</div>
                <div>
                  <p class="h3 mb-3">{{ i18n('Do Login') }}</p>
                </div>
                <div class="row g-2 mb-2">
                  <div class="col-12">
                    <Field :label="i18n('Username')" type="text" placeholder="" v-model="formData.username"
                      :disabled="submitted" :errorMessage="errorMessages.username" />
                  </div>
                  <div class="col-12">
                    <Field :label="i18n('Password')" type="password" placeholder="" :disabled="submitted"
                      v-model="formData.password" :errorMessage="errorMessages.password" />
                  </div>
                </div>
                <div class="d-grid mb-3 mt-4">
                  <button type="submit" class="btn btn-dark btn-dark-petrol" :disabled="submitted">
                    <span v-if="submitted" class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden"> {{ i18n('Sending ...') }}</span>
                    </span>
                    {{ i18n('Log In') }}
                  </button>
                </div>
                <div class="d-grid mb-3 mt-1 small">
                  <router-link :to="{ name: 'LoginForgotPassword' }">
                    {{ i18n('Forgot your password?') }}
                  </router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </MinimalLayout>
  <div class="p-5 text-center" v-else>
    <span class="spinner-border spinner-border-sm" role="status">
      <span class="visually-hidden">
        {{ i18n('Loading...') }}
      </span>
    </span>
  </div>
</template>

<script>
import MinimalLayout from "@/layouts/MinimalLayout.vue";
import Field from "@/components/common/form/Field.vue";
import api from "@/api.js";
import { duplicate, } from '@/tools/object.js';
import { translatedErrorMessage, } from "@/tools/errorHandling.js";

import $env from "@/tools/environment.js";
const defaultLogoUrl = $env('VITE_DEFAULT_LOGO_URL');
const defaultCompanyAlias = $env('VITE_DEFAULT_COMPANY_ALIAS');
const loginImagePath = $env('VITE_DEFAULT_LOGIN_IMAGE');

export default {
  components: {
    MinimalLayout,
    Field,
  },
  data() {
    return {
      preloaded: false,
      formData: {},
      errorMessage: '',
      errorMessages: {},
      submitted: false,
      loginImagePath,
      defaultLogoUrl,
      defaultCompanyAlias,
      urlParams: {},
    };
  },
  created() {
    this.urlParams = Object.fromEntries(new URLSearchParams(window.location.search));

    if (!this.urlParams.redirect_uri
      || !this.urlParams.client_id
    ) {
      this.$router.push('/');
      return;
    }

    api.auth().then(user => {
      if (window.localStorage.ad_cookie) {
        api.post('login/ad/', Object.assign(duplicate(this.urlParams), { cookie: window.localStorage.ad_cookie })).then(codeResult => {
          this.processCodeResult(codeResult);
        }).catch(() => {
          delete window.localStorage.ad_cookie;
          this.preloaded = true;
        });
      } else {
        this.preloaded = true;
      }
    });
  },
  methods: {
    submit() {
      if (!this.formData.username || !this.formData.password) return;

      this.errorMessage = '';
      this.errorMessages = {};
      this.submitted = true;

      const postFormData = Object.assign(duplicate(this.urlParams), duplicate(this.formData));

      api.post('login/ad/', postFormData).then(codeResult => {
        this.processCodeResult(codeResult);
      }).catch((errorMessage) => {
        this.errorMessage = translatedErrorMessage(errorMessage, {
          'Unauthorized': 'Invalid username or password.',
        });
        this.submitted = false;
      });
    },
    processCodeResult(codeResult) {
      if (codeResult.cookie) {
        window.localStorage.ad_cookie = codeResult.cookie;
      }

      const url = new URL(this.urlParams.redirect_uri);
      url.searchParams.append('code', codeResult.code);
      url.searchParams.append('state', this.urlParams.state || '');
      window.location.href = url.toString();
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  max-width: 320px;
}
</style>