<template>
  <DefaultLayout :allowAnonymous="true" :showMenu="false">
    <div class="text-center">
      <h1 class="mt-3">{{ title }}</h1>
      <p>
        {{ message }}
        <br>{{ retryMessage }}
      </p>
      <router-link :to="{ name: 'Index' }">
        {{ backToHome }}
      </router-link>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";

export default {
  components: {
    DefaultLayout,
  },
  data() {
    return {
      title: '',
      message: '',
      retryMessage: '',
      backToHome: '',
    };
  },
  created() {
    this.title = this.i18n('Oops!');
    this.message = this.i18n('It seems there was a problem logging in. You may not have access to this application.');
    this.retryMessage = this.i18n('If you think this is an error, please try again later or contact the system administrator.');
    this.backToHome = this.i18n('Back to home');

    this.$root.setPageTitle(this.i18n('Login Error'));
  }
};
</script>