<template>
    <a href="javascript:void(0)" class="btn btn-sm btn-outline-dark" :class="cssClass"
        @click="$refs['UserSendAccessInstructionsModal'].show()">
        <FileIcon />
        <span class="d-none d-sm-inline-block ms-2">
            {{ i18n('Send Access Instructions') }}
        </span>
    </a>
    <UserSendAccessInstructionsModal :user="user" ref="UserSendAccessInstructionsModal" />
</template>

<script>
import FileIcon from "@/assets/svg/file.vue";
import UserSendAccessInstructionsModal from "@/components/user/UserSendAccessInstructionsModal.vue";

export default {
    components: {
        FileIcon,
        UserSendAccessInstructionsModal,
    },
    props: {
        cssClass: String,
        user: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>