<template>
    <div class="custom-list-view" v-if="!withUser">
        <EmptyListItem v-if="!profiles || profiles.length == 0" />
        <UserProfileListItem v-for="profile in profiles" :profile="profile" :key="profile.id" :user="user"
            @editUsername="showUserApplicationUsernameModal" :withUser="withUser" :withApplication="withApplication"
            :application="profile.application || application" :hideActions="hideActions" />
    </div>
    <div class="custom-list-view" v-else-if="!withApplication">
        <EmptyListItem v-if="!userProfiles || userProfiles.length == 0" />
        <UserProfileListItem @updated="$emit('updated', $event)" v-for="userProfile in userProfiles"
            @editUsername="showUserApplicationUsernameModal" :user="userProfile.user"
            :application="application || userProfile.profile.application"
            :key="`${userProfile.user.id}|${userProfile.profile.id}`" :profile="userProfile.profile"
            :withUser="withUser" :withApplication="withApplication" :hideActions="hideActions" />
    </div>
    <UserApplicationUsernameModal ref="UserApplicationUsernameModal" @updated="$emit('updated', $event)" />
</template>

<script>
import EmptyListItem from "@/components/common/list/EmptyListItem.vue";
import UserApplicationUsernameModal from "@/components/userApplicationUsername/UserApplicationUsernameModal.vue";
import UserProfileListItem from "@/components/userProfile/UserProfileListItem.vue";

export default {
    components: {
        EmptyListItem,
        UserApplicationUsernameModal,
        UserProfileListItem,
    },
    emits: ['updated', 'deleted', 'updating'],
    props: {
        loading: Boolean,
        withUser: {
            type: Boolean,
            default: false,
        },
        withApplication: {
            type: Boolean,
            default: true,
        },
        application: {
            type: Object,
        },
        user: {
            type: Object,
            default() {
                return {};
            },
        },
        profiles: {
            type: Array,
            default() {
                return [];
            },
        },
        userProfiles: {
            type: Array,
            default() {
                return [];
            },
        },
        hideActions: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getExpireAt(profile) {
            if (!this.withUser) {
                let externalUserProfile = this.user.externalUserProfiles.find((up) => up.profile.id == profile.id);
                if (!externalUserProfile) return;

                return externalUserProfile.expires_at;
            } else if (!this.withApplication) {
                return profile.expires_at;
            } else {
                return;
            }
        },
        showUserApplicationUsernameModal(userProfile) {
            if (!this.$refs.UserApplicationUsernameModal) return;
            this.$refs.UserApplicationUsernameModal.show(userProfile.user.id, userProfile.profile.application.id);
        },
    },
};
</script>