<template>
    <button type="button" :class="btnClass" @click="showProfileRoleModal">
        <FileEarmarkPlusIcon />
        <span class="d-none d-sm-inline">
            {{ i18n('Add New') }}
        </span>
    </button>

    <ProfileRoleModal :role="role" ref="ProfileRoleModal" @created="$emit('created', $event)"
        @submit="$emit('submit', $event)" />
</template>
<script>
import ProfileRoleModal from "@/components/profileRole/ProfileRoleModal.vue";
import FileEarmarkPlusIcon from "@/assets/svg/file-earmark-plus.vue";

export default {
    components: {
        ProfileRoleModal,
        FileEarmarkPlusIcon,
    },
    data() {
        return {
            formData: {},
        };
    },
    props: {
        btnClass: {
            type: String,
            default: 'btn btn-sm btn-outline-dark',
        },
        role: {
            type: Object,
        },
    },
    emits: [
        'added',
        'created',
        'submit',
    ],
    methods: {
        showProfileRoleModal() {
            this.$refs['ProfileRoleModal'].show();
        },
    }
};
</script>
