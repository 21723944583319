<template>
    <Modal @shown="shown" @hidden="hidden" ref="modal" @submit="submit">
        <template #title>
            {{ i18n(profile ? 'Edit %s' : 'New %s').replace('%s', i18n('Profile')) }}
        </template>
        <div class="alert alert-danger mb-2" v-if="errorMessage">{{ errorMessage }}</div>

        <div class="row" v-if="profileTypes.length > 1">
            <div class="col mb-2">
                <Field :required="true" :disabled="submitted" :customAutofocus="true" :label="i18n('Type')"
                    type="dictionary" rest="types/profiles" v-model="formData.profile_type_id"
                    :errorMessage="errorMessages.profile_type_id" />
            </div>
        </div>

        <div class="row">
            <div class="col mb-2">
                <Field :required="true" :disabled="submitted" :customAutofocus="true" :label="i18n('Name')"
                    v-model="formData.name" :errorMessage="errorMessages.name" />
            </div>
        </div>

        <div class="row" v-if="application.authorizationType?.code !== 'profiles'">
            <div class="col mb-2">
                <MultipleDictionaryField :disabled="submitted" :summarized="false" :label="i18n('Permissions')"
                    :list="permissions" v-model="formData.permissionIds" />
            </div>
        </div>

        <template #footer>
            <div class="row">
                <div class="col text-end">
                    <button type="submit" class="btn btn-dark btn-sm" :disabled="submitted">
                        {{ i18n('Save') }}
                    </button>
                </div>
            </div>
        </template>
    </Modal>
</template>
<script>
import api from "@/api.js";
import { duplicate, } from "@/tools/object.js";
import Modal from "@/components/common/layout/Modal.vue";
import Field from "@/components/common/form/Field.vue";
import MultipleDictionaryField from "@/components/common/form/MultipleDictionaryField.vue";
import { translatedErrorMessage, } from "@/tools/errorHandling.js";

export default {
    components: {
        Modal,
        Field,
        MultipleDictionaryField,
    },
    data() {
        return {
            profileTypes: [],
            submitted: false,
            formData: {
                permissionIds: [],
            },
            errorMessages: {},
            errorMessage: '',
            profile: null,
            profileId: null,
            permissions: [],
        };
    },
    props: {
        application: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    emits: [
        'shown',
        'hidden',
        'updated',
        'created',
    ],
    mounted() {
        api.cached('types/profiles').then(profileTypes => {
            this.profileTypes = profileTypes;
        });
    },
    methods: {
        sanitizeFormData() {
            let formData = {
                permissionIds: [],
            };

            if (this.profile) {
                Object.keys(this.profile).forEach(key => {
                    if ([
                        'name',
                        'profileType',
                        'profile_type_id',
                        'permissions',
                    ].indexOf(key) === -1) return;
                    formData[key] = this.profile[key];
                });
            }

            if (formData.profileType !== undefined) {
                formData.profile_type_id = formData.profileType.id;
                delete formData.profileType;
            }

            if (formData.permissions !== undefined) {
                formData.permissionIds = formData.permissions.map(p => p.id);
                delete formData.permissions;
            }

            this.formData = formData;
        },
        show(profileId) {
            this.profileId = profileId;
            this.profile = null;
            this.load().then(() => {
                this.submitted = false;
                this.$refs['modal'].show();
                this.errorMessages = {};
                this.errorMessage = '';

                if (this.profile) {
                    this.formData = duplicate(this.profile);
                } else {
                    this.formData = {};
                }

                this.sanitizeFormData();
            });
        },
        load() {
            let recordPromise = Promise.resolve();
            if (this.profileId) recordPromise = api.get(`applications/${this.application.id}/profiles/${this.profileId}`, { acl: 'profile' });

            recordPromise.then(profile => {
                if (!profile) return;
                this.profile = profile;
            });

            return Promise.all([
                recordPromise,
                api.get('applications/' + this.application.id + '/permissions').then(permissions => {
                    this.permissions = permissions;
                }),
            ]);
        },
        hide() {
            this.$refs['modal'].hide();
        },
        shown() {
            this.$emit('shown');
        },
        hidden() {
            this.$emit('hidden');
        },
        _saveProfilePermissions(profile, permissionIds) {
            let index = this.application.profiles.findIndex(e => e.id === profile.id);
            let previousPermissionIds = (this.profile ? this.profile.permissions.map(p => p.id) : []);

            if (index === -1) {
                this.application.profiles.push(profile);
            } else {
                this.application.profiles[index] = profile;
            }

            api.clearCache();

            const attachPermission = (pId) => {
                if (!previousPermissionIds.includes(pId)) {
                    return api.post(`applications/${this.application.id}/profiles/${profile.id}/permissions/${pId}`);
                }

                return Promise.resolve();
            };

            const detachPermission = (pId) => {
                if (!permissionIds.includes(pId)) {
                    return api.delete(`applications/${this.application.id}/profiles/${profile.id}/permissions/${pId}`);
                }

                return Promise.resolve();
            };

            const permissionPromises = [
                ...permissionIds.map(attachPermission),
                ...previousPermissionIds.map(detachPermission)
            ];

            Promise.all(permissionPromises)
                .then(() => {
                    this.$emit(this.profile ? 'updated' : 'created', profile);
                    this.formData = {};
                    api.clearCache();
                    this.hide();
                })
                .catch((errorMessage) => {
                    this.errorMessage = translatedErrorMessage(errorMessage);
                    this.submitted = false;
                });
        },
        submit() {
            let promise,
                postFormData = { ...this.formData },
                permissionIds = postFormData.permissionIds;
            delete postFormData.permissionIds;
            this.errorMessage = '';
            this.submitted = true;

            if (this.profileTypes.length == 0) {
                postFormData.profile_type_id = null;
            } else if (this.profileTypes.length == 1) {
                postFormData.profile_type_id = this.profileTypes[0].id;
            }

            if (this.profile) {
                promise = api.put('applications/' + this.application.id + '/profiles/' + this.profile.id,
                    postFormData);
            } else {
                promise = api.post('applications/' + this.application.id + '/profiles', postFormData);
            }

            promise.then(profile => {
                this._saveProfilePermissions(profile, permissionIds);
            }).catch((errorMessage) => {
                this.errorMessage = translatedErrorMessage(errorMessage);
                this.submitted = false;
            });
        },
    }
};
</script>