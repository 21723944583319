<template>
  <div class="bg-white border p-2 mt-3">
    <div class="table-responsive position-relative"
      :class="{ 'with-actions-gap': applications.length < 4, 'opacity-50': loading }">
      <table class="table">
        <caption class="sr-only" data-no-export>{{ i18n('Application list') }}</caption>
        <thead>
          <tr class="d-none d-lg-table-row">
            <th scope="col">{{ i18n('Id') }}</th>
            <th scope="col">{{ i18n('Alias') }}</th>
            <template v-if="$root?.features?.applications?.categories?.enabled">
              <th scope="col">{{ i18n('Category') }}</th>
              <th scope="col">{{ i18n('Subcategory') }}</th>
            </template>
            <th scope="col">{{ i18n('Url') }}</th>
            <th scope="col">{{ i18n('Profiles') }}</th>
            <th scope="col">{{ i18n('Created At') }}</th>
            <th data-no-export scope="col" style="min-width: 48px">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <ApplicationListItem v-for="application in applications" :application="application" :key="application.id"
            @deleted="$emit('deleted', $event)" @updated="$emit('updated', $event)"
            @updating="$emit('updating', $event)" />
          <EmptyListItem v-if="!applications || applications.length == 0" :loading="loading"
            :colspan="6 + ($root?.features?.applications?.categories?.enabled ? 2 : 0)" />
          <LazyLoadListItem v-if="!loading && (lazyLoad || autoLazyLoad)" :autoLazyLoad="autoLazyLoad"
            :colspan="6 + ($root?.features?.applications?.categories?.enabled ? 2 : 0)" @next="$emit('next', $event)" />
        </tbody>
      </table>
      <div class="position-absolute h-100 w-100 top-0" @click.stop v-if="loading"></div>
    </div>
  </div>
</template>

<script>
import ApplicationListItem from "@/components/application/ApplicationListItem.vue";
import EmptyListItem from "@/components/common/list/EmptyListItem.vue";
import LazyLoadListItem from "@/components/common/list/LazyLoadListItem.vue";
import Tooltip from "@/components/common/layout/Tooltip.vue";

export default {
  components: {
    ApplicationListItem,
    EmptyListItem,
    LazyLoadListItem,
    Tooltip,
  },
  emits: [
    'updated',
    'deleted',
    'updated',
    'updating',
    'next',
  ],
  props: {
    loading: Boolean,
    lazyLoad: Boolean,
    autoLazyLoad: Boolean,
    applications: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
  },
};
</script>