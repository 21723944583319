<template>
  <div class="bg-white p-2 border">
    <PermissionFilter :filters="filters">
      <template #default>
        <h2 class="h5">
          <template v-if="application.authorizationType?.code !== 'profiles'">
            {{ i18n('Permissions') }}
          </template>
          <template v-else>
            {{ i18n('Profiles') }}
          </template>
          <span class="small text-muted">({{ permissions.length }})</span>
        </h2>
      </template>
      <template #buttons>
        <PermissionSectionActions @created="reload" @updated="reload" :application="application" />
      </template>
    </PermissionFilter>

    <PermissionList :permissions="permissions" :loading="loading" @deleted="reload" @updating="loading = true"
      @updated="reload" :application="application" />
  </div>
</template>

<script>
import api from "@/api.js";
import {
  duplicate,
  flattenUrlObject,
} from "@/tools/object.js";
import PermissionSectionActions from "@/components/permission/PermissionSectionActions.vue";
import PermissionFilter from "@/components/permission/PermissionFilter.vue";
import PermissionList from "@/components/permission/PermissionList.vue";

export default {
  components: {
    PermissionFilter,
    PermissionList,
    PermissionSectionActions,
  },
  props: {
    application: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      permissions: [],
      filters: {},
      loadTimeout: null,
      loading: true,
    }
  },
  mounted() {
    api.auth().then((user) => {
      this.$root.loggedUser = user;

      if (!this.can('APPLICATION_MANAGE')
        && !this.can('APPLICATION_ACCESS')) {
        this.$router.push({
          name: 'Index',
        });
        return;
      }

      this.load();
    });
  },
  watch: {
    params: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  computed: {
    params() {
      let params = duplicate(this.filters);
      params.acl = 'permission';

      flattenUrlObject(params);

      return params;
    },
  },
  methods: {
    load(force) {
      clearTimeout(this.loadTimeout);
      this.loading = true;

      if (!force) {
        this.loadTimeout = setTimeout(() => {
          this.load(true);
        }, 250);
        return;
      }

      api.get(`applications/${this.application.id}/permissions`, this.params).then(permissions => {
        this.permissions = permissions;
        this.loading = false;
      });
    },
    reload() {
      this.load();
    },
  },
};
</script>