<template>
  <div class="bg-white border p-2 mt-3">
    <div class="table-responsive position-relative" :class="{ 'with-actions-gap': triggers.length < 4, 'opacity-50': loading }">
      <table class="table">
        <thead>
          <tr class="d-none d-lg-table-row">
            <th scope="col">{{ $root.i18n('Id') }}</th>
            <th scope="col">{{ $root.i18n('Application') }}</th>
            <th scope="col">{{ $root.i18n('Profile') }}</th>
            <th scope="col">{{ $root.i18n('Trigger Type') }}</th>
            <th scope="col">{{ $root.i18n('Params') }}</th>
            <th scope="col">{{ $root.i18n('Created') }}</th>
            <th data-no-export scope="col" style="min-width: 48px">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <TriggerListItem v-for="trigger in triggers"
            :trigger="trigger" :key="trigger.id" @edit="showTriggerModal"
            @deleted="$emit('deleted', $event)" @updated="$emit('updated', $event)" @updating="$emit('updating', $event)" />
          <EmptyListItem v-if="!triggers || triggers.length == 0" :loading="loading" :colspan="7" />
          <LazyLoadListItem v-if="!loading && (lazyLoad || autoLazyLoad)" :autoLazyLoad="autoLazyLoad" :colspan="7"
            @next="$emit('next', $event)" />
        </tbody>
      </table>
      <div class="position-absolute h-100 w-100 top-0" @click.stop v-if="loading"></div>
    </div>
  </div>
  <TriggerModal ref="TriggerModal" @updated="$emit('updated', $event)" />
</template>

<script>
import TriggerListItem from "@/components/trigger/TriggerListItem.vue";
import TriggerModal from "@/components/trigger/TriggerModal.vue";
import EmptyListItem from "@/components/common/list/EmptyListItem.vue";
import LazyLoadListItem from "@/components/common/list/LazyLoadListItem.vue";
import Tooltip from "@/components/common/layout/Tooltip.vue";

export default {
  components: {
    TriggerListItem,
    TriggerModal,
    EmptyListItem,
    LazyLoadListItem,
    Tooltip,
  },
  emits: [
    'updated',
    'deleted',
    'updated',
    'updating',
    'next',
  ],
  props: {
    loading: Boolean,
    lazyLoad: Boolean,
    autoLazyLoad: Boolean,
    triggers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    showTriggerModal(trigger) {
      if (!this.$refs.TriggerModal) {
        return;
      }

      this.$refs.TriggerModal.show(trigger.id);
    },
  },
};
</script>