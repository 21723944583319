<template>
  <div class="bg-white p-2 border">
    <ProfileFilter :filters="filters">
      <template #default>
        <h2 class="h5">
          {{ i18n('Profiles') }}
          <span class="small text-muted">({{ profileRoles.length }})</span>
        </h2>
      </template>
      <template #buttons>
        <ProfileRoleCreateAction @created="reload" v-if="can('ROLE_MANAGE')" btnClass="ms-1 btn btn-sm btn-outline-dark"
          :role="role" />
      </template>
    </ProfileFilter>

    <ProfileRoleList :role="role" :profileRoles="profileRoles" :loading="loading" @deleted="reload"
      @updating="loading = true" @updated="reload" />
  </div>
</template>

<script>
import api from "@/api.js";
import {
  duplicate,
  flattenUrlObject,
} from "@/tools/object.js";
import ProfileRoleCreateAction from "@/components/profileRole/ProfileRoleCreateAction.vue";
import ProfileFilter from "@/components/profile/ProfileFilter.vue";
import ProfileRoleList from "@/components/profileRole/ProfileRoleList.vue";

export default {
  components: {
    ProfileRoleCreateAction,
    ProfileFilter,
    ProfileRoleList,
  },
  props: {
    role: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      profileRoles: [],
      filters: {},
      loadTimeout: null,
      loading: true,
    }
  },
  mounted() {
    api.auth().then((role) => {
      this.$root.loggedRole = role;

      if (!this.can('ROLE_MANAGE')
        && !this.can('ROLE_ACCESS')) {
        this.$router.push({
          name: 'Index',
        });
        return;
      }

      this.load();
    });
  },
  watch: {
    params: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  computed: {
    params() {
      let params = duplicate(this.filters);
      params.acl = 'profileRole';

      flattenUrlObject(params);

      return params;
    },
  },
  methods: {
    load(force) {
      clearTimeout(this.loadTimeout);
      this.loading = true;

      if (!force) {
        this.loadTimeout = setTimeout(() => {
          this.load(true);
        }, 250);
        return;
      }

      api.get(`roles/${this.role.id}/profiles`, this.params).then(profileRoles => {
        this.profileRoles = profileRoles;
        this.loading = false;
      });
    },
    reload() {
      this.load();
    },
  },
};
</script>