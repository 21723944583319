<template>
  <button type="button" :class="btnClass" @click="showProfileArrayModal">
    <GridIcon />
    {{ i18n('Array Update') }}
  </button>

  <ProfileArrayModal ref="ProfileArrayModal" @created="$emit('created', $event)" :application="application" />
</template>
<script>
import ProfileArrayModal from "@/components/profile/ProfileArrayModal.vue";
import GridIcon from "@/assets/svg/grid.vue";

export default {
  components: {
    ProfileArrayModal,
    GridIcon,
  },
  props: {
    application: {
      type: Object,
      required: true,
    },
    btnClass: {
      type: String,
      default: 'btn btn-outline-light',
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  emits: [
    'added',
    'created'
  ],
  methods: {
    showProfileArrayModal() {
      this.$refs['ProfileArrayModal'].show();
    },
  }
};
</script>