<template>
    <div class="form-outline">
        <div class="input-group">
            <div class="form-floating">
                <select :class="{ 'form-select': true, 'no-label': !label }" :id="id" :aria-label="label"
                    v-model="value" ref="input" :required="required"
                    :data-pseudo-required="(pseudoRequired ? 'true' : 'false')" :autocomplete="autocompleteAttr"
                    :disabled="disabled || readonly" :data-custom-autofocus="(customAutofocus ? 'on' : false)"
                    @blur="$emit('blur')" @focus="$emit('focus')">
                    <option v-if="fallbackPlaceholder" value="">{{ fallbackPlaceholder }}</option>
                    <template v-if="expectsObject">
                        <template v-for="(option, index) in options">
                            <template
                                v-if="valueId && (option._originalObject ? option._originalObject : option)?.id == valueId">
                                <option :value="value" :key="index">
                                    {{ option.name }}
                                </option>
                            </template>
                            <template v-else>
                                <option :value="(option._originalObject ? option._originalObject : option)"
                                    :key="index">
                                    {{ option.name }}
                                </option>
                            </template>
                        </template>
                    </template>
                    <template v-else>
                        <option :value="option.id" v-for="option in options" :key="option.id">{{ option.name }}</option>
                    </template>
                </select>
                <label v-if="label" :for="id">{{ label + (required || pseudoRequired ? '*' : '') }}</label>
                <div class="text-danger small ms-1 mt-1" v-if="errorMessage">
                    {{ errorMessage }}
                </div>
                <Transition v-if="resettable">
                    <span v-if="value && (typeof (value) != 'object' || Object.keys(value).length > 0)"
                        class="clean-field" @click.stop="resetInput">
                        <i>{{ i18n('Cleanup') }}</i>
                        <CleanIcon />
                    </span>
                </Transition>
            </div>
            <slot></slot>
        </div>
    </div>
</template>
<script>
import api from "@/api.js";
import vModelMixin from "@/mixins/common/vModelMixin.js";
import fieldMixin from "@/mixins/common/fieldMixin.js";
import CleanIcon from "@/assets/svg/clean.vue";
import {
    extractKeyFromObject,
    extractLabelFromObject,
} from "@/tools/object.js";

export default {
    components: {
        CleanIcon,
    },
    mixins: [
        vModelMixin,
        fieldMixin,
    ],
    props: {
        filter: {
            type: Function,
        },
        sort: {
            type: Function,
        },
        resettable: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default() {
                return null;
            },
        },
        hash: {
            type: Object,
            default() {
                return null;
            },
        },
        rest: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            options: [],
            type: 'select',
            placeholderValue: '',
        };
    },
    mounted() {
        this.setOptions();
    },
    watch: {
        hash: {
            handler() {
                this.setOptions();
            },
            deep: true,
        },
        list: {
            handler() {
                this.setOptions();
            },
            deep: true,
        },
        rest() {
            this.setOptions();
        },
        modelValue() {
            this.sanitizeValue();
        },
    },
    computed: {
        expectsObject() {
            return this.format == 'object';
        },
        valueId() {
            if (this.expectsObject) {
                if (!this.value) {
                    return null;
                } else if (this.value.id) {
                    return this.value.id;
                } else if (this.value.code) {
                    return this.value.code;
                } else {
                    return null;
                }
            }

            return this.value;
        },
        fallbackPlaceholder() {
            return this.placeholder || this.placeholder === null ? this.placeholder : this.i18n('Select...');
        },
        text() {
            let selectedOption = null;

            if (this.valueId) {
                selectedOption = this.options.filter(o => o.id == this.valueId)[0];
            }

            return (selectedOption ? selectedOption.name : '');
        },
    },
    methods: {
        translateOption(item) {
            if (item &&
                typeof (item) == 'object') {
                return {
                    id: extractKeyFromObject(item),
                    name: extractLabelFromObject(item),
                    _originalObject: item,
                };
            } else {
                return {
                    id: item,
                    name: item,
                    _originalObject: item,
                };
            }
        },
        async setOptions() {
            let options = [];

            if (this.list !== null) {
                if (!Array.isArray(this.list)) this.list = [];
                this.list.forEach((item) => {
                    options.push(this.translateOption(item));
                });
            } else if (this.hash !== null) {
                Object.keys(this.hash).forEach(key => {
                    options.push({
                        id: key,
                        name: this.hash[key],
                    });
                });
            } else if (this.rest !== null) {
                let list = await api.cached(this.rest);
                options = [];

                if (!Array.isArray(list)) {
                    list = [];
                }

                list.forEach(item => {
                    options.push(this.translateOption(item));
                });
            }

            if (this.filter) {
                options = options.filter(this.filter);
            }

            if (this.sort) {
                options = options.sort(this.sort);
            }

            this.options = options;
            this.sanitizeValue();
        },
        sanitizeValue() {
            this.sanitizeObjectValue();
            this.sanitizeBooleanValue();
            this.sanitizeNullValue();
        },
        sanitizeObjectValue() {
            if (!this.expectsObject) return;
            if (!this.modelValue) return;
            if (typeof (this.modelValue) !== 'string' && typeof (this.modelValue) !== 'number') return;
            if (this.options.length == 0) return;
            if (isNaN(this.modelValue * 1)) return;

            const selectedOption = this.options.filter(o => o.id == this.modelValue * 1)[0];

            if (!selectedOption) return;
            this.value = selectedOption._originalObject;
        },
        sanitizeBooleanValue() {
            if (this.format !== 'boolean') return;
            if (this.modelValue === undefined || this.modelValue === null || this.modelValue === '') return;
            if (typeof (this.modelValue) !== 'string' && typeof (this.modelValue) !== 'number') return;
            if (this.options.length == 0) return;
            if (isNaN(this.modelValue * 1)) return;

            this.value = this.modelValue * 1 === 1;
        },
        sanitizeNullValue() {
            if (this.modelValue !== '' && this.modelValue !== undefined) return;

            const selectedOption = this.options.filter(o => o.id == this.modelValue)[0];

            if (!selectedOption && this.value !== this.placeholderValue) {
                this.value = this.placeholderValue;
            }
        },
        resetInput() {
            this.value = this.placeholderValue;
            this.$emit('reset');
        },
    },
};
</script>

<style lang="scss" scoped>
.clean-field {
    .custom-icon {
        max-width: 12px;
        max-height: 12px;
    }
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>