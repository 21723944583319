<template>
  <tr @dblclick="$refs['actions'].callFirstStarredAction();" class="d-block d-lg-table-row bg-white px-2 py-2 mb-2 border p-lg-0 mb-lg-0 border-lg-0">
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ $root.i18n('Id') }}</span>
      <FieldReadonly :value="trigger.id" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ $root.i18n('Application') }}</span>
      <FieldReadonly :value="trigger.application" format="object" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ $root.i18n('Profile') }}</span>
      <FieldReadonly :value="trigger.profile" format="object" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ $root.i18n('Trigger Type') }}</span>
      <FieldReadonly :value="trigger.triggerType" format="object" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ $root.i18n('Params') }}</span>
      <FieldReadonly :value="trigger.params" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ $root.i18n('Created') }}</span>
      <FieldReadonly :value="trigger.created_at" format="datetime" />
    </td>
    <td data-no-export class="d-block d-lg-table-cell text-end border-0 border-lg-bottom" @click.stop>
      <ActionDropdown ref="actions" :actions="actions" />
    </td>
  </tr>
</template>

<script>
import api from "@/api.js";
import FieldReadonly from "@/components/common/form/FieldReadonly.vue";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";

export default {
  components: {
    FieldReadonly,
    ActionDropdown,
  },
  props: {
    trigger: Object,
  },
  data() {
    return {};
  },
  emits: [
    'edit',
    'deleted',
    'updating',
    'updated',
  ],
  methods: {
  },
  computed: {
    actions() {
      return [{
        label: this.$root.i18n('Edit'),
        fn: () => {
          this.$emit('edit', this.trigger);
        },
        if: (this.trigger.acl?.update) === true,
        starred: true,
      },
      {
        label: this.$root.i18n('Detail'),
        fn: () => {
          this.$emit('edit', this.trigger);
        },
        if: (this.trigger.acl?.update) !== true,
        starred: true,
      },
      {
        label: this.$root.i18n('Delete'),
        fn: () => {
          this.$emit('updating', this.trigger);
          this.$root.requestConfirmation(this.$root.i18n('Delete? This action cannot be undone.'), () => {
            api.delete(`triggers/${this.trigger.id}`).then(() => {
              this.$emit('deleted', this.trigger);
            }).then(() => {
              // Force reload
              this.$emit('updated', this.trigger);
            });
          });
        },
        if: (this.trigger.acl?.delete) === true,
        starred: false,
      },
      ];
    },
  },
};
</script>