<template>
  <div class="bg-white p-2 border">
    <ProfileFilter :filters="filters">
      <template #default>
        <h2 class="h5">
          {{ i18n('Profiles') }}
          <span class="small text-muted">({{ profiles.length }})</span>
        </h2>
      </template>
      <template #buttons>
        <ProfileArrayUpdateAction @created="reload" v-if="application?.acl?.update"
          btnClass="ms-1 btn btn-sm btn-outline-dark" :application="application" />
        <ProfileSectionActions @created="reload" @updated="reload" :application="application" />
      </template>
    </ProfileFilter>

    <ProfileList :profiles="profiles" :loading="loading" @deleted="reload" @updating="loading = true" @updated="reload"
      :application="application" />
  </div>
</template>

<script>
import api from "@/api.js";
import {
  duplicate,
  flattenUrlObject,
} from "@/tools/object.js";
import ProfileArrayUpdateAction from "@/components/profile/ProfileArrayUpdateAction.vue";
import ProfileFilter from "@/components/profile/ProfileFilter.vue";
import ProfileList from "@/components/profile/ProfileList.vue";
import ProfileSectionActions from "@/components/profile/ProfileSectionActions.vue";

export default {
  components: {
    ProfileArrayUpdateAction,
    ProfileFilter,
    ProfileList,
    ProfileSectionActions,
  },
  props: {
    application: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      profiles: [],
      filters: {},
      loadTimeout: null,
      loading: true,
    }
  },
  mounted() {
    api.auth().then((user) => {
      this.$root.loggedUser = user;

      if (!this.can('APPLICATION_MANAGE')
        && !this.can('APPLICATION_ACCESS')) {
        this.$router.push({
          name: 'Index',
        });
        return;
      }

      this.load();
    });
  },
  watch: {
    params: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  computed: {
    params() {
      let params = duplicate(this.filters);
      params.acl = 'profile';

      flattenUrlObject(params);

      return params;
    },
  },
  methods: {
    load(force) {
      clearTimeout(this.loadTimeout);
      this.loading = true;

      if (!force) {
        this.loadTimeout = setTimeout(() => {
          this.load(true);
        }, 250);
        return;
      }

      api.get(`applications/${this.application.id}/profiles`, this.params).then(profiles => {
        this.profiles = profiles;
        this.loading = false;
      });
    },
    reload() {
      this.load();
    },
  },
};
</script>