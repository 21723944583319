<template>
    <template v-if="false">
        <!-- avoid typos --></template>
    <DictionaryField @reset="$emit('reset', $event)" :tooltip="tooltip" :cssClass="cssClass" :filter="filter"
        :sort="sort" :format="format" :formatClass="formatClass"
        v-else-if="['dictionary', 'combo', 'select'].indexOf(type) != -1" :list="list" :hash="hash" :rest="rest"
        :label="label" :required="required" :pseudoRequired="pseudoRequired" :disabled="disabled" :readonly="readonly"
        :errorMessage="errorMessage" :customAutofocus="customAutofocus" :placeholder="placeholder"
        :autocomplete="autocomplete" v-model="value" :title="title" :resettable="resettable" ref="input"
        @blur="$emit('blur', $event)" @focus="$emit('focus', $event)">
        <slot></slot>
    </DictionaryField>
    <DropDownField @reset="$emit('reset', $event)" :tooltip="tooltip" :cssClass="cssClass" :filter="filter" :sort="sort"
        :format="format" :formatClass="formatClass" v-else-if="['dropdown'].indexOf(type) != -1" :list="list"
        :hash="hash" :rest="rest" :label="label" :required="required" :pseudoRequired="pseudoRequired"
        :disabled="disabled" :readonly="readonly" :errorMessage="errorMessage" :customAutofocus="customAutofocus"
        :placeholder="placeholder" :autocomplete="autocomplete" v-model="value" :title="title" :resettable="resettable"
        ref="input" @blur="$emit('blur', $event)" @focus="$emit('focus', $event)">
        <slot></slot>
    </DropDownField>
    <CheckboxField @reset="$emit('reset', $event)" :tooltip="tooltip" :cssClass="cssClass" :format="format"
        :formatClass="formatClass" v-else-if="['checkbox'].indexOf(type) != -1" :label="label" :required="required"
        :pseudoRequired="pseudoRequired" :disabled="disabled" :readonly="readonly" :errorMessage="errorMessage"
        :customAutofocus="customAutofocus" :autocomplete="autocomplete" v-model="value"
        :valueIfSelected="valueIfSelected" :title="title" @blur="$emit('blur', $event)"
        @focus="$emit('focus', $event)" />
    <RadioField @reset="$emit('reset', $event)" :tooltip="tooltip" :cssClass="cssClass" :format="format"
        :formatClass="formatClass" v-else-if="['radio'].indexOf(type) != -1" :label="label" :required="required"
        :pseudoRequired="pseudoRequired" :disabled="disabled" :readonly="readonly" :errorMessage="errorMessage"
        :customAutofocus="customAutofocus" :autocomplete="autocomplete" v-model="value"
        :valueIfSelected="valueIfSelected" :title="title" @blur="$emit('blur', $event)"
        @focus="$emit('focus', $event)" />
    <LongTextField @reset="$emit('reset', $event)" :tooltip="tooltip" :cssClass="cssClass" :format="format"
        :formatClass="formatClass" v-else-if="['longtext', 'textarea'].indexOf(type) != -1" :type="type"
        :smallLabel="smallLabel" :step="step" :label="label" :required="required" :pseudoRequired="pseudoRequired"
        :disabled="disabled" :readonly="readonly" :errorMessage="errorMessage" :customAutofocus="customAutofocus"
        :placeholder="placeholder" :autocomplete="autocomplete" v-model="value" :pattern="pattern"
        :maxlength="maxlength" :title="title" :resettable="resettable" ref="input" @blur="$emit('blur', $event)"
        @focus="$emit('focus', $event)">
        <slot></slot>
    </LongTextField>
    <WysiwygField @reset="$emit('reset', $event)" :tooltip="tooltip" :cssClass="cssClass" :format="format"
        :formatClass="formatClass" v-else-if="['wysiwyg', 'html'].indexOf(type) != -1" :type="type"
        :smallLabel="smallLabel" :step="step" :label="label" :required="required" :pseudoRequired="pseudoRequired"
        :disabled="disabled" :readonly="readonly" :errorMessage="errorMessage" :customAutofocus="customAutofocus"
        :placeholder="placeholder" :autocomplete="autocomplete" v-model="value" :pattern="pattern"
        :maxlength="maxlength" :title="title" :resettable="resettable" ref="input" @blur="$emit('blur', $event)"
        @focus="$emit('focus', $event)">
        <slot></slot>
    </WysiwygField>
    <ImageUrlField @reset="$emit('reset', $event)" :tooltip="tooltip" :cssClass="cssClass" :format="format"
        :formatClass="formatClass" v-else-if="['imageUrl'].indexOf(type) != -1" :type="type" :smallLabel="smallLabel"
        :step="step" :label="label" :required="required" :pseudoRequired="pseudoRequired" :disabled="disabled"
        :readonly="readonly" :errorMessage="errorMessage" :customAutofocus="customAutofocus" :placeholder="placeholder"
        :autocomplete="autocomplete" v-model="value" :pattern="pattern" :maxlength="maxlength" :title="title"
        :resettable="resettable" ref="input" :min="min" :max="max" :maxImageSideSize="maxImageSideSize"
        :preview="preview" @blur="$emit('blur', $event)" @focus="$emit('focus', $event)">
        <slot></slot>
    </ImageUrlField>
    <FieldReadonly :allowZero="allowZero" @reset="$emit('reset', $event)" :tooltip="tooltip" :cssClass="cssClass"
        :format="format" :formatClass="formatClass" v-else-if="['readonly'].indexOf(type) != -1" :type="type"
        :smallLabel="smallLabel" :step="step" :label="label" :required="required" :pseudoRequired="pseudoRequired"
        :disabled="disabled" :readonly="readonly" :errorMessage="errorMessage" :customAutofocus="customAutofocus"
        :placeholder="placeholder" :autocomplete="autocomplete" :value="value" :pattern="pattern" :maxlength="maxlength"
        :title="title" :resettable="resettable" ref="input" @blur="$emit('blur', $event)"
        @focus="$emit('focus', $event)">
        <slot></slot>
    </FieldReadonly>
    <TextField @reset="$emit('reset', $event)" :tooltip="tooltip" :cssClass="cssClass" :format="format"
        :formatClass="formatClass" v-else :type="type" :smallLabel="smallLabel" :step="step" :label="label"
        :required="required" :pseudoRequired="pseudoRequired" :disabled="disabled" :readonly="readonly"
        :errorMessage="errorMessage" :customAutofocus="customAutofocus" :placeholder="placeholder"
        :autocomplete="autocomplete" v-model="value" :pattern="pattern" :maxlength="maxlength" :title="title"
        :resettable="resettable" ref="input" :min="min" :max="max" @blur="$emit('blur', $event)"
        @focus="$emit('focus', $event)">
        <slot></slot>
    </TextField>
</template>
<script>
import DictionaryField from "@/components/common/form/DictionaryField.vue";
import FieldReadonly from "@/components/common/form/FieldReadonly.vue";
import DropDownField from "@/components/common/form/DropDownField.vue";
import TextField from "@/components/common/form/TextField.vue";
import ImageUrlField from "@/components/common/form/ImageUrlField.vue";
import CheckboxField from "@/components/common/form/CheckboxField.vue";
import RadioField from "@/components/common/form/RadioField.vue";
import LongTextField from "@/components/common/form/LongTextField.vue";
import WysiwygField from "@/components/common/form/WysiwygField.vue";
import vModelMixin from "@/mixins/common/vModelMixin.js";
import fieldMixin from "@/mixins/common/fieldMixin.js";

export default {
    components: {
        DictionaryField,
        DropDownField,
        TextField,
        CheckboxField,
        RadioField,
        LongTextField,
        WysiwygField,
        ImageUrlField,
        FieldReadonly,
    },
    mixins: [
        vModelMixin,
        fieldMixin,
    ],
    props: {
        allowZero: {
            type: Boolean,
            default: false,
        },
        resettable: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        step: {
            type: String,
            default: '',
        },
        smallLabel: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default() {
                return null;
            },
        },
        hash: {
            type: Object,
            default() {
                return null;
            },
        },
        pattern: {
            type: String,
            default: '',
        },
        maxlength: {
            type: Number,
        },
        min: {
            type: [Number, String,],
        },
        max: {
            type: [Number, String,],
        },
        title: {
            type: String,
            default: '',
        },
        rest: {
            type: String,
            default: null,
        },
        filter: {
            type: Function,
        },
        sort: {
            type: Function,
        },
        valueIfSelected: {},
        maxImageSideSize: Number,
        preview: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        placeholderValue() {
            if (!this.$refs['input']) return undefined;
            return this.$refs['input'].placeholderValue;
        },
    },
    methods: {
        blur() {
            if (!this.$refs['input']) return;
            if (this.$refs['input'].blur) {
                this.$refs['input'].blur();
            } else if (this.$refs['input'].$refs
                && this.$refs['input'].$refs['input']
                && this.$refs['input'].$refs['input'].blur) {
                this.$refs['input'].$refs['input'].blur();
            }
        },
        focus() {
            if (!this.$refs['input']) return;
            if (this.$refs['input'].focus) {
                this.$refs['input'].focus();
            } else if (this.$refs['input'].$refs
                && this.$refs['input'].$refs['input']
                && this.$refs['input'].$refs['input'].focus) {
                this.$refs['input'].$refs['input'].focus();
            }
        },
        resetInput() {
            if (!this.$refs['input']) return;
            if (!this.$refs['input'].resetInput) return;
            this.$refs['input'].resetInput();
        }
    },
};
</script>