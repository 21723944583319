<template>
    <DefaultLayout>
        <h1 class="h5 d-flex align-items-center mt-2">
            <div>
                <span class="small text-muted">{{ i18n('Teams') }}</span>
            </div>
        </h1>
    </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
export default {
    components: {
        DefaultLayout,
    },
}
</script>