<template>
  <DefaultLayout :allowAnonymous="true" :showMenu="false">
    <div class="text-center">
      <h1 class="mt-3">
        {{ i18n('Good Bye!') }}
      </h1>
      <p>
        {{ i18n('Your session has been closed.') }}
      </p>
      <router-link :to="{ name: 'Index' }">
        {{ i18n('Do Access') }}
      </router-link>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import api from "@/api.js";

export default {
  components: {
    DefaultLayout,
  },
  created() {
    this.$root.setPageTitle(this.i18n('Good Bye!'));
    api.logout();
  },
};
</script>