<template>
    <DefaultLayout>
        <DashboardSection />
    </DefaultLayout>
</template>

<script>
import DashboardSection from '@/components/dashboard/DashboardSection.vue';

export default {
    components: {
        DashboardSection,
    },
    data() {
        return {}
    },
};
</script>
