<template>
  <div class="" v-if="showView">
    <slot></slot>
  </div>
</template>

<script>
import api from "@/api.js";

export default {
  props: {
    allowAnonymous: {
      type: Boolean,
      default: false,
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      devEnvironmentName: false,
      isEmbed: false,
    };
  },
  computed: {
    showView() {
      return !!this.allowAnonymous || !!(this.$root.loggedUser && this.$root.loggedUser.id);
    }
  },
  methods: {
    logout() {
      this.$router.push({
        name: 'Logout'
      });
    },
  },
  created() {
    this.isEmbed = !(window === window.parent || window.opener);
    this.devEnvironmentName = api.devEnvironmentName();

    if (!this.allowAnonymous &&
      !api.hasAuthToken()) {
      api.login(
        this.$route.query,
        JSON.stringify({
          name: this.$route.name,
          params: this.$route.params
        })).then((route) => {
          this.$root.loadFeatures();
          this.$router.push(route);
        });
    } else if (api.hasAuthToken()) {
      api.auth().then((user) => {
        this.$root.loggedUser = user;
      });
    }
  },
};
</script>