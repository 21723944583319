<template>
    <i :class="{ 'custom-icon': !inline, 'custom-icon__big': big, 'custom-icon__inline': inline }">
        <svg mlns="http://www.w3.org/2000/svg" viewBox="0 0 17.08 17.08" width="16px" height="16px" version="1.1"
            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="M17.08,4.25v-.68c0-.46-.29-.87-.73-1.02L8.88.05c-.22-.07-.46-.07-.68,0L.73,2.54c-.44.15-.73.56-.73,1.02v.68c0,.59.48,1.07,1.08,1.07h.56c0,.06-.03.12-.03.18v7.25c0,.09.03.18.04.26h-.57c-.6,0-1.08.57-1.08,1.29v1.49c0,.72.47,1.29,1.08,1.29h14.93c.6,0,1.07-.57,1.07-1.29v-1.49c0-.72-.47-1.29-1.07-1.29h-.57c.02-.09.04-.17.04-.26v-7.25c0-.06-.02-.12-.03-.18h.56c.59,0,1.07-.48,1.07-1.07ZM3.69,5.32s.05.09.05.18v7.25c0,.11-.04.18,0,.18h-1.01s-.04-.1-.04-.18v-7.25c0-.11.03-.17,0-.18h1.01ZM4.79,5.32h2.18c0,.06-.03.12-.03.18v7.25c0,.09.03.18.04.26h-2.21c.02-.09.04-.17.04-.26v-7.25c0-.06-.02-.12-.03-.18ZM9.02,5.32s.05.09.05.18v7.25c0,.11-.04.18,0,.18h-1.01s-.04-.1-.04-.18v-7.25c0-.11.03-.17,0-.18h1.01ZM16,14.3v1.49c0,.12-.04.2,0,.21H1.13s-.05-.11-.05-.21v-1.49c0-.12.04-.2,0-.21l14.85-.02s.07.1.07.23ZM10.11,13.01c.02-.09.04-.17.04-.26v-7.25c0-.06-.02-.12-.03-.18h2.18c0,.06-.03.12-.03.18v7.25c0,.09.03.18.04.26h-2.21ZM14.4,5.5v7.25c0,.11-.04.18,0,.18h-1.01s-.04-.1-.04-.18v-7.25c0-.11.03-.17,0-.18h1.01s.05.09.05.18ZM16,4.24h-1.57s-.01,0-.02,0h-1.07s-.01,0-.02,0h-4.21s-.02,0-.03,0h-1.07s-.02,0-.03,0H3.79s-.03,0-.04,0h-1.07s-.03.01-.05.01h-1.55s0-.67,0-.67l7.46-2.49,7.46,2.48v.67Z" />
            </g>
        </svg>
    </i>
</template>
<script>
export default {
    props: {
        big: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: false,
        }
    }
}
</script>