<template>
  <tr @dblclick="$refs['actions'].callFirstStarredAction();"
    class="d-block d-lg-table-row bg-white px-2 py-2 mb-2 border p-lg-0 mb-lg-0 border-lg-0">
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Id') }}</span>
      <FieldReadonly :value="company.id" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Code') }}</span>
      <FieldReadonly :value="company.code" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Alias') }}</span>
      <FieldReadonly :value="company.alias" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Name') }}</span>
      <FieldReadonly :value="company.name" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Of Group') }}</span>
      <FieldReadonly :value="company.is_from_group" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Client') }}</span>
      <FieldReadonly :value="company.is_client" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Users') }}</span>
      <FieldReadonly :value="company.user_count" />
    </td>
    <td class="d-block d-lg-table-cell">
      <span class="small d-lg-none fw-bold" data-no-export>{{ i18n('Created At') }}</span>
      <FieldReadonly format="date" :value="company.created_at" />
    </td>
    <td data-no-export class="d-block d-lg-table-cell text-end border-0 border-lg-bottom" @click.stop>
      <ActionDropdown ref="actions" :actions="actions" />
    </td>
  </tr>
</template>

<script>
import FieldReadonly from "@/components/common/form/FieldReadonly.vue";
import Field from "@/components/common/form/Field.vue";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";

export default {
  components: {
    FieldReadonly,
    Field,
    ActionDropdown,
  },
  props: {
    company: Object,
  },
  data() {
    return {};
  },
  emits: [
    'deleted',
    'updating',
    'updated',
  ],
  methods: {
  },
  computed: {
    actions() {
      return [{
        label: this.i18n('Detail'),
        fn: () => {
          this.$router.push({ name: 'CompanyDetail', params: { id: this.company.id } });
        },
        if: (this.company.acl?.update) === true || (this.company.acl?.view) === true,
        starred: true,
      },
      ];
    },
  },
};
</script>