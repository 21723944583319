<template>
    <Modal @shown="shown" @hidden="hidden" ref="modal" modalClass="modal-md" @submit="submit">
        <template #title>
            {{ application.authorizationType?.code !== 'profiles' ?
                i18n('Import Permissions')
                : i18n('Import Profiles') }}
        </template>

        <div class="alert alert-danger mb-2" v-if="errorMessage">{{ errorMessage }}</div>

        <div class="row">
            <div class="col mb-2">
                <FileField :disabled="submitted" :multiple="false" :required="true" @input="inputFile"
                    :label="i18n('JSON File')" accept=".json" files="application/json"
                    :errorMessage="errorMessages.file" />
            </div>
        </div>

        <template #footer>
            <div class="row">
                <div class="col text-end">
                    <button type="submit" class="btn btn-dark btn-sm" :disabled="submitted">{{ i18n('Import')
                        }}</button>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import api from "@/api.js";
import Modal from "@/components/common/layout/Modal.vue";
import FileField from "@/components/common/form/FileField.vue";


export default {
    components: {
        Modal,
        FileField,
    },
    props: {
        application: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            submitted: false,
            formData: {},
            errorMessages: {},
            errorMessage: '',
        };
    },
    emits: [
        'shown',
        'hidden',
        'updated',
    ],
    methods: {
        show() {
            this.submitted = false;
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        shown() {
            this.$emit('shown');
        },
        hidden() {
            this.$emit('hidden');
        },
        inputFile(files) {
            this.errorMessage = null;
            this.file = files[0].binary;
        },
        submit() {
            this.errorMessage = '';
            this.errorMessages = {};
            this.submitted = true;

            if (this.file && this.file.type === 'application/json') {
                let reader = new FileReader();
                reader.onload = function (e) {
                    let content = e.target.result;
                    api.put(`applications/${this.application.id}/permissions/import`, JSON.parse(content)).then(() => {
                        this.$emit('updated');
                        api.clearCache();
                        this.hide();
                    }).catch((err) => {
                        this.submitted = false;
                        this.errorMessage = this.i18n('The file could not be processed.');
                    });
                }.bind(this);

                reader.readAsText(this.file);
            } else {
                this.errorMessage = this.i18n('The file is not a valid JSON file.');
                this.submitted = false;
            }
        },
    }
}
</script>