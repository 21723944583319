<template>
    <ActionDropdown :actions="actions" cssClass="ms-1" />
    <PermissionModal :application="application" ref="PermissionModal" @created="$emit('created', $event)" />
    <PermissionImportModal ref="PermissionImportModal" @updated="$emit('updated', $event)" :application="application" />
</template>

<script>
import api from "@/api.js";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";
import PermissionModal from "@/components/permission/PermissionModal.vue";
import PermissionImportModal from "@/components/permission/PermissionImportModal.vue";

export default {
    components: {
        ActionDropdown,
        PermissionModal,
        PermissionImportModal,
    },
    props: {
        application: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            permissions: [],
            filters: {},
            loadTimeout: null,
            loading: true,
        };
    },
    emits: [
        'updated',
        'created',
        'shown',
        'hidden',
        'submit',
    ],
    methods: {
        showPermissionModal() {
            if (!this.$refs['PermissionModal']) return;
            this.$refs['PermissionModal'].show();
        },
        showPermissionImportModal() {
            if (!this.$refs['PermissionImportModal']) return;
            this.$refs['PermissionImportModal'].show();
        },
    },
    computed: {
        actions() {
            return [
                {
                    label: this.i18n('Add New'),
                    fn: () => {
                        this.showPermissionModal();
                    },
                    if: this.application?.acl?.update === true,
                    starred: true,
                },
                {
                    label: this.application.authorizationType?.code !== 'profiles' ? this.i18n('Export permissions') : this.i18n('Export profiles'),
                    fn: () => {
                        api.getFile(`applications/${this.application.id}/permissions/export`, {}, 'export_permissions_' + this.application.name + '.json').then(result => {
                            api.clearCache();
                        }).catch((err) => {
                            this.$root.showErrorModal(this.i18n('Error'), this.i18n('Document could not be downloaded. Try again later.'));
                        });
                    },
                    if: this.application?.acl?.update === true,
                },
                {
                    label: this.application.authorizationType?.code !== 'profiles' ? this.i18n('Import Permissions') : this.i18n('Import Profiles'),
                    fn: () => {
                        this.showPermissionImportModal();
                    },
                    if: this.application?.acl?.update === true,
                },
            ];
        },
    },
};
</script>