<template>
    <div class="col-12 col-md-11 align-self-center">
        <div class="panel-body" data-equalizer-watch="" style="height: 128px;">
            <span class="rol-12 col-md-2">{{ data }}</span>
            <div v-if="typeItem === 'userDirect'">
                <div class="rol-12 col-md-3 align-right">
                    <i class="rounded-circle me-2" height="24" width="24">
                        <svg role="img" viewBox="0 0 32 32">
                            <g stroke="#000" fill="none" fill-rule="evenodd">
                                <path
                                    d="M12 16.5c1.5 0 5-2 5-5 1.5 0 1.5-3 0-3L16.5 6c-4 0-4-1.5-4.5-2-.5.5-.5 2-4.5 2L7 8.5c-1.5 0-1.5 3 0 3 0 3 3.5 5 5 5z">
                                </path>
                                <path
                                    d="M17.977 9.219L18.5 5.5s.5-5-6.5-5-6.5 5-6.5 5l.533 3.695m3.237 6.187l-6.174 1.873A4 4 0 00.5 21v2.5h23V21a3.998 3.998 0 00-2.596-3.745l-6.174-1.873">
                                </path>
                                <path d="M9 9.5c0-.553.448-.5 1-.5s1-.053 1 .5m2 0c0-.553.448-.5 1-.5s1-.053 1 .5">
                                </path>
                            </g>
                        </svg>
                    </i>
                </div>
                <p class="h5 mt-2">{{ i18n('Users in your charge') }}</p>
            </div>
            <div v-else-if="typeItem === 'userActiveAccess'">
                <div class="rol-12 col-md-3 align-right">
                    <i class="me-2">
                        <svg role="img" viewBox="0 0 24 24">
                            <g stroke="#000" fill="none" fill-rule="evenodd">
                                <path
                                    d="M9.497 22.5H18.5s0-3.352-.474-4.781c-.413-1.244-3.396-2.076-6.526-3.255v-2.5s1-.619 1-3c1 0 1-2 0-2 0-.234 1.078-1.678.75-3-.474-1.904-6.151-1.904-6.625 0-2.369-.476-1.125 2.681-1.125 3v2c0 2.381 2 3 2 3v2.5C4.722 15.52 1.386 16.476.974 17.719.5 19.148.5 22.5.5 22.5h8.997z">
                                </path>
                                <path
                                    d="M21 22.5h2.5s0-3.352-.474-4.781c-.413-1.244-3.396-2.576-6.526-3.755v-1.5s1-.619 1-3c1 0 1-2 0-2 0-.234 1.078-1.862.75-3.184-.222-.891-2.125-2.298-3.625-1.298">
                                </path>
                            </g>
                        </svg>
                    </i>
                </div>
                <p class="h5 mt-2">{{ i18n('Active users to whom you have access') }}</p>
            </div>
            <div v-else-if="typeItem === 'pendingRecertifications'">
                <div class="rol-12 col-md-3 align-right">
                    <i class="icon icon-visits">
                        <svg role="img" viewBox="0 0 32 32">
                            <ellipse cx="8.21" cy="18.8" rx="3.6" ry="10.36" transform="rotate(-45 8.207 18.8)"
                                fill="none" stroke="#636363" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width=".9"></ellipse>
                            <ellipse cx="7.57" cy="19.44" rx="2.7" ry="9.46" transform="rotate(-45 7.561 19.438)"
                                fill="none" stroke="#636363" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width=".9"></ellipse>
                            <path
                                d="M13.62 9.57a7.44 7.44 0 01-2 1.41 29.17 29.17 0 012.32 2.09A29 29 0 0116 15.39a7 7 0 011.42-2 6.54 6.54 0 015.77-2l2.15-2.09a4.12 4.12 0 000-5.84l-1.8-1.8a4.13 4.13 0 00-5.84 0L15.56 3.8a6.55 6.55 0 01-1.94 5.77z"
                                fill="none" stroke="#636363" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width=".9"></path>
                            <path
                                d="M13.94 13.07C9.89 9 5.47 6.88 4.07 8.29L.88 11.48c1.41-1.41 5.83.73 9.87 4.77s6.19 8.47 4.78 9.87l3.18-3.18c1.41-1.41-.71-5.83-4.77-9.87z"
                                fill="none" stroke="#636363" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width=".9"></path>
                        </svg>
                    </i>
                </div>
                <p class="h5 mt-2">{{ i18n('Pending recertifications') }}</p>
            </div>
            <div v-else-if="typeItem === 'assignedApp'">
                <div class="rol-12 col-md-3 align-right">
                    <i class="icon icon-visits">
                        <svg role="img" viewBox="0 0 48 48">
                            <g stroke="#000" fill="none" fill-rule="evenodd">
                                <path fill="none" stroke="#636363" stroke-linecap="round" stroke-linejoin="round"
                                    d="M4 4h8v6H4zm21 0h8v6h-8zM14.5 4h8v6h-8zm0 9h8v6h-8zM25 13h8v6h-8zM4 13h8v6H4z">
                                </path>
                                <path fill="none" stroke="#636363" stroke-linecap="round" stroke-linejoin="round"
                                    d="M.5.5h36v22H.5zm18.56 22.01v6.99m-7 0H25.5"></path>
                            </g>
                        </svg>
                    </i>
                </div>
                <p class="h5 mt-2">{{ i18n('Apps assigned') }}</p>
            </div>
            <div v-else-if="typeItem === 'assignedProfiles'">
                <div class="rol-12 col-md-3 align-right">
                    <i class="icon icon-visits">
                        <svg role="img" viewBox="0 0 32 32">
                            <g stroke="#000" fill="none" fill-rule="evenodd">
                                <path d="M20.5 23.5h-17V.5h11l6 6z"></path>
                                <path d="M14.5.5v6h6m-13 1H12m-4.5 3h9m-9 3h9m-9 3h9m-9 3h9"></path>
                            </g>
                        </svg>
                    </i>
                </div>
                <p class="h5 mt-2">{{ i18n('Profiles assigned') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatisticItem',
    props: {
        // Example prop:
        typeItem: {
            type: String,
            required: true
        },
        data: {
            type: String,
            required: true
        },
    },
    // Your component's methods go here
    methods: {
        // Example method:
        handleClick() {
            // Handle click event
        }
    }
}
</script>

<style scoped>
/* Your component's CSS styles go here */
.icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    fill: #000;
}

.align-right {
    text-align: right;
}
</style>