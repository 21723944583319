export default {
    es: {
        '%s | Applications': '%s | Aplicaciones',
        'A password reset link will be emailed to you momentarily.': 'En breve recibirás un email con un enlace para restablecer tu contraseña.',
        'ACTIVE': 'Activo',
        'AD Status': 'Estado en AD',
        'Acting As': 'Secr. de',
        'Active (user status)': 'Alta',
        'Active at AD': 'Alta en AD',
        'Active users to whom you have access': 'Usuarios activos a los que tienes acceso',
        'Active': 'Activo',
        'Add Profile': 'Añadir perfil',
        'Add Role': 'Añadir rol',
        'Address': 'Dirección',
        'Alias': 'Alias',
        'All Users': 'Todos los usuarios',
        'All': 'Todos',
        'Alternative Email': 'Email alternativo',
        'An error has occurred': 'Ha ocurrido un error.',
        'Application Username': 'Usuario aplicación',
        'Application list': 'Lista de aplicaciones',
        'Application that manages identity': 'Aplicación que gestiona la identidad',
        'Application': 'Aplicación',
        'Applications': 'Aplicaciones',
        'Apps assigned': 'Aplicaciones asignadas',
        'Are you sure you want to generate a new password for this user?': '¿Está seguro de que desea generar una nueva contraseña a este usuario?',
        'Array Update': 'Edición en matriz',
        'Assignment': 'Asignación',
        'Audit': 'Auditoría',
        'Authorization Date': 'Fecha de la autorización',
        'Authorization Type': 'Tipo de autorización',
        'Authorized By': 'Authorizado por',
        'Automatically Assigned': 'Asignado automáticamente',
        'Avatar': 'Avatar',
        'Back to home': 'Volver al inicio',
        'Blocked (user status)': 'Bloqueo',
        'Can update/verify OTP seed': 'Puede actualizar/verificar semilla OTP',
        'Category': 'Categoría',
        'Change Password': 'Cambiar contraseña',
        'Change Status': 'Cambiar estado',
        'Changes Not Applied': 'Cambios sin aplicar',
        'Changes to the fields name, surname, email of Office 365 users will be overwritten by the active directory data at each login.': 'Los cambios en los campos nombre, apellidos, email de los usuarios de Office 365 serán sobrescritos por los datos del directorio activo en cada inicio de sesión.',
        'Changes to the fields name, surname, email of local users must be edited in the corresponding section.': 'Los cambios en los campos nombre, apellidos, email de los usuarios locales deben editarse en la sección correspondiente.',
        'Check password empty or not equeals.': 'Comprueba que la contraseña no esté vacía o sean iguales.',
        'City': 'Ciudad',
        'Clear Signature': 'Limpiar firma',
        'Client': 'Cliente',
        'Comments': 'Comentarios',
        'Companies': 'Empresas',
        'Company list': 'Lista de compañias',
        'Company': 'Empresa',
        'Completed percentage': 'Porcentaje completado',
        'Contact Telephone': 'Teléfono de contacto',
        'Country': 'País',
        'Created At': 'Alta',
        'Created By User': 'Creado por',
        'DISABLED': 'Desactivado',
        'Dashboard': 'Dashboard',
        'Default Team Role': 'Rol por defecto del equipo',
        'Default': 'Por defecto',
        'Delegated identity application': 'Aplicación de identidad delegada',
        'Delete %s?': '¿Eliminar %s?',
        'Delete': 'Eliminar',
        'Deleted At': 'Baja',
        'Department list': 'Lista de departamentos',
        'Department': 'Departamento',
        'Departments': 'Departamentos',
        'Description': 'Descripción',
        'Discharged (user status)': 'Baja',
        'Discharged at AD': 'Baja en AD',
        'Display In Home Page': 'Mostrar en página principal',
        'Do Access': 'Acceder',
        'Do Discharge': 'Dar de baja',
        'Do Discharge?': '¿Dar de baja?',
        'Do Login': 'Iniciar sesión',
        'Do Restore': 'Restaurar',
        'Do Restore?': '¿Restaurar?',
        'Document Type': 'Tipo de documento',
        'Document list': 'Lista de documentos',
        'Document': 'Documento',
        'Download template': 'Descargar plantilla',
        'Download': 'Descargar',
        'Edit Contact Information': 'Editar información de contacto',
        'Edit': 'Editar',
        'Email the user to reset their password?': '¿Enviar un email al usuario para restablecer su contraseña?',
        'Email': 'Email',
        'Employee Number': 'Número de empleado',
        'End Date': 'Fecha de fin',
        'Error while saving': 'Error al guardar',
        'Exceptions': 'Excepciones',
        'Exclude when duplicating profiles': 'Excluir al duplicar perfiles',
        'Exit without saving? Changes will be lost.': '¿Salir sin guardar? Se perderán los cambios.',
        'Expiration Date': 'Fecha de caducidad',
        'Export permissions': 'Exportar permisos',
        'Export profiles': 'Exportar perfiles',
        'Extension': 'Extensión',
        'Failure to change password.': 'Fallo al cambiar la contraseña.',
        'First Name': 'Nombre',
        'Forgot your password?': '¿Olvidaste tu contraseña?',
        'From: ': 'Desde: ',
        'Go Back': 'Volver',
        'Good Bye!': '¡Hasta pronto!',
        'Has Expiration Date': 'Tiene fecha de caducidad',
        'Hidden ext. tlf': 'Ocultar ext. y tlf.',
        'IMPORT_LIVE_HELP_HTML': 'Importar de forma síncrona (puede bloquear la web temporalmente). En caso contrario, la tarea de importación podrá tardar hasta %s minuto(s) en comenzar.',
        'IMPORT_PROFILE_ROLES_NOTE': '¿Está seguro de que desea importar los perfiles de roles?',
        'Id': 'Id',
        'Identifier': 'Identificador',
        'Identity': 'Identidad',
        'If the user is registered on the site, he or she will receive and email with a link to reset the account password.': 'Si el usuario está registrado en el sitio, recibirá un email con un enlace para restablecer la contraseña de la cuenta.',
        'If you think this is an error, please try again later or contact the system administrator.': 'Si cree que se trata de un error, por favor, vuelve a intentarlo más tarde o comuníquese con el administrador de sistemas.',
        'Image': 'Imagen',
        'Import Permissions': 'Importar permisos',
        'Import Profile Roles': 'Importar perfiles de roles',
        'Import Profiles': 'Importar perfiles',
        'Import': 'Importar',
        'In Name Of': 'Delegado/a',
        'In which position would you like to place it?': '¿En qué posición quieres situarlo?',
        'Inactive': 'Inactivo',
        'Integrated With %s': 'Integrado con %s',
        'Integrated': 'Integrado',
        'Internal Server Error': 'Se produjo un error en las comunicaciones con el servidor.',
        'Invalid Document.': 'Documento inválido.',
        'Invalid Nif.': 'NIF inválido',
        'Invalid username or password.': 'Usuario o contraseña incorrectos.',
        'Is Trainee': 'Es becario',
        'Is temporary contract': 'Es contrato temporal',
        'Is trainee': 'Es becario',
        'It seems there was a problem logging in. You may not have access to this application.': 'Parece que hubo un problema iniciando sesión. Es posible que no tenga acceso a esta aplicación.',
        'JSON File': 'Archivo JSON',
        'Keep on deactivating internal': 'Mantener al desactivar internos',
        'Keep on internal users disabled': 'Mantener en usuarios internos desactivados',
        'LOGIN_AS_NOTE': 'Al simular un usuario, se abrirá una nueva ventana del navegador con la sesión del usuario seleccionado. Si ya tiene una sesión iniciada, se cerrará y se abrirá la sesión del usuario.',
        'Language': 'Idioma',
        'Last Name': 'Apellidos',
        'Level': 'Nivel',
        'Local Users': 'Usuarios locales',
        'Local user list': 'Lista de usuarios locales',
        'Log In': 'Entrar',
        'Login As': 'Simular',
        'Login Error': 'Error de inicio de sesión',
        'Logo Icon Url': 'URL de icono (PNG/ICO)',
        'Logo Light Png Url': 'URL de logo en PNG (light)',
        'Logo Png Url': 'URL de logo en PNG',
        'Logo Svg Url': 'URL de logo en SVG',
        'Logout': 'Cerrar sesión',
        'MISSING': 'Falta',
        'Main Info': 'Información principal',
        'Main Url': 'URL principal',
        'Menu Group': 'Agrupación (menú)',
        'Menu Subgroup': 'Agrupación (submenú)',
        'Mobile / Extension / Contact Tel.': 'Móvil / Extensión / Tel. contacto',
        'Mobile Phone': 'Móvil',
        'Movil / Extension': 'Móvil / Extensión',
        'Multiple profiles': 'Múltiples perfiles',
        'NIF': 'NIF',
        'Name Of User': 'Nombre del usuario',
        'Name': 'Nombre',
        'New %s': 'Nuevo %s',
        'New password sent.': 'Nueva contraseña enviada.',
        'Nif': 'NIF',
        'Not AD User': 'No esta el usuario en AD',
        'Not Created': 'No creado',
        'Note': 'Nota',
        'Notification Type List': 'Lista de tipos de notificaciones',
        'Oauth': 'Oauth',
        'Of Group': 'Del grupo',
        'Office list': 'Lista de oficinas',
        'Office': 'Oficina',
        'Offices': 'Oficinas',
        'Oops!': '¡Upss!',
        'Optional': 'Opcional',
        'Origin Identifier': 'Identificador de usuario',
        'Origin': 'Maestro',
        'PENDING': 'Pendiente',
        'Password Expiration Date': 'Fecha de caducidad de la contraseña',
        'Password changed successfully.': 'Contraseña cambiada correctamente.',
        'Password must be at least 10 characters long': 'La contraseña debe tener al menos 10 caracteres',
        'Password': 'Contraseña',
        'Passwords do not match': 'Las contraseñas no coinciden',
        'Pending Recertification': 'Recertificación pendiente',
        'Pending recertifications': 'Recertificaciones pendientes',
        'Pending update AD': 'Pendiente de actualización en AD',
        'Pending update on AD': 'Pendiente de actualización en AD',
        'Permission list': 'Lista de permisos',
        'Permission': 'Permiso',
        'Permissions could not be downloaded. Try again later.': 'Los permisos no pudieron ser descargados. Inténtelo de nuevo más tarde.',
        'Permissions': 'Permisos',
        'Please fill the origin identifier.': 'Por favor, rellene el nombre de usuario.',
        'Portal': 'Portal',
        'Portfolio': 'Cartera',
        'Position': 'Ubicuación fisica',
        'Postal Code': 'Código postal',
        'Profile List': 'Lista de perfiles',
        'Profile Type': 'Tipo de perfil',
        'Profile Types': 'Nivel de seguridad',
        'Profile': 'Perfil',
        'Profiles assigned': 'Perfiles asignados',
        'Profiles could not be downloaded. Try again later.': 'Los perfiles no pudieron ser descargados. Inténtelo de nuevo más tarde.',
        'Profiles': 'Perfiles',
        'Profiling': 'Perfilado',
        'Provider list': 'Lista de proveedores',
        'Provider': 'Proveedor',
        'Providers': 'Proveedores',
        'Province': 'Provincia',
        'Read Only': 'Solo lectura',
        'Recover Password': 'Recuperar contraseña',
        'Redirect URI': 'URI de redirección',
        'Redirect URIs': 'URIs de redirección',
        'Redirect list': 'Lista de redirecciones',
        'Redirecting...': 'Redirigiendo...',
        'Remove %s?': '¿Quitar %s?',
        'Remove': 'Quitar',
        'Remove?': '¿Quitar?',
        'Repeat Password': 'Repetir contraseña',
        'Replaced By': 'Sustit.',
        'Reset Password': 'Restablecer contraseña',
        'Responsible User Username': 'Usuario responsable',
        'Responsible User': 'Usuario responsable',
        'Responsible': 'Resp.',
        'Role list': 'Lista de roles',
        'Role': 'Rol',
        'Roles': 'Roles',
        'Saved': 'Guardado',
        'Saving...': 'Guardando...',
        'Schedule': 'Horario',
        'See User Details': 'Ver detalles de usuario',
        'Self-identity application': 'Aplicación de identidad propia',
        'Send Access Instructions': 'Enviar instrucciones de acceso',
        'Send New Password': 'Generar nueva contraseña y enviar',
        'Sending ...': 'Enviando ...',
        'Sign Inside (HTML)': 'Firma <small class="text-muted"><em>dentro del área marcada</em></small><sup>*</sup>',
        'Start Date': 'Fecha de inicio',
        'Status': 'Estado',
        'Subcategory': 'Subcategoría',
        'Success': 'Éxito',
        'Svg': 'SVG',
        'Team': 'Equipo',
        'Telephone extension': 'Extensión teléfonica',
        'Telephone': 'Teléfono',
        'The expires at field must be a date after yesterday.': 'La fecha de caducidad debe ser una fecha posterior a ayer.',
        'The expires at must be a date after yesterday.': 'La fecha de caducidad debe ser una fecha posterior a ayer.',
        'The file could not be processed.': 'El archivo no pudo ser procesado.',
        'The file is not a valid JSON file.': 'El archivo no es un archivo JSON válido.',
        'The password and repeat password must match.': 'La contraseña y la repetición de la contraseña deben coincidir.',
        'The password does not meet the minimum security requirements.': 'La contraseña no cumple con los requisitos mínimos de seguridad.',
        'The profile is already attached to the user.': 'El perfil ya está asignado al usuario.',
        'The reset password link is invalid or has expired.': 'El enlace para restablecer la contraseña es inválido o ha caducado.',
        'This field is required': 'Este campo es obligatorio',
        'To: ': 'Hasta: ',
        'Trainee': 'Becario',
        'Type': 'Tipo',
        'Unique profile': 'Perfil único',
        'Unsaved changes': 'Cambios sin guardar',
        'Update User Application Username': 'Actualizar usuario aplicación',
        'Updated By User': 'Actualizado por',
        'Uri': 'URL',
        'Url': 'URL',
        'User First Name': 'Nombre de usuario',
        'User Last Name': 'Apellidos de usuario',
        'User List': 'Lista de usuarios',
        'User Profiles': 'Perfiles de usuario',
        'User Type': 'Tipo de usuario',
        'User already has this role': 'El usuario ya tiene este rol',
        'User has already assigned a role. This role will be deleted. Are you sure?': 'El usuario ya tiene asignado un rol. Este rol será eliminado. ¿Está seguro?',
        'User': 'Usuario',
        'Username': 'Nombre de usuario',
        'Users in your charge': 'Usuarios a tu cargo',
        'Users': 'Usuarios',
        'View in {lang}': 'Ver en {lang}',
        'Webhook key': 'Clave del webhook',
        'Webhook url': 'URL del webhook',
        'You can close this window now.': 'Puedes cerrar esta ventana ahora.',
        'Your password has been changed successfully.': 'Tu contraseña ha sido cambiada correctamente.',
        'Your session has been closed.': 'Has cerrado sesión.',
        'cif': 'NIF',
        'downloaded-table': 'descarga',
        'offices': 'Oficinas',
    },
    en: {
        '**': '**',
        'ACTIVE': 'Active',
        'Active at AD': 'Active at AD',
        'DISABLED': 'Disabled',
        'Discharged at AD': 'Discharged at AD',
        'Do Access': 'Access',
        'Do Login': 'Login',
        'IMPORT_HELPER_HTML': 'Select or drag a CSV (separated by &quot;<b>;</b>&quot;) or XSLX file containing the following columns: %s',
        'IMPORT_LIVE_HELP_HTML': 'Import synchronously (may temporarily block the web). Otherwise, the import task may take up to %s minute(s) to start.',
        'LOGIN_AS_NOTE': 'When simulating a user, a new browser window will open with the session of the selected user. If you already have a session started, it will be closed and the user session will be opened.',
        'MISSING': 'Missing',
        'NIF': 'NIF',
        'Nif': 'NIF',
        'Not AD User': 'Not AD user',
        'PENDING': 'Pending',
        'Pending update AD': 'Pending update AD',
    },
};