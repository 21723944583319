<template>
    <div class="row align-items-center">
        <div class="col-6 col-md-6 align-self-center">
            <div class="row align-items-center">
                <div class="col-4 col-md-2">
                    <img :src="user.avatar_url" alt="" />
                </div>
                <div class="col-8 col-md-10 text-truncate">
                    <div>
                        {{ user.first_name }} {{ user.last_name }}
                    </div>
                    <div>
                        <span class="text-muted">
                            <small v-if="!hasSingleUserOrigin()">
                                <em>
                                    {{ user.userOrigin.name }} #{{ user.id }}
                                </em>
                            </small>
                            <small v-else>
                                <em v-if="user.deleted_at" class="text-danger">
                                    {{ i18n('Deleted At') }} : {{ new Date(user.deleted_at).toLocaleDateString() }}
                                </em>
                                <em v-else style="color: #69979d;">
                                    {{ i18n('Created At') }} : {{ new Date(user.created_at).toLocaleDateString() }}
                                </em>
                            </small>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-6 align-self-center text-end">
            <UserSendAccessInstructionsAction cssClass="me-1" :user="user" v-if="user?.acl?.sendWelcomeMail" />
            <UserLoginAsAction cssClass="me-1" :user="user" v-if="user?.acl?.getLoginAsCode" />
            <a href="javascript:void(0)" class="btn btn-sm btn-outline-dark" @click="$emit('goBack')">
                <BackIcon />
                <span class="d-none d-sm-inline-block ms-2">
                    {{ i18n('Go Back') }}
                </span>
            </a>
        </div>
    </div>
</template>

<script>
import { hasSingleUserOrigin } from "@/components/user/userOrigin.js";
import BackIcon from "@/assets/svg/back.vue";
import UserSendAccessInstructionsAction from "@/components/user/UserSendAccessInstructionsAction.vue";
import UserLoginAsAction from "@/components/user/UserLoginAsAction.vue";

export default {
    components: {
        BackIcon,
        UserSendAccessInstructionsAction,
        UserLoginAsAction,
    },
    props: {
        user: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    emits: ['goBack'],
    methods: {
        hasSingleUserOrigin,
    },
};
</script>