<template>
  <ApiViewWrapper :notFound="notFound" :allowAnonymous="false">
    <DefaultLayout>
      <h1 class="h5 d-flex align-items-center" :class="{ 'mt-2': !application.svg }">
        <img :src="svgDataUrl" alt="" class="me-2" v-if="application.svg" style="width: 2rem" />
        <div>
          <span class="small text-muted">{{ i18n('Application') }}</span>
          {{ application.name }}
        </div>
      </h1>

      <SectionManager sectionType="none" stickyY="auto">
        <SectionItem>
          <template #title>
            {{ i18n('Main Information') }}
          </template>
          <div class="bg-white p-2 border">
            <div class="row g-2 align-items-center">
              <div class="col">
                <h2 class="h5">
                  {{ i18n('Main Information') }}
                </h2>
              </div>
              <div class="col text-end">
                <ActionDropdown ref="actions" :actions="actions" />
              </div>
            </div>
            <div class="row g-2">
              <div v-if="application.is_identity && !application.is_self" class="col-12 col-sm-6">
                <FieldReadonly :value="i18n('Identity')" :label="i18n('Type')" />
              </div>
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="application.name" :label="i18n('Name')" />
              </div>
              <div class="col-12 col-sm-6">
                <FieldReadonly :value="application.alias" :label="i18n('Alias')" />
              </div>
              <div class="col-6">
                <FieldReadonly :value="application.main_url" :label="i18n('Main Url')" format="url" />
              </div>
              <template v-if="$root?.features?.applications?.categories?.enabled">
                <div class="col-6">
                  <FieldReadonly :value="application.category" :label="i18n('Category')" />
                </div>
                <div class="col-6">
                  <FieldReadonly :value="application.subcategory" :label="i18n('Subcategory')" />
                </div>
              </template>
              <div class="col-6" v-if="!application.is_self">
                <FieldReadonly :value="application.is_integrated" :label="i18n('Integrated With %s', appName)" />
              </div>
              <div v-if="!application.is_self && !application.is_identity" class="col-6">
                <FieldReadonly :value="application.identityApplication" :label="i18n('Identity')" />
              </div>
              <div class="col-6"
                v-if="application.is_keep_for_internal_disabled_users || application.is_exclude_from_duplication">
                <div class="small text-muted">{{ i18n('Exceptions') }}</div>
                <div class="mx-3" v-if="application.is_keep_for_internal_disabled_users">
                  - {{ i18n('Keep on deactivating internal') }}
                </div>
                <div class="mx-3" v-if="application.is_exclude_from_duplication">
                  - {{ i18n('Exclude when duplicating profiles') }}
                </div>
              </div>
              <div class="col-6">
                <FieldReadonly :value="application.created_at" :label="i18n('Created At')" format="date" />
              </div>
              <div class="col-6" v-if="!application?.is_self">
                <FieldReadonly
                  :value="application.is_single_profile ? i18n('Unique profile') : i18n('Multiple profiles')"
                  :label="i18n('Profiling')" />
              </div>
              <div class="col-6" v-if="!application?.is_self">
                <FieldReadonly :value="application.authorizationType" :label="i18n('Authorization Type')" />
              </div>
            </div>
          </div>
        </SectionItem>
        <SectionItem v-if="application?.authorizationType?.code !== 'profiles'">
          <template #title>
            {{ i18n('Profiles') }}
          </template>
          <ProfileSection :application="application" />
        </SectionItem>
        <SectionItem>
          <template #title>
            {{ i18n('Redirect URIs') }}
          </template>
          <RedirectUriSection :application="application" />
        </SectionItem>
        <SectionItem>
          <template #title>
            <template v-if="application?.authorizationType?.code !== 'profiles'">
              {{ i18n('Permissions') }}
            </template>
            <template v-else>
              {{ i18n('Profiles') }}
            </template>
          </template>
          <PermissionSection :application="application" />
        </SectionItem>
        <SectionItem v-if="can('USER_ACCESS') || can('USER_MANAGE')">
          <template #title>
            {{ i18n('Users') }}
          </template>
          <UserProfileSection :application="application" />
        </SectionItem>
      </SectionManager>
      <ApplicationModal ref="ApplicationModal" @updated="reload" />
    </DefaultLayout>
  </ApiViewWrapper>
</template>

<script>
import $env from "@/tools/environment.js";
const appName = $env('VITE_APP_NAME');
import api from "@/api.js";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ApiViewWrapper from "@/components/common/view/ApiViewWrapper.vue";
import SectionManager from "@/components/common/layout/section/SectionManager.vue";
import SectionItem from "@/components/common/layout/section/SectionItem.vue";
import FieldReadonly from "@/components/common/form/FieldReadonly.vue";
import ActionDropdown from "@/components/common/list/ActionDropdown.vue";
import PermissionSection from "@/components/permission/PermissionSection.vue";
import UserProfileSection from "@/components/userProfile/UserProfileSection.vue";
import ProfileSection from "@/components/profile/ProfileSection.vue";
import RedirectUriSection from "@/components/redirectUri/RedirectUriSection.vue";
import ApplicationModal from "@/components/application/ApplicationModal.vue";

export default {
  components: {
    ApiViewWrapper,
    DefaultLayout,
    SectionManager,
    SectionItem,
    FieldReadonly,
    ActionDropdown,
    PermissionSection,
    UserProfileSection,
    RedirectUriSection,
    ApplicationModal,
    ProfileSection,
  },
  data() {
    return {
      appName,
      application: null,
      notFound: null,
    };
  },
  mounted() {
    api.auth().then((user) => {
      this.$root.loggedUser = user;

      this.load();
    });
  },
  watch: {
    "$route.params.id"() {
      this.load();
    },
  },
  computed: {
    svgDataUrl() {
      return `data:image/svg+xml;base64,${btoa(this.application.svg)}`;
    },
    actions() {
      return [{
        label: this.i18n('Edit'),
        fn: () => {
          this.showApplicationModal();
        },
        if: (this.application.acl?.update) === true,
        starred: true,
      },
      {
        label: this.i18n('Delete'),
        fn: () => {
          this.$root.requestConfirmation(this.i18n('Delete? This action cannot be undone.'), () => {
            this.$emit('updating', this.application);
            api.delete(`applications/${this.application.id}`).then(() => {
              api.clearCache();
              this.$router.push({ name: 'ApplicationIndex', });
            }).then(() => {
              this.reload();
            });
          });
        },
        if: (this.application.acl?.delete) === true,
        starred: false,
      },
      ];
    },
  },
  methods: {
    load(force) {
      clearTimeout(this.loadTimeout);
      this.notFound = null;

      if (!force) {
        this.loadTimeout = setTimeout(() => {
          this.load(true);
        }, 250);
        return;
      }

      if (!this.$route.params.id) {
        this.notFound = true;
        return;
      }

      api.get(`applications/${this.$route.params.id}`, { acl: 'application' }).then((application) => {
        if (application.acl?.view !== true) {
          this.$router.push({ name: 'ApplicationIndex', });
          return;
        }

        this.application = application;
        this.notFound = false;

        this.$root.setPageTitle(this.i18n('%s | Applications', application.name));
      }).catch(() => {
        this.notFound = true;
      });
    },
    reload() {
      this.load();
    },
    showApplicationModal() {
      if (!this.$refs.ApplicationModal) return;
      this.$refs.ApplicationModal.show(this.application.id);
    },
  },
};
</script>