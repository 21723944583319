<template>
  <div class="p-5 text-center">
    <span class="spinner-border spinner-border-sm" role="status">
      <span class="visually-hidden">
        {{ i18n('Redirecting...') }}
      </span>
    </span>
  </div>
</template>

<script>
import api from "@/api.js";
export default {
  components: {},
  data() {
    return {};
  },
  created() {
    this.$root.setPageTitle('');

    api.auth().then(user => {
      if (!user || !user.id) {
        api.login(
          this.$route.query, {
          name: this.$route.name,
          params: this.$route.params
        }).then((route) => {
          this.$root.loadFeatures();
          this.$router.push(route);
        }).catch(() => {
          this.$router.push({ name: 'LoginError' });
        });

        return;
      }

      this.$root.loggedUser = user;

      if (this.can('APPLICATION_MANAGE') || this.can('APPLICATION_ACCESS')) {
        this.$router.push({ name: 'ApplicationIndex' });
      } else if (this.can('USER_MANAGE') || this.can('USER_ACCESS')) {
        this.$router.push({ name: 'UserIndex' });
      } else if (this.can('COMPANY_MANAGE') || this.can('COMPANY_ACCESS')) {
        this.$router.push({ name: 'CompanyIndex' });
      } else if (this.can('PROVIDER_MANAGE') || this.can('PROVIDER_ACCESS')) {
        this.$router.push({ name: 'ProviderIndex' });
      } else {
        this.$router.push({ name: 'LoginError' });
      }
    });

    this.$router.push({
      name: 'ApplicationIndex'
    });
  },
};
</script>