<template>
  <div class="bg-white p-2 border">
    <OfficeFilter :filters="filters">
      <template #default>
        <h2 class="h5">
          {{ i18n('Offices') }}
          <span class="small text-muted">({{ offices.length }})</span>
        </h2>
      </template>
      <template #buttons>
        <OfficeCreateAction @created="reload" v-if="can('COMPANY_MANAGE')" btnClass="ms-1 btn btn-sm btn-outline-dark"
          :company="company" />
      </template>
    </OfficeFilter>

    <OfficeList :offices="offices" :loading="loading" @deleted="reload" @updating="loading = true" @updated="reload"
      :company="company" />
  </div>
</template>

<script>
import api from "@/api.js";
import {
  duplicate,
  flattenUrlObject,
} from "@/tools/object.js";
import OfficeCreateAction from "@/components/office/OfficeCreateAction.vue";
import OfficeFilter from "@/components/office/OfficeFilter.vue";
import OfficeList from "@/components/office/OfficeList.vue";

export default {
  components: {
    OfficeCreateAction,
    OfficeFilter,
    OfficeList,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      offices: [],
      filters: {},
      loadTimeout: null,
      loading: true,
    }
  },
  mounted() {
    api.auth().then((user) => {
      this.$root.loggedUser = user;

      if (!this.can('COMPANY_MANAGE')
        && !this.can('COMPANY_ACCESS')) {
        this.$router.push({
          name: 'Index',
        });
        return;
      }

      this.load();
    });
  },
  watch: {
    params: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  computed: {
    params() {
      let params = duplicate(this.filters);
      params.acl = 'office';

      flattenUrlObject(params);

      return params;
    },
  },
  methods: {
    load(force) {
      clearTimeout(this.loadTimeout);
      this.loading = true;

      if (!force) {
        this.loadTimeout = setTimeout(() => {
          this.load(true);
        }, 250);
        return;
      }

      api.get(`companies/${this.company.id}/offices`, this.params).then(offices => {
        this.offices = offices;
        this.loading = false;
      });
    },
    reload() {
      this.load();
    },
  },
};
</script>